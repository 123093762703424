<script>
export const pageName = 'reflections-answer-LL';
export const pageRoute = '/reflections/{reflectionId}/answer';
export const title = 'Complete self-reflection'

import { getRoleById} from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { functions } from '../../../js/functions.js';
import { getReflectionView, getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'
import {navigate} from '@trullock/page-manager'
import UserRole from '../../../../functions/domain/userRole.js';

export default {
	data() {
		return {
			currentUser: null,
			reflectionId: null,
			role: null,
			level: null,
			competencies: [],
			ready: false
		}
	},
	methods: {
		async submit() {

			this.$refs.form.setSubmitting(true)

			let answers = this.competencies
				.map(c => c.topics).reduce((p, c) => p.concat(c), [])
				.map(t => t.levels).reduce((p, c) => p.concat(c), [])
				.map(l => l.criteria).reduce((p, c) => p.concat(c), [])
				.filter(c => c.answer != null)
				.reduce((p, c) => {
					p[c.id] = parseInt(c.answer, 10);
					return p
				}, {})

			let response = await functions.reflectionSaveAnswers({
				reflectionId: this.reflectionView.id, 
				answers
			})

			// complete?
			if(response.data)
				navigate(`/reflections/${this.reflectionView.id}/submit`)
			else
				navigate(`/reflections/${this.reflectionView.id}/answered`)

				
			this.$refs.form.setSubmitting(false)
		},
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			this.reflectionView = await getReflectionView(opts.reflectionId)

			this.role = getRoleById(this.reflectionView.roleId);
			this.roleLevel = this.role.getLevelById(this.reflectionView.levelId);

			this.competencies = this.roleLevel.competencies.competencies.map(c => ({
				title: c.title,
				path: c.path,
				summary: c.summary,
				topics: c.topics.map(t => ({
					title: t.title,
					path: t.path,
					summary: t.summary,
					levels: t.requiredLevels.map(l => ({
						title: l.title,
						path: l.path,
						summary: l.summary,
						criteria: l.criteria.map(c => ({
							id: c.id,
							text: c.text,
							answer: this.reflectionView.answers[c.id]
						}))
					}))
				}))
			}))

			var userRole = new UserRole(this.role, this.roleLevel);
            userRole.applyAnswers(this.reflectionView.answers);
			this.completeness = userRole.getStats().completeness;
			
			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav />
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mb-4">
			<div class="col-6 text-center">
				<h1>Reflection time</h1>
				<p>Let's have an honest reflection on how you think you're doing.</p>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12">


				<div class="alert alert-light">
					<h3 class="mb-4">Tips for answering questions</h3>
					<ul>
						<li class="mb-1">Be honest</li>
						<li class="mb-1">Don't stress about 100% accurately measuring yourself. This isn't about being perfect its about evaluating trends and general areas for development.</li>
						<li class="mb-1">Don't rush, but don't spend too long struggling to evaluate yourself and answer a particular question. Your first, gut answer is usually pretty good.</li>
						<li class="mb-1">All of the criteria <em>do</em> apply to you and your role. If you think something doesn't apply - and therefore you aren't doing it - choose <strong>Never</strong> and later on we'll show you how it does apply and how to start doing it.</li>
					</ul>
				</div>


				<form ref="form" @submit.prevent="submit" class="mb-6">
				
					<template v-for="competency of competencies">
						<template v-for="topic of competency.topics">
						
							<div v-for="level of topic.levels" class="card">
								<div class="card-header">
									<h4 class="card-header-title">{{ competency.title }} - {{ topic.title }} - {{ level.title }}</h4>
								</div>
								<div class="card-body">
									<p class="small"><strong>{{ competency.title }}</strong> - {{ competency.summary }}</p>
									<p class="small"><strong>{{ topic.title }}</strong> - {{ topic.summary }}</p>
									<p class="small mb-0"><strong>{{ level.title }}</strong> - {{ level.summary }}</p>
								</div>
								<div class="mb-0">
									<table class="table table-sm table-hover card-table">
										<thead>
											<tr>
												<th>Criteria</th>
												<th colspan="2">Never</th>
												<th></th>
												<th colspan="2" class="text-right">Always</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="criterion of level.criteria">
												<td>{{criterion.text}}</td>
												<td class="shrink pr-0">
													<div class="custom-control custom-radio">
														<input type="radio" :name="`answers-${criterion.id}`" value="0" v-model="criterion.answer" class="custom-control-input no-ajax-validate" :id="`cbAnswerTopic${criterion.id}-0`">
														<label class="custom-control-label color-0" :for="`cbAnswerTopic${criterion.id}-0`"></label>
													</div>
												</td>
												<td class="shrink pr-0">
													<div class="custom-control custom-radio">
														<input type="radio" :name="`answers-${criterion.id}`" value="1" v-model="criterion.answer" class="custom-control-input no-ajax-validate" :id="`cbAnswerTopic${criterion.id}-1`">
														<label class="custom-control-label color-1" :for="`cbAnswerTopic${criterion.id}-1`"></label>
													</div>
												</td>
												<td class="shrink pr-0">
													<div class="custom-control custom-radio">
														<input type="radio" :name="`answers-${criterion.id}`" value="2" v-model="criterion.answer" class="custom-control-input no-ajax-validate" :id="`cbAnswerTopic${criterion.id}-2`">
														<label class="custom-control-label color-2" :for="`cbAnswerTopic${criterion.id}-2`"></label>
													</div>
												</td>
												<td class="shrink pr-0">
													<div class="custom-control custom-radio">
														<input type="radio" :name="`answers-${criterion.id}`" value="3" v-model="criterion.answer" class="custom-control-input no-ajax-validate" :id="`cbAnswerTopic${criterion.id}-3`">
														<label class="custom-control-label color-3" :for="`cbAnswerTopic${criterion.id}-3`"></label>
													</div>
												</td>
												<td class="shrink">
													<div class="custom-control custom-radio">
														<input type="radio" :name="`answers-${criterion.id}`" value="4" v-model="criterion.answer" class="custom-control-input no-ajax-validate" :id="`cbAnswerTopic${criterion.id}-4`">
														<label class="custom-control-label color-4" :for="`cbAnswerTopic${criterion.id}-4`"></label>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</template>

					</template>

					<fieldset class="mb-4">
						<legend>
							Reflection details
						</legend>
					</fieldset>
					<p class="small text-muted text-center mb-3">You can review and edit these again later.</p>
					<button type="submit" class="btn btn-lg btn-block btn-primary">Save answers <span class="fe fe-check-circle"></span></button>
				</form>
			</div>
		</div>
	</div>
</template>