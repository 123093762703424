<script>
export const pageName = 'loading';
export const pageRoute = '/loading';
export const title = 'Loading...'

export default {
	data() { return { } },
	mounted() {
		// for styling
		this.$refs.wrapper.parentElement.setAttribute('id', 'page-loading');
	}
}
</script>
<template>
	<div ref="wrapper" class="text-center py-5">
		<span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
	</div>
</template>