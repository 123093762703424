import { UserTask } from "./userTask.js";

export default class SubmitReflectionAnswersUserTask extends UserTask
{
	reviewId = null
	reflectionId = null

	getNextRemindOn(previouslyRemindedOn)
	{
		if(this.remindedCount >= 3)
			return null;

		return previouslyRemindedOn.addBizDays(1);
	}

	get summary() {
		return 'Submit your self reflection'
	}
}
