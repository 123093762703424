<script>
import { getCurrentUserId } from '../../js/auth.js';
import { getLiteUser, getViewUser } from '../../js/readModel.js';
import { functions } from '../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import MeNav from '../menav.vue'
import { getAuth, signOut } from '@firebase/auth';
import { showConfirm } from '../../js/confirm.js';
import Modal from '../../js/modal.js';
import Cropper from 'cropperjs'
import { publish } from '@trullock/pubsub'

export const pageName = 'account-preferences';
export const pageRoute = '/account/preferences';
export const title = 'Preferences'

export default {
	data() {
		return { 
			user: null,
			name: '',
			email: '',
			avatar: null,
			files: [],
			reminders: true,
			ready: false
		}
	},
	methods: {
		async submit() {
			if(!this.$refs.form.validate())
				return;
			
			this.$refs.form.setSubmitting(true)

			await functions.userChangeName({ name: this.name});
			await functions.userChangeReminderPreferences({ reminders: this.reminders});
			
			if(this.newAvatar)
				await functions.userChangeAvatar({ avatar64: this.newAvatar});

			let liteUser = await getLiteUser(this.user.id);
			publish('user changed', liteUser);

			navigate('/')

			this.$refs.form.setSubmitting(false)
		},
		async deleteAccount() {
			showConfirm({
				title: 'Confirm account deletion',
				message: `Are you sure you want to delete your account?<br/>Under GDPR and in accordance with our privacy policy and terms of service all data identifying you will be erased.<br/>Please ensure you understand how billing works before deleting your account.`,
				positiveText: 'Delete',
				positiveStyle: 'danger',
				positiveAction: async () => {
					await functions.userDelete({ })
					await signOut(getAuth())
					navigate('/');
				}
			})
		},
		boot(opts) {
			this.modal = new Modal(this.$refs.cropper);
		},
		async show(opts)
		{
			this.user = await getViewUser(getCurrentUserId());
			this.name = this.user.name;
			this.email = this.user.email;
			this.avatar = this.user.avatar;
			this.files = []
			this.reminders = this.user.receiveReminders;
			this.ready = true;
		},
		hide()
		{
			this.$refs.fupAvatar.value = null
			this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
		},
		crop() {
			this.modal.hide();

			let canvas = this.cropper.getCroppedCanvas({
				width: 240,
				height: 240
			});

			this.avatar = canvas.toDataURL();
			this.newAvatar = this.avatar;

			canvas.toBlob(blob => {
				this.$refs.fupAvatar.blobData = {
					blob: blob,
					name: 'avatar.jpg'
				};
				this.$refs.lblAvatar.innerText = this.$refs.lblAvatar.pendingName;
			});
		},
		hideModal() {
			this.modal.hide()
			this.cropper.destroy();
			this.cropper = null;
		},
		inputChanged(e) {
			var files = e.target.files;
			var done = (url, name) => {
				this.$refs.fupAvatar.value = '';
				this.$refs.fupAvatar.blobData = null;
				this.$refs.imgCropper.src = url;
				this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
				this.$refs.lblAvatar.pendingName = name;
				if(this.cropper)
					this.cropper.destroy();

				this.modal.show();
				this.cropper = new Cropper(this.$refs.imgCropper, {
					aspectRatio: 1,
					viewMode: 1,
					autoCropArea: 1
				});
			};

			if (files && files.length > 0) {
				let file = files[0];
				if (URL) {
					done(URL.createObjectURL(file), file.name);
				} else if (FileReader) {
					let reader = new FileReader();
					reader.onload = function (e) {
						done(reader.result, file.name);
					};
					reader.readAsDataURL(file);
				}
			}
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
    <div class="row">
        <div class="col">
            <div class="header">
                <div class="header-body pt-0">
                    <MeNav />
                </div>
            </div>
        </div>
    </div>
    <form ref="form" @submit.prevent="submit">
		<div class="row">
			<div class="col-12 col-md-6 order-md-last">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Settings</h4>
					</div>
					<div class="card-body">
						<fieldset>
							<legend>Contact Details</legend>
							<div class="form-group mb-0">
								<label for="txtAccountPrefsEmail">Personal email address</label>
								<input type="email" class="form-control" v-model="email" id="txtAccountPrefsEmail" disabled/>
								<span class="form-text small text-muted mt-2 mb-0">This cannot current be changed.</span>
							</div>
						</fieldset>
						<!-- <fieldset>
							<legend></legend>
							@this.DropDownFor(x => x.TimeZone, ViewData.TimeZones, tz => tz.Id, tz => tz.ToString())
						</fieldset> -->
						<div v-if="!user.emailVerified" class="alert alert-light mt-4 mb-0" role="alert">
							<span class="fe fe-info"> </span> You need to verify your account, check your inbox for an email from us. <a href="/account/verify/resend">Resend email</a>.
						</div>
					</div>
				</div>
				<div class="card mb-0">
					<div class="card-header">
						<h4 class="card-header-title">Notifications</h4>
					</div>
					<div class="card-body">
						<fieldset>
							<legend>Notification preferences</legend>
							<div class="form-group mb-0">
								<div class="custom-control custom-checkbox">
									<input type="checkbox" class="custom-control-input" name="reminders" v-model="reminders" value="on" id="chkAccountPrefsReminders" aria-describedby="chkAccountPrefsReminders_Validation">
									<label class="custom-control-label" for="chkAccountPrefsReminders">Receive reminders</label>
								</div>
								<span class="form-text small text-muted mt-2 mb-0">We'll always email you when you have a new action to complete.<br/>Untick this box if you don't also want reminding to do the outstanding tasks you've forgotten about.</span>
								<span id="chkAccountPrefsReminders_Validation" class="feedback"></span>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="card mb-0">
					<div class="card-header">
						<h4 class="card-header-title">Profile</h4>
					</div>
					<div class="card-body">
						<fieldset>
							<legend>Login credentials</legend>
							<div class="form-group mb-4">
								<label for="txtAccountPerfsName">Name</label>
								<input type="text" class="form-control" name="name" v-model="name" id="txtAccountPerfsName" aria-describedby="txtAccountPerfsName_Validation" autocomplete="name" placeholder="First Last" required />
								<span id="txtAccountPerfsName_Validation" class="feedback"></span>
							</div>
						</fieldset>
						<fieldset>
							<div class="field form-group mb-0">
								<label for="fupAvatar">
									Avatar
									<div class="dz-default dz-message py-5">
										<div class="avatar avatar-xxl">
											<img ref="imgAvatar" class="avatar-img rounded-circle" :src="avatar" alt="Avatar">
										</div>
									</div>
								</label>

								<div class="custom-file">
									<input type="file" @change="inputChanged" ref="fupAvatar" class="custom-file-input" id="fupAvatar" name="Avatar" accept="image/*">
									<label ref="lblAvatar" class="custom-file-label text-muted" for="fupAvatar">Choose image&hellip;</label>
								</div>
								<span id="txtAvatar_Help" class="form-text text-muted small mb-0">Must be a jpeg or png</span>
							</div>
						</fieldset>
					</div>
				</div>

			</div>
		</div>
		<div class="container my-5">
			<div class="row justify-content-center">
				<div class="col-12 col-md-6">
					<button type="submit" class="btn btn-lg btn-primary btn-block">Save changes <span class="fe fe-check-circle"></span></button>
				</div>
			</div>
		</div>
	</form>
	<hr class="mb-5" />
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-header-title">Delete account</h4>
                </div>
                <div class="card-body">
                    <p class="small">Deleting your account will remove your access to ivelop.</p>
                    <button type="button" @click.prevent="deleteAccount" class="btn btn-sm btn-outline-danger mb-0">Delete account</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" ref="cropper" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card mb-0">
                <div class="card-header">
                    <h4 class="card-header-title" id="modalLabel">Crop the image</h4>
                    <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="img-container">
                        <img ref="imgCropper" :src="avatar" class="">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="hideModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="crop">Crop</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>