import "regenerator-runtime/runtime.js";

import "./scss/theme.scss";
import '@trullock/dollar'

import "./img/apple-touch-icon.png";
import "./img/favicon-32x32.png";
import "./img/favicon-16x16.png";
import "./img/favicon.ico";
import "./img/email/email-logo.png"

import "./site.webmanifest";
import "./robots.txt"
import "./microsoft-identity-association.json"

// because webpack is broken/shit.
import cerebriSans from './fonts/cerebrisans-regular.woff';
window.cerebriSansPath = cerebriSans;

import init from "./js/index.js";
init();