import { UserTask } from "./userTask.js";

export default class CompleteReflectionAnswersUserTask extends UserTask
{
	reviewId = null
	reflectionId = null

	getNextRemindOn(previouslyRemindedOn)
	{
		return previouslyRemindedOn.addBizDays(1);
	}

	get summary() {
		return 'Complete your self reflection'
	}
}
