import { publish, subscribe } from '@trullock/pubsub'
import { getAuth } from "firebase/auth";
import { getLiteUser } from './readModel.js';

let currentUserId = null;

export function init() {

	subscribe('user auth-state-changed', user => {
		currentUserId = user?.uid
	})

	return new Promise(resolve => {
		let booted = false;
		
		let auth = getAuth();
		auth.onAuthStateChanged(async user => {
			
			publish('user auth-state-changed', user);
			
			if(!booted){
				booted = true;
				
				// Handles page loads
				if(user)
				{
					let liteUser = await getLiteUser(user.uid);
					publish('user changed', liteUser);
				}

				resolve();
			}
		});
	});
}

export function getCurrentUserId() {
	return currentUserId;
};


export function getAuthProvider(id){
	switch(id){
		case 'google.com':
			return new firebase.auth.GoogleAuthProvider()
		case 'github.com':
			return new firebase.auth.GithubAuthProvider()
		default:
			throw new Error('Unexpected auth provider: `' + id + '`')
	}
}
