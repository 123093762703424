<script>
export const pageName = 'reflections-provide-feedback';
export const pageRoute = '/reflections/{reflectionId}/provide-feedback';
export const title = 'Provide feedback'

import { getRoleById} from '../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/functions.js';
import { getLiteUser, getReflectionView, getViewUser } from '../../js/readModel.js';
import MeNav from '../menav.vue'
import {navigate} from '@trullock/page-manager'
import UserRole from '../../../functions/domain/userRole.js';

export default {
	data() {
		return {
			currentUser: null,
			user: null,
			reflectionId: null,
			role: null,
			level: null,
			competencies: [],
			ready: false
		}
	},
	methods: {
		async submit() {

			this.$refs.form.setSubmitting(true)

			let feedbackAnswers = this.competencies
				.map(c => c.topics).reduce((p, c) => p.concat(c), [])
				.map(t => t.levels).reduce((p, c) => p.concat(c), [])
				.map(l => l.criteria).reduce((p, c) => p.concat(c), [])
				.filter(c => c.answer != null)
				.reduce((p, c) => {
					p[c.id] = parseInt(c.feedbackAnswer, 10);
					return p
				}, {})

			await functions.reflectionProvideFeedback({
				reflectionId: this.reflectionView.id, 
				feedbackAnswers
			})

			// tood: say thanks?
			navigate(`/`)
				
			this.$refs.form.setSubmitting(false)
		},
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			this.reflectionView = await getReflectionView(opts.reflectionId)
			this.user = await getLiteUser(this.reflectionView.userId);
			
			this.role = getRoleById(this.reflectionView.roleId);
			this.roleLevel = this.role.getLevelById(this.reflectionView.levelId);

			this.competencies = this.roleLevel.competencies.competencies.map(c => ({
				title: c.title,
				path: c.path,
				summary: c.summary,
				topics: c.topics.map(t => ({
					title: t.title,
					path: t.path,
					summary: t.summary,
					levels: t.requiredLevels.map(l => ({
						title: l.title,
						path: l.path,
						summary: l.summary,
						criteria: l.criteria.map(c => ({
							id: c.id,
							text: c.text,
							answer: this.reflectionView.answers[c.id],
							feedbackAnswer: this.reflectionView.answers[c.id]
						}))
					}))
				}))
			}))

			var userRole = new UserRole(this.role, this.roleLevel);
            userRole.applyAnswers(this.reflectionView.answers);
			this.completeness = userRole.getStats().completeness;
			
			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	
<div class="container" v-if="ready">

	<div class="row">
		<div class="col">
			<div class="header">
				<div class="header-body pt-0">
					<MeNav />
				</div>
			</div>
		</div>
	</div>
	<div class="row justify-content-center mb-4">
		<div class="col-6 text-center">
			<h1>Feedback time</h1>
			<p>Review <strong>{{user.name}}'s</strong> self-reflection and make any changes to their answers that you see fit.</p>
		</div>
	</div>

	<div class="row justify-content-center">
		<div class="col-12">
			<div class="alert alert-light mb-5">
				<h3 class="mb-4">Tips for providing feedback</h3>
				<ul>
					<li class="mb-1">Change any answers you feel are inaccurate</li>
					<li class="mb-1">Be honest</li>
					<li class="mb-1">Don't stress about 100% accurately measuring things. This isn't about being perfect its about evaluating trends and general areas for development.</li>
					<li class="mb-1">Don't rush, but don't spend too long struggling to evaluate a particular answer. Your first, gut reaction is usually pretty good.</li>
					<li class="mb-1">All of the criteria <em>do</em> apply to this team member. If you think something doesn't apply - and therefore they needn't be doing it - choose <strong>Never</strong> and later on we'll show you both how it does apply and how they can start doing it.</li>
					<li class="mb-1">
						If you change an answer, you can see the original answer by this indicator
						<div class="d-inline-block bg-white rounded pl-2 pe-none">
							<div class="custom-control custom-radio d-inline-block">
								<input type="radio" class="custom-control-input previous-value">
								<label class="custom-control-label color-3"></label>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<form @submit.prevent="submit" ref="form" class="mb-4">
				<template v-for="competency of competencies">
					<h2 class="">{{competency.title}}</h2>
					<p class="small border-bottom pb-3 mb-5">{{competency.summary}}</p>

					<template v-for="topic of competency.topics">
						<div v-for="level of topic.levels" class="card">
							<div class="card-header">
								<h4 class="card-header-title">{{competency.title}} - {{topic.title}} - {{level.title}}</h4>
							</div>
							<table class="table table-sm table-hover card-table">
								<thead>
									<tr>
										<th>Criteria</th>
										<th colspan="2">Never</th>
										<th></th>
										<th colspan="2" class="text-right">Always</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="criterion of level.criteria">
										<td>{{ criterion.text }}</td>
										<td class="shrink pr-0">
											<div class="custom-control custom-radio" :aria-title="criterion.answer == 0 ? 'Mentee\'s original answer' : null">
												<input type="radio" :name="`answers-${criterion.id}`" value="0" v-model.number="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 0 ? 'previous-value' : null}`" :id="`cbProvideFeedback${criterion.id}-0`">
												<label class="custom-control-label color-0" :for="`cbProvideFeedback${criterion.id}-0`"></label>
											</div>
										</td>
										<td class="shrink pr-0">
											<div class="custom-control custom-radio" :aria-title="criterion.answer == 1 ? 'Mentee\'s original answer' : null">
												<input type="radio" :name="`answers-${criterion.id}`" value="1" v-model.number="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 1 ? 'previous-value' : null}`" :id="`cbProvideFeedback${criterion.id}-1`">
												<label class="custom-control-label color-1" :for="`cbProvideFeedback${criterion.id}-1`"></label>
											</div>
										</td>
										<td class="shrink pr-0">
											<div class="custom-control custom-radio" :aria-title="criterion.answer == 2 ? 'Mentee\'s original answer' : null">
												<input type="radio" :name="`answers-${criterion.id}`" value="2" v-model.number="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 2 ? 'previous-value' : null}`" :id="`cbProvideFeedback${criterion.id}-2`">
												<label class="custom-control-label color-2" :for="`cbProvideFeedback${criterion.id}-2`"></label>
											</div>
										</td>
										<td class="shrink pr-0">
											<div class="custom-control custom-radio" :aria-title="criterion.answer == 3 ? 'Mentee\'s original answer' : null">
												<input type="radio" :name="`answers-${criterion.id}`" value="3" v-model.number="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 3 ? 'previous-value' : null}`" :id="`cbProvideFeedback${criterion.id}-3`">
												<label class="custom-control-label color-3" :for="`cbProvideFeedback${criterion.id}-3`"></label>
											</div>
										</td>
										<td class="shrink">
											<div class="custom-control custom-radio" :aria-title="criterion.answer == 4 ? 'Mentee\'s original answer' : null">
												<input type="radio" :name="`answers-${criterion.id}`" value="4" v-model.number="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 4 ? 'previous-value' : null}`" :id="`cbProvideFeedback${criterion.id}-4`">
												<label class="custom-control-label color-4" :for="`cbProvideFeedback${criterion.id}-4`"></label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</template>
				</template>
				<fieldset class="mb-4">
					<legend>
						Reflection details
					</legend>
				</fieldset>
				<p class="small text-muted text-center mb-3">You <strong>can't</strong> edit your feedback later, submission is final.</p>
				<button type="submit" class="btn btn-lg btn-block btn-primary">Submit feedback <span class="fe fe-check-circle"></span></button>
			</form>
		</div>
	</div>
	</div>

</template>