
import firebaseConfig from "../../firebase.config.js";
import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";

export function initFirebase() {

	const app = initializeApp(firebaseConfig);
	if (location.hostname === 'localhost') {
		const functions = getFunctions(app);
		connectFunctionsEmulator(functions, "localhost", 5001);

		const db = getFirestore(app);
		connectFirestoreEmulator(db, 'localhost', 8080);

		const auth = getAuth(app);
		connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });

		const storage = getStorage(app)
		connectStorageEmulator(storage, 'localhost', 9199)
	}
}