<script>

// Admin

import ActionTeamApplications from './actionTeamApplications.vue'
import ActionTeamApplicationsUserTask from '../../../../functions/domain/users/userTasks/members/admins/actionTeamApplicationsUserTask.js';

import SetupTeam from './setupTeam.vue'
import SetupTeamUserTask from '../../../../functions/domain/users/userTasks/members/admins/setupTeamUserTask.js';

import StartFirstReview from './startFirstReview.vue'
import StartFirstReviewUserTask from '../../../../functions/domain/users/userTasks/members/admins/startFirstReviewUserTask.js';


export default {
	data() {
		return { 
			task: this.task,
			currentUser: this.currentUser,

			ActionTeamApplicationsUserTask,
			SetupTeamUserTask,
			StartFirstReviewUserTask
		}
	},
	props: [ 'task', 'currentUser' ],
	components: { 
		ActionTeamApplications,
		SetupTeam,
		StartFirstReview
	}
}

</script>
<template>
	<SetupTeam v-if="(task instanceof SetupTeamUserTask)" :task="task" :currentUser="currentUser"></SetupTeam>
	<ActionTeamApplications v-else-if="(task instanceof ActionTeamApplicationsUserTask)" :task="task" :currentUser="currentUser"></ActionTeamApplications>
	<StartFirstReview v-else-if="(task instanceof StartFirstReviewUserTask)" :task="task" :currentUser="currentUser"></StartFirstReview>
	<div v-else>
		Missing AdminTask vue
	</div>
</template>