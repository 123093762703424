<script>

export const requireAuth = false;
export const pageName = 'account-sign-up';
export const pageRoute = '/account/sign-up';
export const title = 'Sign-up'
export const furniture = 'plain';

import { validation } from '../../js/language.js'
import {showToast} from '../../js/toast.js'
import { getAuth, updateProfile, OAuthProvider, GoogleAuthProvider, GithubAuthProvider, signInWithPopup, signInWithEmailAndPassword, fetchSignInMethodsForEmail, createUserWithEmailAndPassword, getAdditionalUserInfo } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { countPasswordBreaches } from '../../js/hibp.js';
import { navigate, PageShowError } from '@trullock/page-manager';
import { getLiteUser } from '../../js/readModel.js';
import { publish } from '@trullock/pubsub'
import { functions } from '../../js/functions.js';

export default {
	data() {
		return {
			name: '',
			email: '',
			password: '',
			agree: false
		 }
	},
	methods: {
		ssoGithub: function(){
			var provider = new GithubAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Github account');
		},
		ssoGoogle: function(){
			var provider = new GoogleAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Google account');
		},
		ssoMicrosoft: function(){
			var provider = new OAuthProvider('microsoft.com');
			this.signInWithSSO(provider, 'Account successfully linked to your Microsoft account');
		},
		submit: async function(){
			this.resetAuthErrors();

			if(!this.$refs.form.validate())
				return;
				

			let breaches = await countPasswordBreaches(this.password)
			if(breaches > 0)
			{
				this.$refs.form.setValidationResult({ password: validation.messages.breached(breaches)});
				return;
			}
		
			this.$refs.form.setSubmitting(true)

			let result = null;
			try
			{
				result = await createUserWithEmailAndPassword(getAuth(), this.email, this.password)
				await updateProfile(result.user, {
					displayName: this.name
				})
			}
			catch(error)
			{
				if(error.code == 'auth/email-already-in-use'){
					fetchSignInMethodsForEmail(getAuth(), this.email).then(methods => {
						if(methods.indexOf('password') > -1)
						{
							return signInWithEmailAndPassword(getAuth(), this.email, this.password)
								.then(user => navigate('goal'))
								.catch(_ => this.handleEmailAlreadyInUse(methods, 'auth/email-already-in-use'))
						}

						this.pendingCred =  firebase.auth.EmailAuthProvider.credential(this.email, password);
						this.pendingCredLinkedMessage = 'Password successfully associated with account';
						return this.handleEmailAlreadyInUse(methods, 'auth/account-exists-with-different-credential');
					});
					return;
					
				}

				this.$refs.form.setValidationResult(validation[error.code] || validation['error']);
				this.$refs.form.setSubmitting(false)
				return;
			}

			let createResult = await this.issueCreateUserCommand();
			let isInvitedToATeam = createResult.isInvitedToATeam;
			
			let liteUser = await getLiteUser(result.user.uid);
			publish('user changed', liteUser);
			
			navigate('goal', { fallback: isInvitedToATeam ? '/onboarding/teams/invited' : '/onboarding'});
			this.analytics(result);
			this.$refs.form.setSubmitting(false)
		},
		signInWithSSO(provider, linkedMessage){
			this.resetAuthErrors();
			this.$refs.form.reset();
			
			this.$refs.form.setSubmitting(true, this.$refs.btnSignUp)

			signInWithPopup(getAuth(), provider).then(async signInResult => {
				
				let isInvitedToATeam = false;

				let info = getAdditionalUserInfo(signInResult);
				if(info.isNewUser)
				{
					let createResult = await this.issueCreateUserCommand();
					isInvitedToATeam = createResult.isInvitedToATeam;
				}

				let liteUser = await getLiteUser(signInResult.user.uid);
				publish('user changed', liteUser);
				
				navigate('goal', { fallback: isInvitedToATeam ? '/onboarding/teams/invited' : info.isNewUser ? '/onboarding' : '/'});

				this.analytics(signInResult);

				if(this.pendingCred)
				{
					signInResult.user.linkWithCredential(this.pendingCred).then(result => {
						showToast({
							style: 'success',
							message: this.pendingCredLinkedMessage
						})
					}, e => console.error(e));
				}
			}).catch(error => {
				
				if(error.code == 'auth/account-exists-with-different-credential')
				{
					this.handleExistingAuth(error, linkedMessage);
					return;
				}
				console.error(error);
				this.$refs.form.setValidationResult(validation[error.code] || validation['error']);
			}).finally(() => {
				
			this.$refs.form.setSubmitting(false, this.$refs.btnSignUp)
			});
		},
		analytics: function(userCredential){
			// TODO
			// firebase.analytics().logEvent(userCredential.additionalUserInfo?.isNewUser ? 'sign-up' : 'login', {
			// 	method: userCredential.credential.providerId
			// });
		},
		resetAuthErrors: function() {
			this.$refs.btnGoogle.classList.remove('glow');
			this.$refs.btnGithub.classList.remove('glow');
			this.$refs.btnMicrosoft.classList.remove('glow');
			this.$refs.txtPassword.classList.remove('glow');
		},
		handleEmailAlreadyInUse: function(methods, errorCode){
			this.glowAuthProviderMethods(methods);
			this.$refs.form.setValidationResult(validation[errorCode])
			this.$refs.form.setSubmitting(false, this.$refs.btnSignUp)
		},
		handleExistingAuth: function(error, linkedMessage){
			this.pendingCred = error.credential;
			this.pendingCredLinkedMessage = linkedMessage;
			this.$txtEmail.value = error.email;

			fetchSignInMethodsForEmail(getAuth(), error.email).then(methods => {
				this.glowAuthProviderMethods(methods);
			});

			this.$refs.form.setValidationResult(validation[error.code]);
		},
		glowAuthProviderMethods: function(methods) {
			if(methods.indexOf('google.com') > -1)
				this.$refs.btnGoogle.classList.add('glow');
			if(methods.indexOf('github.com') > -1)
				this.$refs.btnGithub.classList.add('glow');
			if(methods.indexOf('microsoft.com') > -1)
				this.$refs.btnMicrosoft.classList.add('glow');
			if(methods.indexOf('password') > -1)
				this.$refs.txtPassword.classList.add('glow');
		},
		issueCreateUserCommand: async function()
		{
			let result =  await functions.userCreate({ name: this.name });
			return result.data;
		},
		show(opts) {
			if(getAuth().currentUser)
				throw new PageShowError('/', 'Already signed in', {}, 'replace')
			
			this.resetAuthErrors();
			this.pendingCred = null;
			this.pendingCredLinkedMessage = null;
			this.$refs.form.reset();
		}
	}
}

</script>
<template>
	<div class="container mt-5">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6">
				<div class="text-center">
					<h1 class="display-4 mb-3">Create an account</h1>
					<p class="px-4 text-muted">Start developing yourself.</p>
				</div>
			</div>
		</div>
		<div class="row justify-content-center mt-4">
			<div class="col-12 col-md-5 col-xl-4 my-5">
				<div class="text-center mb-2">
					<button @click.prevent="ssoGoogle" ref="btnGoogle" class="btn btn-white btn-auth"><img alt="Google icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" width="18" height="18"> Sign up with Google</button>
				</div>
				<div class="text-center mb-2">
					<button @click.prevent="ssoGithub" ref="btnGithub" class="btn btn-white btn-auth"><img alt="Github icon" src="../../img/github.svg" width="18" height="18"> Sign up with Github</button>
				</div>
				<div class="text-center mb-4 d-none">
					<button @click.prevent="ssoMicrosoft" ref="btnMicrosoft" class="btn btn-white btn-auth"><img alt="Microsoft icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg" width="18" height="18"> Sign up with Microsoft</button>
				</div>
				<hr class="mb-4" />
				<form novalidate ref="form" @submit.prevent="submit">
					<div class="form-group mb-4">
						<label for="txtSignUpName">Name</label>
						<input type="text" class="form-control" name="name" v-model="name" id="txtSignUpName" aria-describedby="txtSignUpName_Validation" autocomplete="name" placeholder="First Last" required tabindex="1" />
						<span id="txtSignUpName_Validation" class="feedback"></span>
					</div>
					<div class="form-group mb-4">
						<label for="txtSignUpEmail">Personal email address</label>
						<input type="email" class="form-control" name="email" v-model="email" id="txtSignUpEmail" aria-describedby="txtSignUpEmail_Validation" autocomplete="email" placeholder="your.name@example.com" required tabindex="1" />
						<span class="form-text small text-muted mt-2 mb-0">Use your personal address even if you're doing this through work, as you can take your account with you for life.</span>
						<span id="txtSignUpEmail_Validation" class="feedback"></span>
					</div>
					<div class="form-group mb-4">
						<label for="txtSignUpPassword">Password</label>
						<div class="input-group">
							<input type="password" name="password" v-model="password" placeholder="Something long and unique" class="form-control form-control-appended" autocomplete="current-password" required minlength="8" id="txtSignUpPassword" ref="txtPassword" aria-describedby="txtSignUpPassword_Validation" tabindex="3">
							<div class="input-group-append">
								<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
							</div>
						</div>
						<span class="form-text small text-muted mt-2 mb-0">8 characters or more, must not be a common password</span>
						<span id="txtSignUpPassword_Validation" class="feedback"></span>
					</div>
					<div class="form-group">
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" name="agree" v-model="agree" value="on" id="txtSignUpUserTnC" aria-describedby="txtSignUpUserTnC_Validation" required tabindex="3">
							<label class="custom-control-label" for="txtSignUpUserTnC">I agree to the <a href="/account/terms-of-service" target="_blank">terms of service <span class="fe fe-external-link"></span></a></label>
						</div>
						<span id="txtSignUpUserTnC_Validation" class="feedback"></span>
					</div>
					<button ref="btnSignUp" type="submit" class="btn btn-primary btn-block" tabindex="4">Sign up</button>
					<p class="small text-muted text-center mt-3">Already got an account? <a href="/account/sign-in" tabindex="5">Sign in</a>.</p>
				</form>
			</div>
		</div>
</div>
</template>