<script>

// TODO: treeshaking: https://www.chartjs.org/docs/latest/getting-started/integration.html
import Chart from 'chart.js/auto';


export default {
	data() {
		return { 
			title: this.title,
			labels: this.labels,
			data: this.data
		}
	},
	mounted() {
		if(this.data.length <= 1)
			return;
		
		var chart = new Chart(this.$refs.chart, {
			type: 'line',
			options: {
				scales: {
					y: {
						ticks: {
							min: 0,
							max: 10,
							stepSize: 0.5
						}
					}
				}
			},
			data: {
				labels: this.labels,
				datasets: [
					{
						label: 'Rating',
						data: this.data
					}
				]
			}
		});
	},
	props: [ 'title', 'labels', 'data' ]
}

</script>
<template>
	<section class="card">
		<div class="card-header">
			<h4 class="card-header-title">{{ title }}</h4>
		</div>
		<div class="card-body">
			<div v-if="data.length <= 1" class="chart empty align-items-center justify-content-center d-flex">
				<span class="">There is not yet enough data to display</span>
			</div>
			<div v-else class="chart">
				<canvas class="chart-canvas" ref="chart"></canvas>
			</div>
		</div>
	</section>
</template>