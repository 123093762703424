<script>

export default {
	data() {
		return { 
			task: this.task
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<section class="card">
	<div class="card-header">
		<h4 class="card-header-title">Wait for a review to start</h4>
		<a href="/browse/competencies" class="btn btn-sm btn-primary">Browse competencies <span class="fe fe-arrow-right-circle"></span></a>
	</div>
	<div class="card-body">
		<p>Your team is brand new and is just getting set up, so isn't ready for you to start reviewing yourself just yet.</p>
		<p>Once your team's administrator kicks things off, you'll be notified of what you need to do and this dashboard will contain instructions and tasks for you to complete.</p>
		<p class="mb-0">In the mean time, you might want to get a head-start and begin familiarising yourself with some of the content you'll be working towards by browsing the details of the competencies.</p>
	</div>
</section>
</template>