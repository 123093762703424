import { autoMapToFirestore, autoMapFromFirestore } from "./domain/automapper.js";

export function serializeData(obj)
{
	return autoMapToFirestore(obj);
}

export function deserializeData(obj)
{
	return autoMapFromFirestore(obj);
}