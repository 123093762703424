export default {
	"id": "72FF2C99-8CA5-49E4-8CC9-59F0B3A946D7",
	"title": "🍏 Core",
	"path": "core-competencies",
	"theme": "human",
	"summary": "The core competences enable staff to align job profiles, creating a shared understanding of how to attain career progression. Use the documentation of the core competencies to identify your current level, and identify the areas you'd like to improve in. Get started by selecting a core competency.",
	"topics": [
		{
			"id": "D7B9C33B-8EC6-41C5-A1F8-0102A210EC7E",
			"title": "😀 Attitude",
			"path": "attitude",
			"theme": "human",
			"summary": "Attitude is everything. Attitude determines the success or failure of the thing we set out to do. We need to be adaptable and open to change, willing to listen to and take on new ideas. A good attitude enables and encourages cohesion and collaborative problem solving.",
			"levels": [
				{
					"id": "2A9D7899-D57E-4B9D-8A5D-780C3B00411E",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Keen and willing to learn",
					"criteria": [
						{
							"id": "279F6E8D-664C-42A4-9911-7511422799D1",
							"text": "Enthusiastic for subject and has a willingness to learn",
							"clues": [
								"Enthusiasm can't really be taught, you have to want to want to do you job. If you aren't enthusiastic or have fallen into a rut, what you need to do ask yourself why.",
								"You need to be willing to learn. If asked, most people will say they are open to learning, but not many really are, not at the level required. Reflect on your own humility. Are you suffering from https://en.wikipedia.org/wiki/Dunning%E2%80%93Kruger_effect?",
								"Are you being too short-sighted in your evaluation of opportunities to learn? Take a step back and look at the people and processes around you. Make a list, write it down. Who knows about what? What processes are happening that you either don't know much about or aren't involved in?",
								"Why are you not more enthusiastic?"
							]
						},
						{
							"id": "38F5BB0B-4636-4900-A380-408D0DDCE110",
							"text": "Passionate about your work and the subject in general",
							"clues": []
						},
						{
							"id": "2E1C605D-4176-41BB-AB90-3B415C5A10A9",
							"text": "Happy working in a fast-paced and quickly evolving team",
							"clues": [
								"This can be a cliché, and is touted so often on job adverts that it no longer has any diffrentiating meaning. The faster you can produce outputs, the more valuable you are to yourself and your employer.",
								"Realise that more speed (not haste) truly is an important virtue.",
								"Take a moment to understand the cost of your employment. You are paid a salary, but you also need to include bonuses, benefits, pensions and you have an overhead in terms of equipment, software licenes, electricity, coffee etc. There is also a time cost in terms of processing your payroll and other administrative functions. Some estimates put the total cost of employment for an average-salary employee at double the salary value. This is huge. Put youself in the shoes of the boss/person in charge of finaces. In order to be able to afford to pay for you (<=2x salary, remember) to break-even you need to be generating the company that much in revenue."
							]
						},
						{
							"id": "D7247C13-2E0B-4247-92F9-C8FE6A659A1D",
							"text": "Flexible and open to change",
							"clues": [
								"Unless you're dealing with the physical laws of nature, understand that every rule has simply been made up by someone. Every rule was made for a reason, and without being a jerk about it you should be open to reviewing rule's and evaluating if they are still fit for purpose.",
								"Processes exist because humans are imperfect. If everyone behaved in an all-knowing, ideally operating way then no process would ever need creating or writing down, because everything would be perfectly executed all of the time. Understand that processes exist to bridge the gap between this idealisting scenario and reality. If the reality has changed and an old process is no longer fit for purpose, evolve it. Read Who Moved My Cheese",
								"Help others come to terms with the above, and that they shouldn't be defensive or feel threatened by change. Resisting change hurts everyone involved, there are never any long-term winners."
							]
						},
						{
							"id": "A2FAF86E-915A-4804-B884-0BCDA7606080",
							"text": "An analytical mindset with a strong eye for detail",
							"clues": [
								"The devil is in the detail, so they say. And they are right.",
								"In one sense you want to be that annoying child asking \"why? why? why?\", just try to do it without the being-annoying part.",
								"Bringing the Why to the forefront of every conversation is really important, as it helps everyone to understand the purpose of the activity. Understanding the purpose is the key to producting effective solutions and it's usually these sorts of discussions that bring out the missing details.",
								"Try not to literally ask \"Why?\". Using why is a fast route to upsetting people and inviting them to be defensive. Some psychologists attribute this to childhood where when you here the question \"Why [have you done that]?\" you're usually in trouble. Instead, phrases like \"What's the thinking behind that approach?\", \"Can you help me understand how we've arrive at that conclusion?\" and playing stupid helps; \"Sorry, I don't get it, can you help me understand whats best about this approach?\"."
							]
						},
						{
							"id": "88E262E5-5708-4B96-9635-49BC182238B5",
							"text": "A strong work ethic and professional attitude",
							"clues": [
								"Having the restraint to remain professional in the face of "
							]
						},
						{
							"id": "D42D39F0-83EF-42C3-83FD-5EF7C591E790",
							"text": "Understanding of how to prioritise work and what attributes to value"
						},
						{
							"id": "02319806-88DF-476F-AEF9-B2ACA701D9CB",
							"text": "Team player who enjoys collaborative problem solving"
						}
					]
				},
				{
					"id": "4B1CC4F7-9ED4-43EF-897F-D86991C02276",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Self motivated",
					"criteria": [
						{
							"id": "551CB880-0942-465A-9616-FEACE9297A16",
							"text": "Fosters an appropriate sense of urgency to deliver results at pace"
						},
						{
							"id": "EC38C882-F6EF-4CD1-ACBF-F07895B26301",
							"text": "Self-motivated to achieve; shows initiative and performs to a high level with low levels of supervision"
						},
						{
							"id": "2B70B686-3D3A-4CAD-A4BA-F9BC60A5916B",
							"text": "Willingly seeks input and ideas from colleagues"
						},
						{
							"id": "8875B427-45DC-46BD-BD19-801BD4D2A54F",
							"text": "Completes tasks and ensures they meet the original requirements"
						},
						{
							"id": "56125267-1ABE-43B8-B032-1A6B88C1EE6B",
							"text": "Comes to line manager with potential solutions to problems rather than just presenting the issue"
						},
						{
							"id": "F47C8E9F-D036-4820-B651-1BEAE0CEDE06",
							"text": "Distinguishes between relevant and irrelevant information in order to make logical decisions"
						},
						{
							"id": "F064E101-3A0F-4EF6-BB47-5B6D45E9C2CF",
							"text": "Openly acknowledge mistakes & takes action to rectify them"
						},
						{
							"id": "2568B64F-FF5F-4671-BA96-4E57AEA16247",
							"text": "Asks for help when it’s required - doesn’t accept being Blocked on work"
						},
						{
							"id": "CE9A05BA-8D09-44D3-A8DB-1916CB0BB982",
							"text": "Continuous self-improvement and a hunger to learn"
						},
						{
							"id": "846A1035-C73C-4209-A66D-82117A82291D",
							"text": "A self-motivator - picks up the correct work without having to be asked"
						},
						{
							"id": "75CA9D6B-7242-450E-A321-3FE5A62A1E35",
							"text": "Strong analytical and diagnostic skills, particularly with regard to technical issues and constraints"
						},
						{
							"id": "2A0A3527-FBD4-41CF-A100-804E55A7728E",
							"text": "Help build a ‘delivery with high quality’ culture that is accountability and results orientated"
						}
					]
				},
				{
					"id": "1C24B488-3A89-4B93-9BE5-D6A5D59F4015",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Takes responsibility",
					"criteria": [
						{
							"id": "59B729B3-F382-4CB6-89C3-EA776A0EBA36",
							"text": "Take responsibility for decisions, ensuring plans are followed and regularly reviewed and non-standard approaches are approved by leadership"
						},
						{
							"id": "F74BFC93-92DC-46C8-9292-D93B89BC2CBA",
							"text": "Responsible for delivery, serve as a liaison between the delivery team and stakeholders"
						},
						{
							"id": "C7D0EB0B-DCBA-4B24-9EA3-6C0BD82FB0C1",
							"text": "Drives the team to continuously improve the skills of everyone"
						},
						{
							"id": "B97CA7DA-4859-406D-8451-4938176D64E3",
							"text": "Be a leader within a multidisciplinary team"
						}
					]
				},
				{
					"id": "2075E16A-2E1A-49CE-B31D-80480F109BCC",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Team leader",
					"criteria": [
						{
							"id": "911E117E-A208-4FED-9AB4-B41B62394443",
							"text": "Actively monitors and reviews employee engagement",
							"clues": [
								"You do not need to be a manager to do this, everyone should have their finger on the pulse of how their colleagues are feeling, behaving and performing. This is one of the ways to building strong and meaningful relationships and teams. Take a few moments at the end of each day to reflect on at least your immediate colleagues and consider how they're feeling and how engaged they are.",
								"By making a conscious effort to be more aware of your colleagues mental states, you'll be much better placed to have valuable and productive interactions with them. Think about right now, how much do you really know about your colleagues feelings/attitude? Probably not enough.",
								"You need to take action on your findings. Regardless of whether you're a manager or not, what can you do with your observations? If you observe someone is feeling low, take them out for a coffee. If someone's on a high, celebrate this - how can you bottle this and feed it to the rest of the team? Encourage sharing and collaboration."
							]
						},
						{
							"id": "267F7C0F-07E2-4099-8028-37C225CE655D",
							"text": "Maintain a constant appreciation of the bigger picture to understand intimately the dependencies between components and how they combine to deliver service to the end consumer and to the business"
						}
					]
				}
			]
		},
		{
			"id": "CC64EFA5-9373-49CE-B649-0E4911C56340",
			"title": "📯 Delivering Value",
			"path": "delivering-value",
			"theme": "human",
			"summary": "Delivery of value is a key component in the success of your career. Being commercially aware, understanding costs around work and the impact of those costs on the business are all important.",
			"levels": [
				{
					"id": "2C1D9CC6-6A2D-4609-A93A-6D9339C570BF",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Commercially Aware",
					"criteria": [
						{
							"id": "86FC6351-16C7-413B-BB4B-D4F550C035B0",
							"text": "Actively contributes to the design of products and solutions",
							"clues": [
								"This can be done whether you're the boss or the new intern. If you're new to a project/team/company realise you're in a unique position. Everyone else is engrained in the project, and whilst they may have more domain knowledge than you, you have a fresh pair of eyes. Ask the stupid questions, suggest alternative approaches. What opportunities can you find to do this?",
								"Your utility and value to your employer is your ability to solve problems. If you're in a position where you're told exactly what to do, go hunting for problems and opportunities to improve. There will be a cultural etiquette to observe, so don't be a jerk about it, but finding problems earlier is better than finding them later. How can you be proactive in hunting for previously undiscovered issues?",
								"How active and involved are you in design sessions? If you're not invited, work out why, what can you do to change this? If you are invited, are you sat on your phone or do you arrive prepared and researched ready to produce the best solution as efficiently as possible? Think about the gap between these scenarios and reality.",
								"Think about your personal brand. Do others see you as the person who contributes or the person who does not? What can you do to ensure everyone is aware of your contributions and value?"
							]
						},
						{
							"id": "8B0A2AE9-E5AF-40A2-AAC6-F5ACF6A22E9E",
							"text": "Aware of the value of providing outstanding client service and what can be done on a day-to-day basis to deliver this",
							"clues": [
								"Client Focus is a whole competency in itself, but in the context of Delivering Value you need to appreciate the power of perception. Without being a show-off, its valuable that the people you deliver work to (your clients - this could be your boss, colleague or customer) understand and see your contributions as valuable. How visible are your contributions and what can you do - without being a jerk - to raise visibility?",
								"Value - as far as your client goes - is a construct in their mind. You need to find the right balance between giving the client what they want and what they need. You also need to deliver this in a way that helps them see the value in your alternative suggestions. How good are you at explaining the value in your solutions? Practice explaining value in your client terms. Use their terminology, ideas and requirements to speak on their level."
							]
						},
						{
							"id": "5C856A05-CA10-40A0-9D5E-F975C7555F5D",
							"text": "Justifies the use of business resources and suggested changes to processes in terms of value deliverance",
							"clues": [
								"Everything comes down to value, and explaining and justifying ideas in terms of its value will help you succeed. How well do you understand the full value of your proposals? Practice exploring all the possible side effects (good and bad) of your ideas - think how they touch all the areas outside of your domain of knowledge and control. This will feel unfamiliar at first, you may not know what other people care about. This is step 1.",
								"You must put things in terms your stakeholders understand and care about. Draw up a table of all of the stakeholders in a project and identify their main areas of concern, e.g. finance, governance, legal, technical. Does your solution or idea account for each stakeholder's requirements? You shouldn't ever expect to be able to know what each expert stakeholder knows about, thats the reason for their existence - they are the experts in their field. What you should do though is your due dilligence in identifying and consulting each stakeholder to ensure their view on value has been considered.",
								"Justifying is then much easier with the above steps taken. Management are much more likely to agree an idea if you can demonstrate a well thought out plan. Remember though, your goal in not to justify your idea by any means, its to validate it and either rule it out or take it forwards. Being able to rule out bad ideas is just as - if not more - valuable as proving the value of good ones. It will not reflect bad on you that you had an idea and rules it out. Consider objectively how this looks to someone else. Everyone can have an idea, ideas are cheap, and therefore not especially valuable. Having the robust mechanisms to evaluate their utility and value is whats important. Work on this."
							]
						},
						{
							"id": "38595006-3C54-48E6-AD24-70572D02C7E9",
							"text": "Clear on the purpose and value of all tasks and will seek clarification when not",
							"clues": [
								"You will often have a lot of scope in how to perform your duties/tasks, to be valuable means to do just enough, as quickly as possible. Just-enough does not mean being lazy or apathetic, doing any more than just-enough is a waste of time, energy and money. Any argument about this is really just a differing of opinion on what qualifies as just-enough. To fight this, try employing clarifying techniques like https://en.wikipedia.org/wiki/MoSCoW_method to ensure all parties are 100% clear on what a successful job-done looks like.",
								"Often you'll be asked to do tasks where the purpose is not clear, and the person assigning the task may not also know the purpose. Without being a jerk or overstepping any social boundaries, can you and the task-assigner work together to help each other identify what the purpose and value of a task is? They will probably appreciate you helping them, they want to do a good job as much as you do. Be tactful.",
								"Assumption is the mother of all mistakes, being able to identify and discuss the assumptions on which an idea is bases can be a tricky skill to pick up at first. Practice identifying the assumptions in every task, and discuss these before work commences. Ensure stakeholders are clear on the assumptions and are happy to commit to work on the basis of the mutual understanding of the assumptions."
							]
						},
						{
							"id": "B8ECE1CA-BCEF-405C-9F2B-80E152EE51DC",
							"text": "Accurately estimates the amount of effort required to complete projects, and performs duties to the required standard within agreed timescales",
							"clues": [
								"Estimating is hard, we're not pretending it isn't. But what you can do is track estimates and their accuracy over time to identify errors and their causes. Are you tracking your estimates vs actual time taken? Start doing this, preferably as a team but at least individually.",
								"When a task is completed and you have a value for actual-time-taken vs the estimate, evaluate why the value is what it is. What made is so accurate or inaccurate? What caused it to be like it is? Do this as a group where possible. Write these reasons down and next time you make an estimate, review the previous list and see if you should adjust your estimate.",
								"Once you've done the above several times you'll start to see patterns, the main culprits for inaccurate estimates are the following: 1. Estimating how long it will take you (personally) - will it be you doing the work? Try https://en.wikipedia.org/wiki/Planning_poker as a group to help solve this. 2. Estimating how long it would take under some idealistic, distraction free conditions. This is ok as a first step, but its important to be clear when producing documentation. Just because a job will take 10 hours work, does not mean it will be ready in the next 10 hours. 3. Not appreciating the scale or complexity of the problem. What was missing in planning?"
							]
						},
						{
							"id": "28E85B43-46B7-4A8C-B25E-A9253F434051",
							"text": "Aware of the competitive nature of your business/team/product, who you compete with and how you can be different",
							"clues": [
								"What is valuable to your team in terms of the bigger picture or wider market? Do you want to have the highest quality? Have the fastest turnaround? Be the cheapest? Take the time to establish or identify team/company/product level values that will underpin the way you work. This should the be factored into all work done, if it doesn't meet the principles you establish here, you should ask why you're doing it (that way)."
							]
						}
					]
				},
				{
					"id": "EF6D04FE-BF36-4AEC-B565-903EA51BE65C",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Improved Profitability",
					"criteria": [
						{
							"id": "6496AAC7-BC5B-45B3-AF89-10B2AABACB6C",
							"text": "Bases recommendations on citable, sound analysis of risks, benefits and value"
						},
						{
							"id": "3F9A8859-244F-46C4-83AC-7D5DD7125075",
							"text": "Considers and discusses alternative ways of performing tasks to enhance productivity and delivery of value"
						},
						{
							"id": "3CE27993-E1EB-465A-9396-BC8692CF5707",
							"text": "Demonstrate a robust approach to producing estimates i.e. breaking down tasks into sub-elements and quantifying time required"
						},
						{
							"id": "4DBFCEF4-E3EF-4EE7-8E52-A1DD4882D38B",
							"text": "Demonstrates appreciation of RoI when defining and performing tasks by vocally considering effort vs value return"
						},
						{
							"id": "CB55F640-DA95-4B9E-B3E4-65D9CF8E0B1F",
							"text": "Identifies opportunities with existing products/solutions to further develop value opportunities"
						},
						{
							"id": "9D795CDB-5E4E-43C9-8351-D29AD5706198",
							"text": "Self-conscious of rabbit-holes, and can identify when in one"
						},
						{
							"id": "A6913A60-7442-4688-B8D0-607C0C8A15DF",
							"text": "Holds an iterative, and value-incrementing approach to solutions development, always considering MVP/S"
						},
						{
							"id": "EF897E23-6FF5-4EC2-80E5-2A2F1E345E94",
							"text": "Doesn’t over-engineer or gold-plate solutions unnecessessarilly"
						}
					]
				},
				{
					"id": "AC3E47CD-0E7B-423C-AFC9-137E5E449C00",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Delivers ROI",
					"criteria": [
						{
							"id": "356D0E3A-52EB-42AE-BCE4-B844E2224CC8",
							"text": "Tracks and proactively reports team/project feature/value deliverance"
						},
						{
							"id": "4AB7C711-BDFD-4733-B5EF-FBBFCC386D23",
							"text": "Monitors expenditure against budgets and initiates corrective action as required"
						},
						{
							"id": "5C10CA19-8182-4D6E-BA28-2EBC510806D6",
							"text": "Monitors and reports on implementation of financial and technical investment decisions and activity in terms of ROI"
						},
						{
							"id": "CEB83F02-8A17-4ED3-966E-E2C208DF9FDB",
							"text": "Proposals are accurately costed & accurately predict the iteration duration"
						},
						{
							"id": "58D8AC4A-82FD-4AE0-9575-53016D7DF13D",
							"text": "Makes informed decisions regarding the acceptance of projects based on their viability"
						},
						{
							"id": "22DA41E0-25AA-4EF8-AF65-73A5F6EE2299",
							"text": "Proactively identifies risks and raises with senior leaders"
						},
						{
							"id": "81D951F1-C8F9-480F-9333-DCFCA4C08314",
							"text": "Makes robust business cases when requesting investment"
						}
					]
				},
				{
					"id": "847A7469-EE16-49B0-A113-D616DD942095",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Optimises Corporate Value",
					"criteria": [
						{
							"id": "7303C489-B7EF-4728-8104-B0FA754208A6",
							"text": "Tracks team / department / company KPIs and maintains robust forecasts"
						},
						{
							"id": "A5EC5186-EE5D-4E65-8B38-069A74B80307",
							"text": "Conducts detailed planning/modelling of new proposals to comprehensively evaluate the value/RoI impact of options."
						},
						{
							"id": "9DD08CF1-F6A4-4476-BE14-81882E1A5E1E",
							"text": "Has a sound understanding of accountancy terms linked to business accounts; applies knowledge to inform business operations"
						},
						{
							"id": "C2A8380A-A0E1-425D-A2C0-B61F835FA223",
							"text": "Manages technical/financial risks, develops robust systems to mitigate any identified risks."
						},
						{
							"id": "5F75A090-102D-4519-842D-D173C4642D4C",
							"text": "Employs robust and evidence based approach to developing strategy; works to understand audience dynamics, clearly identify needs, segment of the audience, offer competitively advantaged products and services."
						}
					]
				}
			]
		},
		{
			"id": "DD96672C-01BD-4691-AFFB-3456808CA279",
			"title": "🎯 Client Focus",
			"path": "client-focus",
			"theme": "human",
			"summary": "Delivering good service to clients (members, partners and internal customers) is a baseline we should all be adhering to. These activities include understanding client requirements, remaining client focused and being a visible point of contact for a client. As you develop skills, you're expected to be more consultative, spending more time with clients, gathering requirements and proactively seeking feedback. The final level of mastery sees you proactively providing updates to clients, asking probing questions to really get to the root of the problem the client is looking to solve.",
			"levels": [
				{
					"id": "B06F5DB1-50CD-4D24-82F5-12A29217AEF4",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Good Client Service",
					"criteria": [
						{
							"id": "3E5E6160-1EAD-432B-A3FA-CA6F612DD592",
							"text": "Work activities are directed by, or focused on client needs; client remains primary focus"
						},
						{
							"id": "D959DEBA-9F87-4F58-8801-893BEB1FB825",
							"text": "Demonstrates a genuine interest in clients and visibly acts in their best interests"
						},
						{
							"id": "6787B8C7-ECE9-4488-8A95-449642FE646D",
							"text": "Makes self-visible or available as a point of contact for clients"
						},
						{
							"id": "8B13B741-620E-4F45-A2EA-3811FC7DF2A1",
							"text": "Manages all client enquiries in a professional and understanding way"
						},
						{
							"id": "28712908-4366-41ED-9EDD-BEC2E268F18A",
							"text": "Invites and receptive to client feedback"
						},
						{
							"id": "E955F366-687D-40D1-9F9C-C4ED9AA7A723",
							"text": "Understands who their client and stakeholders are for every task"
						},
						{
							"id": "24B5D27F-7FE7-41A3-863F-E090621B437A",
							"text": "Responds and meets client requests in a timely and appropriate manner"
						}
					]
				},
				{
					"id": "1F029560-657C-4F8F-839A-79EC658371AD",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Meets Clients Needs",
					"criteria": [
						{
							"id": "6B89CEC1-302B-4937-A9BE-47370DCE385F",
							"text": "Actively listens to clients to identify and understand their needs and objectives; asks questions to identify client interests, views & motivations"
						},
						{
							"id": "F7EAE88B-6551-4FF3-AC50-F016B4201027",
							"text": "Consults clients when exploring solution options"
						},
						{
							"id": "831D3F2D-7B28-4649-B692-7844E5392D6A",
							"text": "Proactively undertakes appropriate research to build client knowledge & help inform discussions"
						},
						{
							"id": "F7DAB978-91CA-4501-B7B4-4F4D0B5A6A3D",
							"text": "Ensures clients are kept regularly informed regarding project progress; displays a highly proactive approach to providing updates"
						},
						{
							"id": "8749BBD7-CCB7-4454-9158-49A1699DCD4D",
							"text": "Proactively uses knowledge of client, their needs & objectives to drive the development & delivery of appropriate solutions that meet their needs"
						},
						{
							"id": "09C89E9E-C5CC-41BD-B287-24401845119D",
							"text": "Successfully adapts solutions to reflect changes in client requirements"
						},
						{
							"id": "66411197-79A4-4654-BB3D-A2D719117DA9",
							"text": "Develops solutions which clients find easy to use and understand; adopts the client’s perspective to aid product design"
						}
					]
				},
				{
					"id": "053E12E7-7881-4320-8D36-B7D3F75417CE",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Adds Value For the Client",
					"criteria": [
						{
							"id": "436AF91E-597C-4152-ACF2-A897F84AD516",
							"text": "Utilises broad domain knowledge to recommend improved solutions"
						},
						{
							"id": "649EA625-3336-4620-B9BA-219020D1AF9B",
							"text": "Ability to deal with changing requirements from clients and changes in focus"
						},
						{
							"id": "BEC33921-6B27-4426-A0B2-938FBF44BDD9",
							"text": "Represents the client and their interests in discussions, and ensures all decisions are made with the client’s context considered"
						},
						{
							"id": "2BF40842-E338-45AA-A842-7C20F9B04819",
							"text": "Undertakes appropriate research to build Client knowledge & help inform discussions"
						},
						{
							"id": "80B8FD35-D94D-48EB-8B00-6A922736B7C0",
							"text": "Maintains the customers best interests at heart. Designs solutions which are appropriate and add the required value."
						}
					]
				},
				{
					"id": "6AB7074B-C720-42BE-882E-A2E65DA3FFF0",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Works in Partnership with the Client",
					"criteria": [
						{
							"id": "4C04B47D-B7F0-4F74-AE0D-DB2560EA1E7F",
							"text": "Conceives and drives the development of solutions that anticipate impending client needs"
						},
						{
							"id": "968B6B9B-52FF-41AF-8AC3-771D7B3A763D",
							"text": "Ability to read clients and agree desired outcomes that are mutually beneficial i.e. win/win outcomes"
						},
						{
							"id": "EF1506EF-3814-4E1B-A696-4B5D3FFD7DA5",
							"text": "Proactively uses knowledge of the client, their needs & objectives to drive the development & delivery of appropriate solutions that meet their needs"
						},
						{
							"id": "C77E4945-742A-450F-8030-2959E872FA37",
							"text": "Ensures clients are kept regularly informed regarding project progress; displays a highly proactive approach to providing updates."
						}
					]
				}
			]
		},
		{
			"id": "6BB91077-DDE1-4997-81D1-3159F3C8D50E",
			"title": "📅 Planning and Organisation",
			"path": "planning-and-organisation",
			"theme": "human",
			"summary": "A baseline organiser is self organised, consistently communicating and consulting with stakeholders when required. As this skill develops, the expectation is that you'll begin to create action plans, set expectations with stakeholders and meet those expectations. Once you've levelled up this skill, you'll be conducting analysis of markets, setting SMART objectives and creating KPIs/performance metrics to track implementation. You'll be making decisions based on evidence and scientific methods. It's key that as a master of this skill you set expectations for both stakeholders and the delivery teams around you.",
			"levels": [
				{
					"id": "19B26834-9FF4-4784-B758-E4EF4D62812D",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Organised",
					"criteria": [
						{
							"id": "D556E215-7130-4DB7-8608-DF79C67AAF0D",
							"text": "Actively listens to identify and understand needs and objectives; asks questions to identify interests, views & motivations"
						},
						{
							"id": "BC619CCF-F18A-4A18-83F3-0D446F1FDEC4",
							"text": "Understands the objectives of and participates actively in team ceremonies. Prepared and organised to make the most of squad time."
						},
						{
							"id": "11C13853-3B9B-47EB-967D-B53D3548791A",
							"text": "Consults stakeholders when exploring solution options."
						},
						{
							"id": "379DD617-F418-4F31-8F5A-7E0910C0DCE8",
							"text": "Undertakes appropriate research to build client knowledge & help inform discussions"
						},
						{
							"id": "1218F262-A875-4782-B330-3CA99005124A",
							"text": "Fully competent with the day-to-day use of productivity tools"
						},
						{
							"id": "BF0FBB4B-4F82-41D0-A84E-21B830A58362",
							"text": "Proactively uses knowledge of the business, needs & objectives to drive the development & delivery of appropriate solutions"
						},
						{
							"id": "745605A0-C992-4C7F-805D-1AEB9E3629EC",
							"text": "Successfully adapts service provision to reflect changes in requirements"
						},
						{
							"id": "5369DA68-4B6A-44CA-BEBB-990F10935793",
							"text": "Ensures stakeholders are kept regularly informed regarding project progress; displays a highly proactive approach to providing updates"
						}
					]
				},
				{
					"id": "9CF5ECD7-3EBE-441D-B4CB-1E44973075FE",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Forward Thinking",
					"criteria": [
						{
							"id": "1DA6D598-DA34-4FDB-8DE3-7D8A65039475",
							"text": "Develops clear, documented action plans, with realistic expectations & objectives"
						},
						{
							"id": "BA4A080F-B928-4D74-A8F9-FA4815AFB021",
							"text": "Anticipates & realistically appraises potential problems or changes; develops contingency plans to ensure desired outcome is achieved effectively within agreed timelines"
						},
						{
							"id": "17B59FFA-58DA-4FFF-A6CE-44448A00E260",
							"text": "Converts plans to task components for self and others, including timelines & targets"
						},
						{
							"id": "9F91739F-F536-4F49-A190-D9796D066B49",
							"text": "Accurately tracks own development progress and effectively forecasts output"
						},
						{
							"id": "2BFC06F8-032D-46E4-A3C7-A7200B00BB7C",
							"text": "Takes full responsibility for own productivity and output."
						},
						{
							"id": "672A46E3-9654-45C9-A11E-CC7B7C0E96DE",
							"text": "Accurately identifies & secures required resources before implementing plans e.g. adequate capacity in team"
						},
						{
							"id": "1FA98F53-B271-46E1-A558-F5BDAF1BDD3F",
							"text": "Able to balance time dedicated to maintaining existing business with business development"
						}
					]
				},
				{
					"id": "43C5218F-EDD5-40BE-8C03-A270AB625232",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Project Planning",
					"criteria": [
						{
							"id": "31E9FAF6-2410-415C-A8E6-8D5AE11213E7",
							"text": "Manages multiple complex projects using established project management methodologies"
						},
						{
							"id": "F620A2CF-6015-4A53-AEBD-18CF6B6A7F33",
							"text": "Integrates inputs from different sources/team members; optimises their contribution relevant to work programme"
						},
						{
							"id": "DE4BEB2A-78D7-4EFE-958C-DDB8E170009D",
							"text": "Optimises the allocation of resources"
						},
						{
							"id": "182DECDB-8E65-4064-B2B7-72C4CDFFF0DA",
							"text": "Assigns responsibilities and sets deadlines for tasks"
						},
						{
							"id": "5A8BBB95-4DF2-4F6C-8643-E8FEAEB8BA0B",
							"text": "Identifies critical path activities from project plans; concentrates extra effort on tasks where any delays impact on overall deadline"
						},
						{
							"id": "EF215BA3-047D-4E2C-A712-EED88C688BC8",
							"text": "Sets/attends regular project meetings to assess progress against action plans and to ensure monitoring activity is routinely conducted"
						},
						{
							"id": "9C823A3D-37B1-44F3-9F03-E120EE108987",
							"text": "Varies project plan activity sequencing and timelines according to available resource to avoid delays"
						}
					]
				},
				{
					"id": "B1AA8BE9-6844-4B10-AACD-F87C9F058DD6",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Strategic Planning",
					"criteria": [
						{
							"id": "5D55BD90-C239-49FD-A0B0-912700091C71",
							"text": "Conducts a robust situational analysis – market/technology attractiveness & competitive position"
						},
						{
							"id": "D156F0F5-5615-47BF-A5A9-740794AB252E",
							"text": "Devises strategies aligned to analysis of market/technology; aware of alternative strategic options "
						},
						{
							"id": "7FA923AC-57C8-428A-B897-3367E79CA34E",
							"text": "Segments the market to identify most value generating client groups or segments; positions company offer to satisfy the needs of key user groups"
						},
						{
							"id": "2997CD6B-B46E-46B5-9DB7-C4EF1FE13297",
							"text": "Sets SMART Strategic objectives/goals; devises critical success factors (CSFs) aligned to strategy; sets KPIs/performance metrics to track implementation"
						},
						{
							"id": "B5EB6435-70D0-413D-8BA3-A86615C4C617",
							"text": "Presents evidence to justify strategy adopted"
						},
						{
							"id": "A59EDD42-B908-4CCD-971D-36E7710C3E6F",
							"text": "Outlines exactly how strategy will be delivered; clarifies accountabilities, workload, resources needed, milestones and expectations"
						}
					]
				}
			]
		},
		{
			"id": "A74ABB7D-5A81-4D18-80EB-2F0F76B517D4",
			"title": "🤹 Problem Solving",
			"path": "problem-solving",
			"theme": "human",
			"summary": "A solution focused approach is a good foundation to build this skill on. You should be presenting solutions to decision makers, as opposed to presenting the problem. Impediments or blockers don't exist- everybody should refuse to become blocked. You should always see that there is always a way to move things forwards, and you do. Further understanding of this skill involves you developing and actually resolving the problems, making recommendations where necessary, based on evidence and analysis. You'll be using your judgement to quickly make good decisions based on limited evidence.",
			"levels": [
				{
					"id": "78F173AB-E2ED-4832-A933-0A0428EAEC14",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Solution Focused",
					"criteria": [
						{
							"id": "D6C07657-B4FA-4816-8D00-4E2BA73FF180",
							"text": "Takes immediate action or initiative to resolve issues preventing satisfactory work completion; including seeking help from others"
						},
						{
							"id": "8C5A985F-3616-453D-9DED-1E878C321F02",
							"text": "Comes to line manager with potential solutions to problems rather than just presenting the issue"
						},
						{
							"id": "CB34F03B-F57D-4209-8530-D12D5ECDF50E",
							"text": "Considers past experience of similar situations & existing procedures to think through potential approaches to addressing problems"
						},
						{
							"id": "7FAFE253-29D6-40CD-A722-8EB6D97A2577",
							"text": "Records solutions appropriately, with concise and complete explanations and justifications"
						},
						{
							"id": "5003EBD8-009F-419C-A272-6F5B59C0822B",
							"text": "When asked can suggest some improvements to work processes to prevent future problems"
						},
						{
							"id": "FCBC36C1-A2C5-4125-A3BB-0637D230A8ED",
							"text": "Refuses to become blocked. Tries to unblock themselves first before seeking help"
						},
						{
							"id": "BFE3833E-CAD8-4B88-9DAE-40C72A3C3463",
							"text": "Distinguishes between relevant and irrelevant information in order to make logical decisions"
						},
						{
							"id": "C9E58D46-B114-401D-BF89-5E5869CC8C6C",
							"text": "Openly acknowledge mistakes & takes action to rectify them"
						}
					]
				},
				{
					"id": "9EDFB4DE-7435-4DF2-815C-A5856BA371A2",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Structured Problem Solving",
					"criteria": [
						{
							"id": "99299BEA-E60A-4543-84E8-45258A30E0E4",
							"text": "Uses research to better understand an issue and come to reasoned decisions about how to resolve a problem"
						},
						{
							"id": "B1C21F52-B584-4646-90BC-557996BB0659",
							"text": "Identifies the root causes of blockers"
						},
						{
							"id": "45F7C3D3-2075-46D6-B7C6-AE1788FCF8E1",
							"text": "Uses visualisation tools (SWOT, brainstorming, cause-effect diagrams) to gain a fuller understanding of issues"
						},
						{
							"id": "E2A032E5-29A6-44C0-9F3E-FCD8215348C9",
							"text": "Analytical; deduces meaning from numerical & verbal data sources & uses such data to generate solutions to current problems or future changes"
						},
						{
							"id": "EC2B00D7-F543-41DC-8F36-C9E2E0EDB2E6",
							"text": "Where relevant develops tools or models to further analyse data sources e.g. to test assumptions or develop scenarios"
						}
					]
				},
				{
					"id": "D9DC1CB6-D199-4024-A0F0-AB7909BA2E74",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Looks for Optimum Solutions",
					"criteria": [
						{
							"id": "EA1CAFB3-91FA-4B57-9800-094DDFF8239F",
							"text": "Ensures recommendations are evidence based. Bases decisions on robust analysis of the situation"
						},
						{
							"id": "46CD198E-9E8E-4BCD-965B-ABF2FA86FDE3",
							"text": "Conducts option appraisal: systematically compares a number of approaches, weighs up relevant data before applying judgement; then makes recommendations."
						},
						{
							"id": "5FDE3ADD-4C59-47FE-A798-AE0C606EA9F4",
							"text": "Considers financial, technical, security and other risks before taking action or making decisions"
						},
						{
							"id": "2BDB603F-215E-488B-8308-B31FC31E314C",
							"text": "Able to effectively and logically defend judgements if challenged"
						}
					]
				},
				{
					"id": "AA891218-0855-46D0-9ACD-259AE2997485",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Exercises Sound Judgement",
					"criteria": [
						{
							"id": "E34924E1-00A2-43DE-BE24-3B9B9EDDBD59",
							"text": "Makes sound judgement even when making complex or difficult decisions or when under time pressures."
						},
						{
							"id": "F50995B1-8ADA-4F2B-84F1-3EA26FD7BDAB",
							"text": "Sets processes for managing business risk"
						},
						{
							"id": "AFBE9A17-EC11-4C76-9B95-4B9A0A9553FF",
							"text": "Takes full accountability for implementing resultant decisions"
						},
						{
							"id": "C8F03ADB-8BF2-499E-9AAA-CA070CE8956F",
							"text": "Identifies potential blockers before they occur, and prevents them"
						}
					]
				}
			]
		},
		{
			"id": "B3818DDB-7CB8-41DC-89D7-6FA3430ABF90",
			"title": "💬 Communication",
			"path": "communication",
			"theme": "human",
			"summary": "All Level 1s should be able to listen, communicate and set the expectation for communication to their clients. Advancement comes in the form of the creation of consumable technical specifications. Translation of technical jargon into understandable language for others is a critical skill as a communicator. When you've made Level 4 communicator, you'll be consistently communicating. You'll build trust by autonomously communicating effectively. You'll produce business plans and use communication to gather buy-in for your solutions from teams.",
			"levels": [
				{
					"id": "0AB2BC00-A091-41D9-B2A5-E3C3AF45CA02",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Communicated Clearly",
					"criteria": [
						{
							"id": "C3D66FB4-589D-4B8F-93C8-BB4B19938A56",
							"text": "Actively listens to project briefs and asks questions where necessary to clarify understanding of own role and the project as a whole"
						},
						{
							"id": "AED8E297-E1B4-479C-8DC5-D7A28B2DAF8C",
							"text": "Where unsure checks what the expectations are for the delivery of tasks",
							"clues": [
								"Every task should have clear deliverables, even if its an exploratory/research type task. Establishing a habit of clarifying deliverable expectations will help both you and the person setting you tasks improve.",
								"Every time you're set a task, reply to your task-setter (by email is good, so its on record) saying \"OK, just to be clear, I'm going to do X, Y and Z by the end of the week\". If your tasks are delegated through project management software, the same applies - do whats appropriate to the medium your team uses.",
								"Ensure the deliverables are clear and agreed. If you encounter badly-specified deliverables, go and talk with the task-setter. Its your duty to help them understand how not-clearly-defined deliverables won't lead to the best result."
							]
						},
						{
							"id": "05388B78-E0E9-4C12-989F-D05F70221B70",
							"text": "Asks for feedback and advice where necessary to enable successful completion of delegated work"
						},
						{
							"id": "C6640E23-1290-4BF4-AB56-E2E1C947A9CF",
							"text": "Shows interpersonal sensitivity",
							"clues": [
								"You have no idea what's going on in all of your colleagues' and client's lives. Be nice before you're mean.",
								"Instead of being frustrated with someone who isn't doing work to the level you'd expect, ask yourself why? Most people want to do the right thing, as best they can.",
								"Next time you're faced with a situation like this, seek to understand why someone is behaving the way they are. What might be going on in their head? How can you help them?"
							]
						},
						{
							"id": "115423B7-1F62-49BE-9DAE-E8BA81E65A1C",
							"text": "Communicates concisely and clearly, sticking to facts and avoiding jargon, acronyms or abbreviations where appropriate"
						},
						{
							"id": "B7D5E45B-E735-463C-AA45-06459E468942",
							"text": "Treats all interactions with respect and compassion",
							"clues": [
								"People will judge you based on how you treat others. Think about your \"Personal Brand\" - if a random person in your team was asked of their opinion of you, what would they say? Is that what you want them to say? It's probably time to change your behaviour.",
								"Your personal success is tied to your team's success. Individuals don't deliver solutions, teams do.",
								"A high-performing jerk is still a jerk. Don't put your boss in the position of having to choose whether to get rid of you for the benefit of the greater team. Regardless of the outcome of such a decision you will be resented. Not a great career move."
							]
						},
						{
							"id": "ECC61433-CE1F-40AD-832E-8AD8BDCD7C85",
							"text": "Establishes the quality and frequency of feedback required by manager / client and routinely meets these requirements"
						},
						{
							"id": "19A0C7D0-543F-4EC1-AE96-190BDC0C2531",
							"text": "Acts as a role model for corporate standards of integrity both within the business and externally"
						}
					]
				},
				{
					"id": "EEEE95C8-E2C9-4BB1-B34D-41A60ACADC5D",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Excellent Technical Communication",
					"criteria": [
						{
							"id": "0B718685-98C6-42CE-BF98-7DE444468761",
							"text": "Maintains a constant dialogue with all project stakeholders to ensure a clear and joint understanding of project direction and progress"
						},
						{
							"id": "297DC6DA-41D8-47E5-B3CB-5C79D9F99F3B",
							"text": "Translates complex issues into simple terms and manageable chunks for others to understand"
						},
						{
							"id": "CE7D3C54-F559-44A9-AF38-7414269A66B3",
							"text": "Verifies that others have received, consumed and understood presented information during discussions"
						},
						{
							"id": "0F2ED1CF-76AF-4AF8-93F5-0BB64360F540",
							"text": "Produces reports which are accurate, easily understood, succinct and worded appropriately for target audience"
						},
						{
							"id": "C2DAD309-B05F-494D-99FC-3B999CD52667",
							"text": "Identifies assumptions, shares direction and tailors communication to audience."
						},
						{
							"id": "A1750AF5-2ED8-4A3C-BF3E-3F7B53D60628",
							"text": "Communicates effectively to a wide range of stakeholders, e.g. sharing updates at all hands"
						}
					]
				},
				{
					"id": "0828CE16-16D6-4182-97D2-D47260CDE75E",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Establishes Shared Understanding",
					"criteria": [
						{
							"id": "3F67713E-6327-42ED-B0A5-5165C37EF9E3",
							"text": "Provides clear and easily understood briefs to others, making clear expected outcomes and which tasks are to be completed by whom"
						},
						{
							"id": "35F62922-A655-48A7-9714-D782674DED7E",
							"text": "Displays strong presentation/writing skills"
						},
						{
							"id": "AA3627D1-5BF7-4287-A6A6-C70FE4533BFD",
							"text": "Ensures all relevant information is communicated to others to enable them to successfully execute delegated tasks"
						},
						{
							"id": "3475C152-6E5A-432C-B517-7A54D0407934",
							"text": "Presents proposals in a clear and structured manner"
						},
						{
							"id": "4AFC6F93-2767-496D-8ED1-B50D2AD092E9",
							"text": "Produces and maintains clear & concise technical specifications throughout the project"
						},
						{
							"id": "9B856469-28D8-4BD0-BA9D-EE91870C02F2",
							"text": "Seeks out and gives constructive, actionable feedback where valuable."
						},
						{
							"id": "0D65CEB4-31C0-47F8-9289-402B14675BD0",
							"text": "Can gather influence and support for their work, confidently debating and defending their decisions and approach."
						},
						{
							"id": "BFC02D0E-1A16-476C-8BBD-30DE267B118F",
							"text": "Able to simply explain complex concepts and deliver difficult messages clearly."
						}
					]
				},
				{
					"id": "8C833007-96B5-4F4E-805C-6975141A6D62",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Inspires",
					"criteria": [
						{
							"id": "80F56AEE-8633-4A13-8443-90E44CB7A780",
							"text": "Ensures the team has strong relationships with stakeholders, fully recognises their constraints and concerns and creates mutual recognition and trust."
						},
						{
							"id": "E5DBF914-6B75-426D-A144-0939538AF1EE",
							"text": "Consistently and frequently communicates / demonstrates organisational values, expectations and boundaries"
						},
						{
							"id": "60D6651A-405A-4B2B-AB60-E776AB805E9C",
							"text": "Communicates business needs to others, conveying the importance of these needs from all perspectives"
						},
						{
							"id": "9B267DC4-7F6A-47BB-BB1A-8E0F4E2C3019",
							"text": "Produces detailed and accurate delivery plans, presenting them objectively and transparently"
						},
						{
							"id": "D1559B31-B5B0-48DD-BDC8-C4FB11D1A642",
							"text": "Motivates and engages employees through communication"
						},
						{
							"id": "142A322E-6A73-4CD5-A4D9-CFAA6D8EE1F3",
							"text": "Articulates and unites a team behind a clear and compelling vision, mission and strategy, along multiple time horizons."
						}
					]
				}
			]
		},
		{
			"id": "B0BB5811-13A5-4C39-B227-E15E74358C9A",
			"title": "🥇 Striving For Success",
			"path": "striving-for-success",
			"theme": "human",
			"summary": "This skill is all about attitude and reflection. As a Level 1 you should be generally enthusiastic and have a can do attitude when faced with problems. You should be receptive to feedback and new ideas, reflecting on your own skills and areas you may need to improve. As you develop this skill, you should be seeking continuous improvement and looking to constantly exceed expectation. You should be monitoring key business metrics and be seeking ways to help improve those. You're emotionally intelligent. You should be able to provide constructive feedback and potential improvements to solutions/business processes.",
			"levels": [
				{
					"id": "6789CDD1-9C27-4811-96C9-64FFFC6CB204",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Committed",
					"criteria": [
						{
							"id": "D149AC87-AC7E-4EC8-94FD-5CAE0DC73BC4",
							"text": "Tackles work with enthusiasm, and takes a positive outlook when things are difficult"
						},
						{
							"id": "675FD2F4-9C59-44D2-9BAB-76DFAE68589F",
							"text": "Shows commitment to work objectives & underlying tasks"
						},
						{
							"id": "A58458D0-AEE3-4D50-9F56-5885BCB05B8B",
							"text": "Willing to accept advice from others regarding how objectives could be achieved"
						},
						{
							"id": "A5D53B9B-9D74-4787-875C-69DF4D74EB99",
							"text": "Sets deadlines and strives to meet them"
						},
						{
							"id": "AFCE452B-0A88-4D88-BE8A-3760081A7AE7",
							"text": "Gains a sense of achievement and self-satisfaction from successfully meeting targets and business needs"
						},
						{
							"id": "9A7815E1-1A46-47CD-9A4F-4E844B25F253",
							"text": "Prepared to examine own skills and abilities to identify ways of improving performance e.g. discusses personal development with line manager"
						}
					]
				},
				{
					"id": "37DBE4D9-EC82-48B7-A2F7-6C2F261D9A5F",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Self Motivated",
					"criteria": [
						{
							"id": "42306087-FB4F-4EB7-9979-FB54742DA4EF",
							"text": "Perseveres to overcome obstacles / resistance until objectives are fully met and tasks completed"
						},
						{
							"id": "55C193BC-B7A0-4CAD-834B-D89DEEAF6A4C",
							"text": "Proactive in taking on extra tasks or responsibility"
						},
						{
							"id": "3CAA1ED3-C047-4672-A528-14FF3D02BF26",
							"text": "Prepared to work flexibly, including working beyond standard hours in order to finish tasks to meet project deadlines"
						},
						{
							"id": "9840817C-C067-41C5-91B4-442BF0DB4A10",
							"text": "Actively seeks out work and maintains to-do lists for personal peeves"
						},
						{
							"id": "19E796F9-2D8B-45B5-A71A-5DE0143F914B",
							"text": "Self-motivated to achieve; shows initiative and performs to a high level with low levels of supervision"
						},
						{
							"id": "C6905624-8C37-4AA4-9C75-EE57D684AE15",
							"text": "Adopts good practice or new techniques from others."
						}
					]
				},
				{
					"id": "2243D86F-52DD-4578-9EB3-6F402DE9E214",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Raising The Bar",
					"criteria": [
						{
							"id": "66B7055B-35B8-4E40-B88B-AC1F2A75C94D",
							"text": "Consistently reflects on own performance & seeks continuous improvement"
						},
						{
							"id": "1014A622-4F27-41B0-B157-16E5064E130C",
							"text": "Seeks to exceed expectations"
						},
						{
							"id": "EF9810D5-58E3-4777-BE6D-994E9C065329",
							"text": "Demonstrates an attention to detail and quality, and looks to evaluate solutions as part of a desire to drive quality improvement"
						},
						{
							"id": "B0D1F352-C332-47BC-980D-265EE78B4F48",
							"text": "Aware of own credibility. Actively seeks to reinforce and enhance this"
						},
						{
							"id": "327D86EA-0DA3-4C15-BC50-5EB6F1B958DE",
							"text": "Devotes time to expand technical expertise to inform and improve client solutions & work processes"
						},
						{
							"id": "AC48ED56-B424-465A-86E3-3D4E3576CFD7",
							"text": "Seeks views of others and accepts constructive criticism"
						}
					]
				},
				{
					"id": "50E817E5-E33F-432B-BC6E-3C9D289AD74B",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Seeks Perfection",
					"criteria": [
						{
							"id": "4AF88919-8D79-4E08-819B-5E40456FCFE9",
							"text": "Consistently strives to achieve targets or goals at a team/departmental/company level"
						},
						{
							"id": "54F9CD17-1579-4FDC-8199-96BC7025A911",
							"text": "Prepared to take calculated risks to improve performance/delivery"
						},
						{
							"id": "9190DF74-DCA9-4686-BCF1-51D47711246B",
							"text": "Is never satisfied with current business performance; continually seeks to improve business performance & drive the organisation forward"
						},
						{
							"id": "D14638F8-2332-4C64-8F96-1E4711571C0B",
							"text": "Monitors business performance"
						},
						{
							"id": "40EE7F66-9913-437A-91DD-E11F4E631ADC",
							"text": "Seeks ways to enhance team performance; reinforces and rewards positive achievements to encourage further improvement"
						},
						{
							"id": "438DC916-ED8E-4991-8908-E79CB623C806",
							"text": "Pursues potential business opportunity leads, or creates openings for others"
						},
						{
							"id": "9515F6C8-465C-4BD5-8D88-24A6D8F0242E",
							"text": "Prepared to constructively challenge the practices or rationale of others in order to improve client solutions or work processes"
						}
					]
				}
			]
		},
		{
			"id": "62DBBE3B-302B-4487-AC09-FD3BE721F056",
			"title": "👨‍👨‍👧 Team Orientation",
			"path": "team-orientation",
			"theme": "human",
			"summary": "We're looking for people who are team players, who seek to foster positive relationships with the people they work with around a shared goal. Show trust by delegating tasks and defending colleagues if required. Once you've mastered this skill, you'll be a shining beacon, focused on delivery by creating a cohesive environment, where everybody shares the same values and goals. You will be a cheerleader for your team, celebrating success and instilling positivity within the team. You're a teacher, and share you experience with the team. You lead by example and identify areas the team needs to improve.",
			"levels": [
				{
					"id": "F71719AA-C65D-4041-A7A3-44A198B62D29",
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Team Player",
					"criteria": [
						{
							"id": "855D9287-8588-40BC-9DD9-3C2A84D6E43A",
							"text": "Shows genuine commitment to the team and company",
							"clues": [
								"This can't be faked, you have to want to be part of the team and the company. Take a moment to reflect on how you currently feel about your team and company.",
								"If you're not already committed, it's time to work out why. Whats stopping you from feeling more engaged? You need to be the master of your own destiny and truly take hold of what you want to do in life and where you want to go. There is value to be derived from every environment, regardless of your current opinion of your situation. Consult the advice for the other ivelop criteria and work on them to identify and take the opportunites that do exist to advance yourself.",
								"Things are not always rosy, but you only hurt yourself and your own progress by being a maverick and going against the team. Think about the way you interact with other people in a team setting, are you behaving professionally and in the best interests of the team?"
							]
						},
						{
							"id": "B18D09AC-D4ED-4911-879A-6F8F5BAAD78F",
							"text": "Prepared to compromise or go with team decision",
							"clues": [
								"The concept to grasp here has nothing to do with the quality of anyone's particular idea or decision. Of course you want the team to make the best decisions all the time, maybe they're always your ideas maybe they're not. This doesn't matter right now. Try to embrace this and get over it in order to proceed.",
								"Start paying attention or keeping notes on every meeting you're in over the next few days. Notice the dynamic, how do all the participants behave when faced with a decision? Notice those who become quieter, notice those who speak up and voice opinions. The individuals themselves is not necessarilly important, the point for you is to practice getting tuned in to conversation dynamics, and identifying the various personalities involved.",
								"After a few meetings and attentiveness, you'll start to notice the various techniques people employ to sway decisions. Some will be aggressive and shouty, some will pull rank, others will make data driven arguments, others will make experience based arguments. Some people will make logically flawed or otherwise badly described arguments and some people will be silent. Once you're practiced at observing others, reflect upon yourself. How do you (or have you traditionally) behaved? Which personality are you most similar and most different to. How do you feel about this, is it something you should change?",
								"If you're in a team then you win if the team wins. The team doesn't win if you're constantly overriding/overruling decisions to get your own way - even if your way is the best! Lets assume that most extreme case for a moment where you aren't used to compromising because your ideas are the ones that generally should be the ones to go with. From this position you're either right (they are all the best ideas) or not. If you're wrong, wouldn't it be good to find that out before you start pushing the idea? If you're right, lets bring other people in to the fold earlier. Take the time before the next decision-making meeting to talk to the least-engaged people you've observed from above. Discuss your idea with them, get them on board and work with them to present the idea together. This way, not only will you stop hogging the limelight - which other people already see and dislike, but you're also helping someone else advance themselves. You win, they win and most importantly, the team wins."
							]
						},
						{
							"id": "D43E58C9-4043-4C5E-A388-FA162665886E",
							"text": "Easily and quickly adapts to new challenges and ways of working"
						},
						{
							"id": "D58B58CA-044F-4056-A553-364506863DB8",
							"text": "Takes responsibility for own actions"
						},
						{
							"id": "B03B8A29-7190-48BA-8A90-059C52AD3109",
							"text": "Has positive relationships with team members displaying high levels of reliability and responsibility"
						},
						{
							"id": "671B1CE4-43B6-435A-8011-F23810C8B58C",
							"text": "Always ready and available to help others"
						}
					]
				},
				{
					"id": "A1D0D013-BE5D-4A1B-AACC-D34770F8633E",
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Proactive Colleague",
					"criteria": [
						{
							"id": "EE4F58BC-8364-44D3-937B-0F835997A910",
							"text": "Makes self available to others; proactively offers to assist others with activities to meet deadlines or manage short-term work pressures"
						},
						{
							"id": "04F2A558-71B5-4939-9132-46AEE2F4FEA4",
							"text": "Regularly and pro-actively communicates with colleagues regarding project or task progress."
						},
						{
							"id": "CAEEC8F2-D7D0-46EA-BFE3-2F4B17F362CD",
							"text": "Prepares for and actively participates in team meetings"
						},
						{
							"id": "916299E1-E21C-4468-945A-064F28331E60",
							"text": "Willingly seeks input and ideas from colleagues"
						}
					]
				},
				{
					"id": "7CBFE6C4-A7AD-4E31-A155-D112F1EA2759",
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Maximising The Team",
					"criteria": [
						{
							"id": "495276E0-BD60-4D15-9784-9E07D8F6CE33",
							"text": "Demonstrates awareness of different perspectives, working styles and preferences in team"
						},
						{
							"id": "01679D14-A0D3-430D-99B4-ADCD73B8B723",
							"text": "Organises social or more formal team activities"
						},
						{
							"id": "B9C5A30E-4E81-4FD7-AC96-12DF6D96BA90",
							"text": "Provides constructive feedback and suggestions, including to more senior colleagues"
						},
						{
							"id": "03EEE41B-1F27-4FC3-9371-2E19C5CC2759",
							"text": "Defends colleagues in the face of unwarranted criticism"
						},
						{
							"id": "73EA9AD0-D3AC-4137-A37F-D99E79F9B8F3",
							"text": "Encourages and assists with the personal development of others"
						},
						{
							"id": "36EAED28-13E5-4D4B-BEBC-23DA174C2B4F",
							"text": "Puts systems in place to encourage team communication and shared learning"
						},
						{
							"id": "13874DC2-925A-4893-9041-F6EDBA96F0F2",
							"text": "Demonstrates trust of team members by fully delegating tasks or projects"
						}
					]
				},
				{
					"id": "642E783C-DB54-4C2C-A8CD-43583F6E3C9A",
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Creates Team Culture",
					"criteria": [
						{
							"id": "394FDC8F-5D62-44E2-B63E-21503EB0A916",
							"text": "Seeks to understand team needs, motivations, strengths, dynamics to inform and optimise working arrangements and team performance"
						},
						{
							"id": "8B777199-084E-40F6-ADF4-8F175325DB3D",
							"text": "Employs robust methods for selecting team members for job roles"
						},
						{
							"id": "D4EEF01C-C84E-4CC7-9EF3-27E52DF68C56",
							"text": "Strives to identify ways to improve team working processes and enhance team performance"
						},
						{
							"id": "4B8A1F22-D36E-403D-B58F-1182ADC29457",
							"text": "Able to have difficult conversations where required"
						},
						{
							"id": "8A24116C-AEF3-469F-8BBC-042DE8A14E74",
							"text": "Shares team success internally and with other divisions; supportively encourages admission of collective mistakes, encouraging the team to learn from their experiences"
						},
						{
							"id": "1E2EB590-BCBF-4CFD-B2EF-C4B9E8448D9E",
							"text": "Identifies sources of conflict within the team and acts in a facilitative manner to resolve conflict or improve cohesion"
						}
					]
				}
			]
		}
	]
}
