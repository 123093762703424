<script>
import { getCurrentUserId } from '../../js/auth.js';
import { getViewUser } from '../../js/readModel.js';
import { functions } from '../../js/functions.js'
import { navigate, PageShowError } from '@trullock/page-manager'
import MeNav from '../menav.vue'

export const pageName = 'account-team';
export const pageRoute = '/account/team';
export const title = 'Your team'

export default {
	data() {
		return { 
			user: null,
			ready: false
		}
	},
	methods: {
		acceptInvitation: async function(membershipId){
			await functions.teamInvitationAccept({
				membershipId
			})
			navigate('/')
		},
		rejectInvitation: async function(membershipId){
			await functions.teamInvitationReject({
				membershipId
			})
			navigate('/')
		},	
		show: async function(opts)
		{
			this.user = await getViewUser(getCurrentUserId());
			this.ready = true;

			if(this.user.isAdminOfATeam)
				throw new PageShowError('/teams/' + this.user.membership.path, 'You\'re a team admin, redirecting to team admin page', {}, 'replace')
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}
</script>
<template>
<template v-if="ready">
<div class="container">

    <div class="row">
        <div class="col">
            <div class="header">
                <div class="header-body pt-0">
                    <MeNav />
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">
			<section v-if="user.isMemberOfATeam" class="card">
				<div class="card-header">
					<h4 class="card-header-title">{{ user.membership.name }}</h4>
					<div class="dropdown">
						<button class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i class="fe fe-more-vertical"></i>
						</button>
						<div class="dropdown-menu dropdown-menu-right">
							<a :href="`/teams/${user.membership.path}/leave`" class="dropdown-item text-danger">Leave team</a>
						</div>
					</div>
				</div>
				<div class="card-body">

					<div class="row">
						<div class="col">
							<h5>Mentors <span aria-title="These are people who are your mentor/manager. You define your mentors when you start a new self-reflection."><span class="fe fe-info text-muted"></span></span></h5>
						</div>
					</div>

					<ul v-if="user.membership.mentors.length" class="list-group list-group-flush list mb-n3">
						<li v-for="mentor in user.membership.mentors" class="list-group-item">
							<div class="row align-items-center">
								<div class="col-auto">
									<div class="avatar avatar-xs">
										<img :src="mentor.avatar" :alt="`Picture of ${mentor.name}`" class="avatar-img rounded-circle">
									</div>
								</div>
								<div class="col ml-n2">
									{{mentor.name}}
								</div>
							</div>
						</li>
					</ul>
					<p v-else class="small text-muted">You do not have a mentor.</p>
					
					<hr />
					<h5>Mentees <span aria-title="These are people who you mentor/manage. You cannot control who you mentor, they must nominate you."><span class="fe fe-info text-muted"></span></span></h5>

					<ul v-if="user.membership.mentees.length" class="list-group list-group-flush list">
						<li v-for="mentee in user.membership.mentees" class="list-group-item">
							<div class="row align-items-center">
								<div class="col-auto">
									<div class="avatar avatar-xs">
										<img :src="mentee.avatar" :alt="`Picture of {mentee.name}`" class="avatar-img rounded-circle">
									</div>
								</div>
								<div class="col ml-n2">
									{{mentee.name}}
								</div>
								<!-- <div class="col-auto">
									<a href="#" class="btn btn-sm btn-outline-primary disabled">Insights</a>
								</div> -->
							</div>
						</li>
					</ul>
					<p v-else class="small text-muted mb-0">You do not mentor anyone.</p>
					
				</div>
			</section>
            
			<section v-if="user.isAppliedToATeam" class="card">
				<div class="card-header">
					<div class="card-header-title">
						<span class="small text-muted">Application to join team</span>
						<h4 class="mb-0">{{user.teamApplication.name}}</h4>
					</div>
					<a :href="`/teams/${user.teamApplication.path}/people/cancel-application`" class="btn btn-sm btn-outline-danger">Cancel application</a>
				</div>
				<div class="card-body">
					<p>You have applied to join <strong>{{user.teamApplication.name}}</strong><br />A team admin needs to approve you.</p>
					<p class="small text-muted mb-0">Maybe go and nag them?</p>
				</div>
			</section>

			<template v-if="user.isInvitedToATeam">
				<section v-for="invitation in user.teamInvitations" class="card">
					<div class="card-header">
						<div class="card-header-title">
							<span class="small text-muted">Team invitation</span>
							<h4 class="mb-0">{{invitation.name}}</h4>
						</div>
						<div class="d-flex">
							<form @submit.prevent="acceptInvitation(invitation.membershipId)">
								<button type="submit" class="btn btn-sm btn-primary mr-2">Accept</button>
							</form>
							<form @submit.prevent="rejectInvitation(invitation.membershipId)">
								<button type="submit" class="btn btn-sm btn-outline-danger">Reject</button>
							</form>
						</div>
					</div>
					<div class="card-body">
						<p class="mb-0">You have been invited to join team <strong>{{invitation.name}}</strong><br />Accept or reject the invitation.</p>
					</div>
				</section>
			</template>

			<div v-if="!user.isInvitedToATeam && !user.isMemberOfATeam && !user.isAppliedToATeam" class="text-center">
				<h1>Team</h1>
				<p>You are not currently part of a team.</p>
				<p class="mb-5">Get the most out of <strong>ivelop</strong> by joining a team and collaborating with your peers and mentors.</p>

				<div class="row mb-4">
					<div class="col">
						<a href="/teams/create" class="btn btn-lg btn-outline-primary mb-4">Create new team</a>
						<p class="small text-muted">I'm a team leader,<br />I want to set create a team for my team/organisation.</p>
					</div>
					<div class="col">

						<a href="/onboarding/teams/search" class="btn btn-lg btn-primary mb-4">Join existing team</a>
						<p class="small text-muted">I'm a team member,<br />I want to search for and join an existing team.</p>

					</div>
				</div>
			</div>
        </div>
    </div>
</div>
</template>
</template>