import { AdminTask } from "../../userTask.js"

export default class SetupTeamUserTask extends AdminTask
{
	teamName = null
	get order() { return 1; }

	get summary() {
		return 'Finish setting up your team'
	}
}
