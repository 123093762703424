<script>
export const pageName = 'error-404';
export const pageRoute = '/error/404';
export const title = 'Page not found'

export default {
	data() { return { } }
}
</script>
<template>
	<div class="container mt-4">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6">
				<h1 class="display-4 text-center mb-3">Error</h1>
				<p class="text-muted text-center mb-5">Page not found</p>
				<a href="/" class="btn btn-primary btn-block">Go home <span class="fe fe-home"></span></a>
			</div>
		</div>
	</div>
</template>