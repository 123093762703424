import { getFirestore, doc, getDoc, collection, getDocs, query, where, orderBy } from 'firebase/firestore'
import { convertFromFirestore } from '../../functions/domain/automapper.js'
import { chunk } from '../../functions/arrayFunctions.js'
import '../../functions/domain/mappings.js'

export async function getProjection(path, type)
{
	let db = getFirestore();

	let ref = doc(db, path);
	let snap = await getDoc(ref);
	let data = snap.exists() ? convertFromFirestore(snap.data(), type) : null;
	return data;
}

export async function findProjection(collectionPath, where)
{
	let db = getFirestore();

	let ref = collection(db, collectionPath);
	let q = query(ref, where);
	let snap = await getDocs(q)
	if(snap.docs.length == 1)
		return convertFromFirestore(snap.docs[0].data());
	return null;
}

export function getViewTeamByPath(path)
{
	return findProjection(`viewTeam`, where('path', '==', path));
}

export function getReviewStats(id)
{
	return getProjection(`reviewStats/${id}`);
}

export function getViewTeam(id)
{
	return getProjection(`viewTeam/${id}`);
}

export function getTeamMemberships(id)
{
	return getProjection(`teamMemberships/${id}`);
}

export function getTeamRoles(id)
{
	return getProjection(`teamRoles/${id}`);
}

export function getViewUser(id)
{
	return getProjection(`viewUser/${id}`);
}

export function getReflectionsHistory(userId)
{
	return getProjection(`reflectionsHistory/${userId}`);
}

export function getLiteUser(id)
{
	return getProjection(`liteUser/${id}`);
}

export async function getLiteUsers(userIds)
{
	if(userIds.length == 0)
		return [];

	let db = getFirestore();
	let ref = collection(db, 'liteUser');

	let idChunks = chunk(userIds, 10);
	let chunkedSnapshots = await Promise.all(idChunks.map(ids => getDocs(query(ref, where('id', 'in', ids)))))
	let users = chunkedSnapshots.flatMap(snap => snap.docs.map(doc => convertFromFirestore(doc.data())))
	return users;
}

export async function getUserTasks(userId)
{
	let db = getFirestore();
	let ref = collection(db, 'userTask');
	let q = query(ref, where('userId', '==', userId), orderBy('dueOn', 'desc'));
	let snap = await getDocs(q);
	// TODO: Security
	return snap.docs.map(doc => convertFromFirestore(doc.data()))
}

export async function getAdminUserTasks(userId)
{
	let db = getFirestore();
	let ref = collection(db, 'userTask');
	let q = query(ref, where('userId', '==', userId), where('_isAdmin', '==', true), orderBy('dueOn', 'desc'));
	let snap = await getDocs(q);
	// TODO: Security
	return snap.docs.map(doc => convertFromFirestore(doc.data()))
}

export function getReflectionView(id)
{
	return getProjection(`reflectionView/${id}`);
}
