import { AdminTask } from "../../userTask.js"

export default class StartFirstReviewUserTask extends AdminTask
{
	reviewId = null

	get summary() {
		return 'Start your team\'s first review'
	}
}
