<script>
export const pageName = 'reflections-new';
export const pageRoute = '/reflections/new';
export const title = 'New self-reflection'

import { allRoles } from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { getTeamRoles, getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'

export default {
	data() {
		return {
			roles: [],
			ready: false
		}
	},
	methods: {
		async show(opts) {

			this.roles = allRoles();
			this.currentUser = await getViewUser(getCurrentUserId());

			if(this.currentUser.isMemberOfATeam)
			{
				let teamRoles = await getTeamRoles(this.currentUser.membership.teamId)
				this.roles = teamRoles.filterDefaultRoles(this.roles)
			}

			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav />
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mb-4">
			<div class="col-12 col-md-8 col-lg-6 text-center">
				<h1>Select your role</h1>
				<p>Choose the role that most closely suits what you're currently doing.</p>
				<p class="small">If you're curious as to what each role entails, you can <a href="/browse/roles" target="_blank">browse all roles <sup class="fe fe-external-link"></sup></a>.</p>
			</div>
		</div>
			
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-lg-6">
				<div class="card">
					<div class="card-body">
						<ul class="list-group list-group-flush list">
							<li v-for="role of roles" class="list-group-item">
								<div class="row">
									<div class="col">{{ role.icon}} {{ role.title }}</div>
									<div class="col col-auto"><a :href="`/reflections/new/${role.path}`" class="btn btn-sm btn-outline-primary">Choose <span class="fe fe-arrow-right-circle"></span></a></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		</div>
</template>