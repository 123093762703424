import reflectionState from "./reflectionState.js"

export default class ReflectionSummary
{
	id = null
	createdOn = null
	name = null
	roleId = null
	levelId = null
	state = reflectionState.Unsubmitted
	dueOn = null
}