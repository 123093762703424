<script>
export const pageName = 'teams-overivew';
export const pageRoute = '/teams/{path}';
export const title = 'Team overview'

import { getCurrentUserId } from '../../js/auth.js';
import { getAdminUserTasks, getLiteUsers, getTeamMemberships, getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import { TeamPermissions } from '../../../functions/domain/teams/teamPermissions.js';
import { TeamState } from '../../../functions/domain/teams/teamState.js';
import TopNav from './topnav.vue'
import CurrentReview from './reviews/common/currentReview.vue'
import Applications from './applications.vue'
import TeamUserTask from './userTasks/task.vue'
import { functions } from '../../js/functions.js';
import { showToast } from '../../js/toast.js';
import { refresh } from '@trullock/page-manager'
import { showConfirm } from '../../js/confirm.js';

export default {
	data() {
		return { 
			currentUser: null,
			tasks: [],
			team: null,
			teamState: { completeness: 0 },
			teamMemberships: [],
			invitations: [],
			applications: [],
			reviewStats: null,// TODO: populate me
			TeamState,
			ready: false
		}
	},

	methods: {
		getCompleteness: function (state) {
			let count = 0;
			((state & TeamState.BasicDetails) == TeamState.BasicDetails) && count++
			((state & TeamState.Roles) == TeamState.Roles) && count++
			((state & TeamState.People) == TeamState.People) && count++
			((state & TeamState.Billing) == TeamState.Billing) && count++
			((state & TeamState.Settings) == TeamState.Settings) && count++

			return (count * 100 / 5.0).toFixed(0);
		},
		async resendInvitation(membershipId){
			await functions.teamInvitationResend({
				membershipId
			});
			showToast({ message: 'Invitation email resent '});
		},
		async revokeInvitation(membershipId){
			showConfirm({
				title: 'Confirm invitation revokation',
				message: `Are you sure you want to revoke this invitation?`,
				positiveText: 'Revoke',
				positiveStyle: 'danger',
				positiveAction: async () => {
					await functions.teamInvitationRevoke({
						membershipId
					});
					refresh();
				}
			})
			
		},
		async show(opts) {
			let user = await getViewUser(getCurrentUserId());
			let team = await getViewTeamByPath(this.options.path);
			
			// TODO: read security

			let memberships = await getTeamMemberships(team.id);

			let inviteeUserIds = memberships.invitations.filter(i => i.userId).map(i => i.userId);
			let applicationUserIds = memberships.applications.map(a => a.userId);
			let userIds = inviteeUserIds.concat(applicationUserIds);
			let users = await getLiteUsers(userIds);

			let adminTasks = await getAdminUserTasks(user.id);

			
			this.currentUser = user;
			this.tasks = adminTasks;
			this.team = team;
			this.teamState.completeness = this.getCompleteness(team.state);
			this.teamMemberships = memberships,
			this.invitations = memberships.invitations.map(i => ({
				membershipId: i.membershipId,
				isAdmin: i.permissions == TeamPermissions.admin,
				sentOn: i.sentOn,
				nonUserEmail: i.nonUserEmail,
				user: users.find(u => u.id == i.userId)
			})),
			this.applications = memberships.applications.map(a => ({
				membershipId: a.membershipId,
				requestedOn: a.requestedOn,
				user: users.find(u => u.id == a.userId)
			}));
			this.reviewStats = null;
			this.ready = true;
		}
	},
	components: { TopNav, CurrentReview, Applications, TeamUserTask },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser" current-page="overview"></TopNav>

		<main class="container">

			<div class="row">
				<div class="col12 col-xl-4 order-xl-last">
						
					<div class="card" v-if="team.state != TeamState.Complete && currentUser.isAdminOfTeam(team.id)">
						<div class="card-header">
							<h4 class="card-header-title">Team set-up</h4>
						</div>
						<div class="card-body">
							<div class="row align-items-center mb-4">
								<div class="col">
									<div class="progress progress-sm">
										<div :class="`progress-bar progress-${teamState.completeness}`" role="progressbar" :aria-valuenow="teamState.completeness" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
								</div>
								<div class="col-auto small">
									{{teamState.completeness}}% complete
								</div>
							</div>
							<div class="list-group list-group-flush list-group-activity my-n3">
								<div class="list-group-item">
									<div :class="`row align-items-center ${((team.state & TeamState.BasicDetails) == TeamState.BasicDetails) ? 'text-muted' : ''}`">
										<div class="col-auto">
											<div class="avatar avatar-sm">
												<div :class="`avatar-title font-size-lg rounded-circle ${((team.state & TeamState.BasicDetails) == TeamState.BasicDetails) ? 'text-white bg-success' : 'text-primary bg-primary-soft'}`">
													<i class="fe fe-plus-circle"></i>
												</div>
											</div>
										</div>
										<div class="col ml-n2">
											<h5 class="mb-1">Basic details</h5>
											<p class="small mb-0">Create your team</p>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div :class="`row align-items-center ${((team.state & TeamState.Settings) == TeamState.Settings) ? 'text-muted' : null}`">
										<div class="col-auto">
											<div class="avatar avatar-sm">
												<div :class="`avatar-title font-size-lg rounded-circle ${ ((team.state & TeamState.Settings) == TeamState.Settings) ? 'text-white bg-success' : 'text-primary bg-primary-soft'}`">
													<i class="fe fe-settings"></i>
												</div>
											</div>
										</div>
										<div class="col ml-n2">
											<h5 class="mb-1">Settings</h5>
											<p class="small mb-0">Customise your team</p>
										</div>
										<div class="col-auto">
											<a :href="`/teams/${team.path}/settings`" :class="`btn btn-sm ${((team.state & TeamState.Settings) == TeamState.Settings) ? 'btn-white' : 'btn-primary'}`">Customise</a>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div :class="`row align-items-center ${((team.state & TeamState.Roles) == TeamState.Roles) ? 'text-muted' : null}`">
										<div class="col-auto">
											<div class="avatar avatar-sm">
												<div :class="`avatar-title font-size-lg rounded-circle ${((team.state & TeamState.Roles) == TeamState.Roles) ? 'text-white bg-success' : 'text-primary bg-primary-soft'}`">
													<i class="fe fe-check-circle"></i>
												</div>
											</div>
										</div>
										<div class="col ml-n2">
											<h5 class="mb-1">Configure roles</h5>
										</div>
										<div class="col-auto">
											<a :href="`/teams/${team.path}/roles`" :class="`btn btn-sm ${((team.state & TeamState.Roles) == TeamState.Roles) ? 'btn-white' : 'btn-primary'}`">Configure</a>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div :class="`row align-items-center ${((team.state & TeamState.Billing) == TeamState.Billing) ? 'text-muted' : null}`">
										<div class="col-auto">
											<div class="avatar avatar-sm">
												<div :class="`avatar-title font-size-lg rounded-circle ${((team.state & TeamState.Billing) == TeamState.Billing) ? 'text-white bg-success' : 'text-primary bg-primary-soft'}`">
													<i class="fe fe-dollar-sign"></i>
												</div>
											</div>
										</div>
										<div class="col ml-n2">
											<h5 class="mb-1">Set-up billing</h5>
										</div>
										<div class="col-auto">
											<a :href="`/teams/${team.path}/billing`" :class="`btn btn-sm ${((team.state & TeamState.Billing) == TeamState.Billing) ? 'btn-white' : 'btn-primary'}`">Configure</a>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div :class="`row align-items-center ${((team.state & TeamState.People) == TeamState.People) ? 'text-muted' : null}`">
										<div class="col-auto">
											<div class="avatar avatar-sm">
												<div :class="`avatar-title font-size-lg rounded-circle ${((team.state & TeamState.People) == TeamState.People) ? 'text-white bg-success' : 'text-primary bg-primary-soft'}`">
													<i class="fe fe-users"></i>
												</div>
											</div>
										</div>
										<div class="col ml-n2">
											<h5 class="mb-1">Invite people</h5>
										</div>
										<div class="col-auto">
											<a :href="`/teams/${team.path}/people/invite`" :class="`btn btn-sm ${((team.state & TeamState.People) == TeamState.People) ? 'btn-white' : 'btn-primary'}`">Invite</a>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<div class="list-group list-group-flush my-n3">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Members
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{teamMemberships.members.length}}
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Pending invitations
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{teamMemberships.invitations.length}}
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Pending applications
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{teamMemberships.applications.length}}
											</small>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="col-12 col-xl-8">
					<template v-for="task of tasks">
						<TeamUserTask :task="task" :currentUser="currentUser"></TeamUserTask>
					</template>
					<section class="card" v-if="applications.length">
						<div class="card-header">
							<h4 class="card-header-title">{{ applications.length }} pending application{{ (applications.length > 1 ? "s" : null)  }} to join</h4>
						</div>
						<Applications v-if="applications.length" :currentUser="currentUser" :team="team" :applications="applications"></Applications>
					</section>
					<section class="card" v-if="invitations.length">
						<div class="card-header">
							<h4 class="card-header-title">Invitations to join</h4>
						</div>

						<table class="table table-sm table-nowrap table-hover card-table">
							<thead>
								<tr>
									<th>Person</th>
									<th class="shrink">Permissions</th>
									<th class="d-none d-md-table-cell">Sent</th>
									<th class="shrink"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="invitation of invitations">
									<td>
										<div class="row align-items-center" v-if="invitation.user != null">
											<div class="col-auto">
												<div class="avatar avatar-sm dropdown-toggle">
													<img :src="invitation.user.avatar" :alt="invitation.user.name" class="avatar-img rounded-circle">
												</div>
											</div>
											<div class="col">
												<div class="mb-0">{{ invitation.user.name }}</div>
												<div class="text-muted small">{{ invitation.user.email }}</div>
											</div>
										</div>
										<template v-else>
											{{ invitation.nonUserEmail }}
										</template>
									</td>
									<td class="shrink text-center">
										{{(invitation.isAdmin ? "Admin" : "Non-admin")}}
									</td>
									<td class="d-none d-md-table-cell">{{ invitation.sentOn }}</td>
									<td>
										<form @submit.prevent="resendInvitation(invitation.membershipId)" class="mb-1 d-block d-sm-inline">
											<button type="submit" class="btn btn-sm btn-block btn-sm-inline-block btn-outline-primary">Re-send invitation</button>
										</form>
										<form @submit.prevent="revokeInvitation(invitation.membershipId)" class="mb-1 ml-2 d-block d-sm-inline">
											<button type="submit" class="btn btn-sm btn-block btn-sm-inline-block btn-outline-danger">Revoke</button>
										</form>
									</td>
									</tr>
							</tbody>
						</table>
					</section>
					<CurrentReview team-path="team.path" team-state="team.state" review="team.CurrentReview" review-stats="reviewStats"></CurrentReview>
				</div>
			</div>

		</main>
</div>
</template>