export default class TeamBenchmarks {
	id = null
	reviews = {}
	stats = {}

	setUserStats(reviewId, userId, submittedOn, userRole)
	{
		if(!this.stats[userId])
			this.stats[userId] = [];

		let stats = new TeamBenchmarks.UserRoleLevelTopicStats();
		stats.reviewId = reviewId;
		stats.submittedOn = submittedOn,
		stats.roleId = userRole.id;
		stats.levelId = userRole.level.id;
		stats.competencyTopicStats = userRole.getCompetencyTopicStats()

		this.stats[userId].push(stats);
	}

	removeUserStats(userId)
	{
		delete this.stats[userId]
	}

	static Review = class {
		createdOn = null
		name = null
	}

	static UserRoleLevelTopicStats = class {
		reviewId = null
		submittedOn = null
		roleId = null
		levelId = null
		competencyTopicStats = null
	}
}