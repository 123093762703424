<script>
export const pageName = 'reflections-answer-start';
export const pageRoute = '/reflections/{reflectionId}/answer/start';
export const title = 'Start new self-reflection'

import { getRoleById } from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { getReflectionView, getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'
import UserRole from '../../../../functions/domain/userRole.js';

export default {
	data() {
		return {
			currentUser: null,
			reflectionId: null,
			requiredCriteria: 0,
			firstTopicLevelCriteria: 0,
			topics: 0,
			role: null,
			level: null,
			ready: false
		}
	},
	methods: {
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			this.reflectionView = await getReflectionView(opts.reflectionId)

			this.role = getRoleById(this.reflectionView.roleId);
			this.level = this.role.getLevelById(this.reflectionView.levelId);

			let userRole = new UserRole(this.role, this.level)
			
			this.firstCompetency = userRole.level.competencies[0];
			this.firstTopic = this.firstCompetency.topics[0];
			this.firstLevel = this.firstTopic.levels[0];

			this.requiredCriteria = userRole.requiredCriteria;
			this.firstTopicLevelCriteria = this.firstLevel.criteria.length;
			this.topics = userRole.level.competencies.map(c => c.topics.length).reduce((p,c)=>p+c, 0)

			this.ready = true;

		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav />
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mb-4">
			<div class="col-12 col-lg-8">
				<div class="text-center">
					<h1>Start self-reflecting</h1>
					<p>For your role as {{level.title}}, you need to answer {{requiredCriteria}} questions covering {{topics}} competencies and topics.</p>
					<p>Don't worry! We'll only ask you to do a few each day, spread out over the next couple of weeks - although you can do them all at once if you like.</p>
					<p class="">Each topic is divided into levels, you will be aiming towards the following levels in each topic.</p>
					<p class="small mb-5">If you're curious as to what each topic entails, you can <a :href="`/browse/roles/${role.path}/${level.path}`" target="_blank">browse all topics <sup class="fe fe-external-link"></sup></a>.</p>
				</div>
				<section class="card mb-5">
					<div class="card-header">
						<h4 class="card-header-title">{{level.title}}</h4>
					</div>
					<div class="mb-0">
						<table class="table table-sm table-hover card-table">
							<thead>
							<tr>
								<th>Competency</th>
								<th>Topic</th>
								<th>Level</th>
								<th>Questions</th>
							</tr>
							</thead>
							<tbody>
								<template v-for="competency of level.competencies.competencies">
									<tr v-for="topic of competency.topics">
										<td>{{competency.title}}</td>
										<td>{{ topic.title }}</td>
										<td>{{ topic.expectedLevel.title }}</td>
										<td class="text-center">{{topic.requiredCriteria.length}}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</section>

				<div class="row mb-4">
					<div class="col">
						<a href="/" class="btn btn-lg btn-block btn-white">Skip until later</a>
					</div>
					<div class="col">
						<a :href="`/reflections/${reflectionView.id}/answer/${firstCompetency.path}/${firstTopic.path}/${firstLevel.path}`" class="btn btn-lg btn-block btn-primary">Answer first {{ firstTopicLevelCriteria }} questions <span class="fe fe-arrow-right-circle"></span></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>