<script>
import { ReviewPhase } from "../../../../../functions/domain/reviews/reviewPhase.js";
import ReflectingTemplate from './reflecting.vue'
import IteratingTemplate from './iterating.vue'
import NoReviewTemplate from './noreview.vue'

export default {
	data() {
		return {
			ReviewPhase,
			teamPath: this.teamPath,
			teamState: this.teamState,
			review: this.review,
			reviewStats: this.reviewStats
		};
	},
	components: { ReflectingTemplate, IteratingTemplate, NoReviewTemplate},
	props: ['teamPath', 'teamState', 'review', 'reviewStats']
}

</script>
<template>
	<ReflectingTemplate v-if="review?.phase == ReviewPhase.reflecting" :review="review" :reviewStats="reviewStats" :teamPath="teamPath" :teamState="teamState"></ReflectingTemplate>
	<IteratingTemplate v-if="review?.phase == ReviewPhase.iterating" :review="review" :reviewStats="reviewStats" :teamPath="teamPath" :teamState="teamState"></IteratingTemplate>
	<NoReviewTemplate v-if="!review" :teamPath="teamPath" :teamState="teamState"></NoReviewTemplate>
</template>