import { AdminTask } from "../../userTask.js"

export default class ActionTeamApplicationsUserTask extends AdminTask
{
	teamPath = null

	constructor(teamPath)
	{
		super();
		this.teamPath = teamPath;
	}

	get order() { return 1; }

	get summary() {
		return 'Approve or reject waiting team members'
	}
}
