<script>
import DateTime from '../../../functions/domain/dateTime.js';


export default {
	data() {
		return { 
			DateTime,
			task: this.task
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<div class="card">
    <div class="card-header">
        <h4 class="card-header-title">Provide feedback to {{task.menteeName}}</h4>
        <small :class="task.dueOn <= DateTime.now() ? 'text-danger' : 'text-muted'" :aria-title="`This task should be completed before ${task.dueOn.format('P')}`"><span class="fe fe-clock"></span> {{task.dueOn.format('MMMM do')}}</small>
        <a :href="`/reflections/${task.reflectionId}/provide-feedback`" class="btn btn-sm btn-primary">Provide feedback <span class="fe fe-arrow-right-circle"></span></a>
    </div>
    <div class="card-body">
        <p class="mb-0">{{task.menteeName}}'s self reflection requires your feedback.</p>
    </div>
</div>

</template>