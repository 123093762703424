import AggregateRoot from "../aggregateRoot.js";
import DateTime from "../dateTime.js";
import ReflectionState from "./reflectionState.js";
import { v4 as newId } from 'uuid'

export default class Reflection extends AggregateRoot
{
	userId = null
	teamId = null
	reviewId = null
	name = null
	roleId = null
	levelId = null
	dueOn = null
	answers = {}
	feedback = {}

	static DefaultIterationDurationDays = 14

	static createForTeamMember(userId, teamId, reviewId, name, roleId, levelId, dueOn)
	{
		let reflection = new Reflection();
		reflection.apply('reflectionCreatedForTeamMember', {
			userId,
			teamId,
			reviewId,
			name,
			roleId,
			levelId,
			dueOn
		});
		return reflection;
	}

	onReflectionCreatedForTeamMember(event)
	{
		this.id = event.aggregateRootId;
		this.userId = event.userId;
		this.teamId = event.teamId;
		this.reviewId = event.reviewId;
		this.name = event.name;
		this.roleId = event.roleId;
		this.levelId = event.levelId;
		this.dueOn = event.dueOn;
	}

	#mergeAnswers(answers)
	{
		let newAnswers = Object.keys(this.answers).reduce((p, c) => {
			p[c] = this.answers[c]
			return p
		}, {});
		Object.keys(answers).forEach(k => newAnswers[k] = answers[k])
		return newAnswers;
	}

	updateAnswers(userRole, answers)
	{
		if(userRole.id != this.roleId || userRole.level.id != this.levelId)
			throw new Error('UserRole provided is not for the Role/Level of this reflection')

		let newAnswers = this.#mergeAnswers(answers);
		
		if(!Object.keys(newAnswers).length)
			return

		userRole.applyAnswers(newAnswers);
		let complete = userRole.getStats().completeness == 100;
		
		this.apply('reflectionAnswersUpdated', {
			reviewId: this.reviewId,
			userId: this.userId,
			roleId: this.roleId,
			levelId: this.levelId,
			reflectionDueOn: this.dueOn,
			answers: newAnswers,
			isCompletelyAnswered: complete
		})

		return complete;
	}

	onReflectionAnswersUpdated(event)
	{
		Object.keys(event.answers).forEach(key => this.answers[key] = event.answers[key])
	}

	submitAnswers()
	{
		if(this.state == ReflectionState.Submitted)
			throw new Error(`Reflection ${this.id} is already submitted`)

		this.apply('reflectionAnswersSubmitted', {
			userId: this.userId,
			reviewId: this.reviewId,
			roleId: this.roleId,
			levelId: this.levelId,
			answers: this.answers
		})
	}

	onReflectionAnswersSubmitted(event) {
		this.state = ReflectionState.Submitted
	}

	provideFeedback(mentorId, feedbackAnswers)
	{
		if(this.feedback[mentorId])
			throw new Error(`Mentor ${mentorId} has already provided feedback for this reflection ${this.id}`);

		// The answers dict provided to this method should have exactly the same set of keys as this.Answers, however if roles have changed its plausible they may not.
		// Ideally this should be prevented. Wasn't at the time of writing this.

		let changedAnswers = []

		for(let key in this.answers)
		{
			let answer = this.answers[key];

			if(answer != feedbackAnswers[key])
				changedAnswers.push(key)
		}

		this.apply('reflectionFeedbackProvided', {
			reviewId: this.reviewId,
			mentorId: mentorId,
			menteeId: this.userId,
			answers: feedbackAnswers,
			changedAnswers: changedAnswers
		})
	}

	onReflectionFeedbackProvided(event) {
		this.feedback[event.mentorId] = event.answers
	}

	reviewFeedback(mentorId) {
		this.apply('reflectionFeedbackReviewed', {
			mentorId,
			menteeId: this.userId
		});
	}

	onReflectionFeedbackReviewed(event) {
		// noop
	}

	startIteration(competencyId, topicId, criteria, iterationDurationDays)
	{
		this.apply('reflectionIterationStarted', {
			iterationId: newId(),
			userId: this.userId,
			competencyId,
			topicId,
			criteria,
			iterationDurationDays,
			endsOn: DateTime.now().addDays(iterationDurationDays)
		})
	}

	onReflectionIterationStarted(event) {
		this.state = ReflectionState.Iterating;
		this.currentIterationId = event.iterationId;
	}
}