<script>
import { completenessColor } from '../../js/utils.js';
import { ratingStars, feedbackAdjustment } from '../../js/utils.js';

export default {
	data() {
		return { 
			currentReflection: this.currentReflection,
			currentUser: this.currentUser,
			completenessColor
		}
	},
	methods: {
		ratingStars,
		feedbackAdjustment
	},
	props: [ 'currentReflection', 'currentUser' ]
}

</script>
<template>
<section class="card">
	<div class="card-header">
		<h4 class="card-header-title row align-items-center">
			<div class="col">
				<span class="small text-muted">{{currentReflection.summary.name}}</span>
				<h4 class="mb-0">{{currentReflection.level.title}}</h4>
			</div>
			<div>
				<a  :href="`/reflections/${currentReflection.summary.id}/view`" class="btn btn-sm btn-outline-primary">View reflection answers</a>
			</div>
		</h4>
	</div>
	<div class="card-body">
		<div class="row">
			<div class="col">
				<div class="display-2">{{ currentReflection.summary.stats.rating }}</div>
				<div class="small" v-html="ratingStars(currentReflection.summary.stats.rating)"></div>
				<div class="small text-muted">Rating</div>
			</div>
			<div class="col text-right">
				<template v-if="currentUser.isMemberOfATeam">
					<a v-for="feedback of currentReflection.summary.feedback" :href="`/reflections/${currentReflection.summary.id}/feedback/${feedback.mentorId}`" class="btn btn-sm btn-outline-primary">View {{feedback.mentorName}}'s feedback</a>
				</template>
			</div>
		</div>
	</div>
	<div class="mb-0">
		<table v-for="competency of currentReflection.progress" class="table table-sm table-nowrap table-hover card-table">
			<thead>
				<tr>
					<th>{{ competency.displayName }}</th>
					<th class="shrink">Rating <span aria-title="Ratings are based off your own answers and any feedback you've received from mentors"><span class="fe fe-info"></span></span></th>
					<th v-if="currentReflection.summary.feedback.length" class="shrink">Feedback <span aria-title="Indicates how your individual rating was adjusted as a result of all mentor feedback"><span class="fe fe-info"></span></span></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="topic in competency.topics">
					<td>{{topic.displayName}}</td>
					<td v-html="ratingStars(topic.stats.rating)"></td>
					<td v-if="currentReflection.summary.feedback.length" class="text-center" v-html="feedbackAdjustment(topic.stats.feedback)"></td>
				</tr>
			</tbody>
		</table>
	</div>
</section>

</template>