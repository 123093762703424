import UserRoleTopicLevelCriterion from './userRoleTopicLevelCriterion.js'
export default class UserRoleTopicLevel
{
	id = null
	title = null
	path = null
	summary = null
	requiredByRole = false
	criteria = []

	get answerCompleteness()
	{
		return 100.0 * this.criteria.filter(c => c.answer !== null).length / this.criteria.length
	}

	constructor(level)
	{
		this.id = level.id;
		this.title = level.title;
		this.path = level.path;
		this.summary = level.summary;
		this.requiredByRole = level.requiredByRole
		this.criteria = level.criteria.map(c => new UserRoleTopicLevelCriterion(c))
	}
}
