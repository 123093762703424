<script>
export const pageName = 'browse-competency-topic-levels';
export const pageRoute = '/browse/competencies/{competency}/{topic}';
export const title = 'Browse competency topics'

import { getCompetencyByPath } from '../../../functions/domain/roles/roleStore.js';

export default {
	data() {
		return { 
			competency: null,
			topic: null,
			ready: false
		}
	},
	async created() {
		this.competency = await getCompetencyByPath(this.options.competency);
		this.topic = this.competency.getTopicByPath(this.options.topic);
		this.ready = true;
	},
	props: [ 'options' ]
}

</script>

<template>
<template v-if="ready">
	<div class="header">
		<div class="container">
			<div class="header-body">
				<nav class="h5" aria-label="breadcrumb">
					<ol class="breadcrumb pt-0 pb-1">
						<li class="breadcrumb-item"><a href="/">Home</a></li>
						<li class="breadcrumb-item active" aria-current="page">Competencies</li>
					</ol>
				</nav>
				<h1 class="header-title">All Competencies</h1>
			</div>
		</div>
	</div>
	<main class="container">
		<div class="row">
			<div class="col-12">
				<div class="alert alert-light" role="alert">
					<p class="small mb-0"><span class="fe fe-info"></span> Competencies and their Topics are the collections of professional qualities and criteria that make up Roles.</p>
					<p class="small mb-0">Your role is a collection of some of the Competency-Topics listed below. <a href="/browse/roles">Browse the Roles pages</a> to find your role.</p>
				</div>
			</div>
			
			<div class="header">
				<div class="container">
					<div class="header-body">
						<nav class="h5" aria-label="breadcrumb">
							<ol class="breadcrumb pt-0 pb-1">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item"><a href="/browse/competencies">Competencies</a></li>
								<li class="breadcrumb-item active" aria-current="page">{{ competency.title }} - {{ topic.title }}</li>
							</ol>
						</nav>
						<h1 class="header-title">{{ competency.title }} - {{ topic.title }}</h1>
					</div>
				</div>
			</div>
			<main class="container">

				<div class="row">
					<div class="col-12">
						<div class="card">
							<div class="card-body">
								<h4>Competency: {{ competency.title }}</h4>
								<p>{{ competency.summary }}</p>
								<h4>Topic: {{ topic.title }}</h4>
								<p class="mb-0">{{ topic.summary }}</p>
							</div>
						</div>
					</div>
						<div class="col-12 col-xl-6" v-for="level of topic.levels">
							<section class="card">
								<div class="card-header">
									<h4 class="card-header-title">{{ level.title }}</h4>
									<h6 class="small mb-0">{{ level.summary }}</h6>
								</div>
								<div class="mb-0">
									<table class="table table-sm table-hover card-table">
										<thead>
										<tr>
											<th>Criteria</th>
											<!-- <th class="shrink text-center">
												<span aria-title="The number of clues available to help you implement each criterion."><span class="fe fe-info text-muted"></span></span>
											</th> -->
										</tr>
										</thead>
										<tbody>
											<tr v-for="criterion of level.criteria">
												<td>{{ criterion.text }}</td>
												<!-- <td><span class="badge badge-soft-primary">{{ criterion.clues.length }} clues</span></td> -->
											</tr>
										</tbody>
									</table>
								</div>
							</section>
						</div>
				</div>

			</main>
		</div>
	</main>
</template>
</template>