<script>
export const pageName = 'browse-roles-levels';
export const pageRoute = '/browse/roles/{role}/{level}';
export const title = 'Browse role'

import { getRoleByPath } from '../../../functions/domain/roles/roleStore.js';

export default {
	data(){
		let role = getRoleByPath(this.options.role);
		let level = role.levels.find(l => l.path == this.options.level);
		
		// TODO: handle 404

		return {
			role,
			level
		};
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="header">
	<div class="container">
		<div class="header-body">
			<nav class="h5" aria-label="breadcrumb">
				<ol class="breadcrumb pt-0 pb-1">
					<li class="breadcrumb-item"><a href="/">Home</a></li>
					<li class="breadcrumb-item"><a href="/browse/roles">Roles</a></li>
					<li class="breadcrumb-item"><a href="/browse/roles/@ViewData.Role.Path">{{ role.title }}</a></li>
					<li class="breadcrumb-item active" aria-current="page">{{ level.title }}</li>
				</ol>
			</nav>
			<h1 class="header-title">{{ level.title }} requirements</h1>
		</div>
	</div>
</div>
<main class="container">
	<div class="row">
		<template v-for="competency of level.competencies.competencies">
			<div class="col-12 col-lg-6" v-for="topic of competency.topics">
				<section class="card">
					<div class="card-header">
						<h4 class="card-header-title">{{ topic.title }}</h4>
						<a :href="`/browse/competencies/${competency.path}/${topic.path}`" class="btn btn-sm btn-outline-primary">Browse all levels</a>
					</div>
					<div class="card-body">
						<h5>Required level</h5>
						<p class="small mb-0">{{ topic.expectedLevel.title }}: {{ topic.expectedLevel.summary }}</p>
					</div>
					<div class="mb-0">
						<table class="table table-sm table-hover card-table" v-for="level of topic.requiredLevels">
							<thead>
								<tr>
									<th>{{ level.title }} criteria</th>
									<!-- <th class="shrink text-center">
										<span aria-title="The number of clues available to help you implement each criterion. These will be shown to you in detail throughout the development iteration process."><span class="fe fe-info text-muted"></span></span>
									</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="criterion of level.criteria">
									<td>{{ criterion.text }}</td>
									<!-- <td><span class="badge badge-soft-primary">{{ criterion.clues.length }} clues</span></td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</template>
	</div>
</main>
</template>
