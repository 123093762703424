import { subscribe } from '@trullock/pubsub'
import * as Auth from './auth.js';
import * as pageManager from '@trullock/page-manager';
import { updateFormGroupIds } from './utils.js'
import { clearToast } from './toast.js';
import { initFirebase } from "./firebase.js";
import { validation } from './language.js';
import trullockForms from '@trullock/forms'

import '../pages/pages.js';
import { showConfirm } from './confirm.js';
import './charts.js'

export default function() {
	initFirebase();
	trullockForms(validation.messages);
	
	var authReady = Auth.init();

	document.addEventListener('submitted', e => e.target.matches('form') && e.target.setSubmitting(true, e.detail))
	document.addEventListener('submission-complete', e => {
		if (!e.target.matches('form'))
			return;

		e.target.setSubmitting(false, e.detail.button);
	});

	document.addEventListener('click', e => {
		if(e.target.matches('a.disabled'))
		{
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();
		}
	});


	document.addEventListener('click', e => {
		var $btn = e.target.matches('.js-reveal') ? e.target : e.target.closest('.js-reveal');
		if(!$btn)
			return;

		e.preventDefault();

		var $input = $btn.closest('.input-group').querySelector('input');
		var $eye = $btn.$('.fe');

		if($input.type == 'password'){
			$input.type = 'text';
			$eye.classList.remove('fe-eye');
			$eye.classList.add('fe-eye-off');
		} else {
			$input.type = 'password';
			$eye.classList.add('fe-eye');
			$eye.classList.remove('fe-eye-off');
		}

		$input.focus();
	});

	document.addEventListener('click', e => {
		var $btn = e.target.matches('button') ? e.target : e.target.closest('button');
		if(!$btn)
			return;

		let $form = $btn.closest('form');
		$form && ($form.clickedButton = $btn);
	});

	let currentUser = null;
	subscribe('user auth-state-changed', user => currentUser = user);
	window.addEventListener('DOMContentLoaded', function(){

		authReady.then(() => pageManager.init({
			fetchPageTemplate: route => {
				if(route.pageClass.fetchHTML === false)
				{
					var $div = document.createElement('div');
					$div.className = 'd-none pg';
					return Promise.resolve($div);
				}
		
				return fetch(`/pages/app/${route.routeName}.html`)
					.then(r => r.text())
					.then(html => {
						var $div = document.createElement('div');
						$div.innerHTML = html;
						// Pages are assumed to have a single wrapping element
						return $div.firstElementChild;
					});
			},
			prepareMarkup: $html => {
				$html.$('.form-group')?.forEach(updateFormGroupIds)
			},
			pageInterrupt: route => {
				if (route.pageClass.requireAuth && !currentUser)
					return { url: pageManager.getPath('account-sign-in') };
				return null;
			},
			loadingPageName: 'loading',
			defaultPageName: 'root',
			error404PageName: 'error-404',
			beforeUnload: message => new Promise(resolve => {
				showConfirm({
					title: 'Confirm',
					message: message,
					positiveText: 'I\'m sure',
					negativeText: 'Cancel',
					positiveAction: () => { resolve(true); return Promise.resolve() },
					negativeAction: () => { resolve(false); return Promise.resolve() }
				});
			})
		}));
 
		// listen for navigations
		document.addEventListener('click', e => {
			if(e.ctrlKey || e.metaKey)
				return;

			var $a = e.target.matches('a') ? e.target : e.target.closest('a');
			if(!$a)
				return;

			if($a.target || $a.protocol == "mailto:")
				return;

			var href = $a.pathname + $a.search + $a.hash;
			if(href == '')
				return;
				
			pageManager.navigate(href, {}, true)

			e.preventDefault();
		}, false);

		// user state classes
		subscribe('user auth-state-changed', user => {
			var $anons = document.$('.user-anon');
			var $autheds = document.$('.user-authed');

			if(user){
				$anons?.classList.add('d-none');
				$autheds?.classList.remove('d-none');
			} else {
				$anons?.classList.remove('d-none');
				$autheds?.classList.add('d-none');
			}
		})

		
		// user state classes
		subscribe('user changed', liteUser => {
			var $avatar = document.$('.js-user-avatar');
			$avatar.src = liteUser?.avatar || '';
		})


		let $nav = document.body.$('nav.js-nav');
		document.addEventListener('page-shown', e => {

			if (e.detail.opts.route.path != '/app/loading') {
				// https://developers.google.com/gtagjs/reference/event#page_view
				// firebase.analytics().logEvent('page_view', {
				// 	page_location: window.location.protocol + '//' + window.location.host + e.detail.opts.route.path,
				// 	page_path: e.detail.opts.route.path,
				// 	page_title: e.detail.page.title
				// });
			}

			if(e.detail.page.constructor.furniture == 'plain')
			{
				document.body.classList.add('footer-offset', 'd-flex', 'align-items-center', 'bg-auth', 'border-top', 'border-top-2', 'border-primary')
				$nav.classList.add('d-none')
			}
			else
			{
				document.body.classList.remove('footer-offset', 'd-flex', 'align-items-center', 'bg-auth', 'border-top', 'border-top-2', 'border-primary')
				$nav.classList.remove('d-none')
			}
		});

		// document.addEventListener('page-hidden', e => {
		// 	clearToast();
		// });

		// dropdown menus
		document.addEventListener('click', e => {
			var $button = e.target.matches('button[data-toggle=dropdown]') ? e.target : e.target.closest('button[data-toggle=dropdown]');
			
			var $menu = $button && $button.parentElement.$('.dropdown-menu')[0];

			document.$('.dropdown-menu').forEach($m => $m != $menu && $m.classList.remove('show'));
			
			if ($menu)
			{
				e.preventDefault();

				if($menu.className.indexOf('show') > -1)
				{
					$menu.classList.remove('show');
				} else {
					$menu.classList.add('show');

					var top = $button.offsetTop + $button.offsetHeight;
					$menu.style.top = (top + 5) + 'px';

					var bottom = $menu.offsetTop + $menu.offsetHeight;
					var $el = $menu;
					while ($el.offsetParent) {
						$el = $el.offsetParent;
						bottom += $el.offsetTop;
					}

					if(bottom > window.pageYOffset + window.innerHeight)
						window.scroll(0, bottom);
				}
			}

		}, false);
	});
}
