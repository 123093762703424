import AggregateRoot from "../aggregateRoot.js";
import { MembershipType } from "./membershipType.js";
import { TeamPermissions } from "./teamPermissions.js";

export default class Membership extends AggregateRoot
{
	teamId = null
	nonUserEmail = null
	userId = null
	type = 0
	permissions = 0
	invitedByUserId = null
	notifyInviterOnAccept = false
	mentorIds = []

	/// Creates an Invitation for a person who is not currently an ivelop user
	static createNonUserInvite(membershipId, teamId, nonUserEmail, permissions, invitedByUserId, notifyInviterOnAccept)
	{
		let mem = new Membership();
		mem.apply('personInvitedToTeam', {
			aggregateRootId: membershipId,
			teamId,
			nonUserEmail,
			permissions,
			invitedByUserId,
			notifyInviterOnAccept
		});
		return mem;
	}

	/// Creates an Invitation for an existing ivelop user
	static createUserInvite(membershipId, teamId, userId, permissions, invitedByUserId, notifyInviterOnAccept)
	{
		let mem = new Membership();
		mem.apply('personInvitedToTeam', {
			aggregateRootId: membershipId,
			teamId,
			userId,
			permissions,
			invitedByUserId,
			notifyInviterOnAccept
		});
		return mem;
	}

	onPersonInvitedToTeam(event) 
	{
		this.id = event.aggregateRootId;
		this.teamId = event.teamId;
		this.nonUserEmail = event.nonUserEmail;
		this.userId = event.userId
		this.type = MembershipType.invite;
		this.permissions = event.permissions;
		this.invitedByUserId = event.invitedByUserId;
		this.notifyInviterOnAccept = event.notifyInviterOnAccept
	}


	/// Creates an request for an existing user to join an existing team
	static createApplication(teamId, userId)
	{
		let mem = new Membership();
		mem.apply('userAppliedToJoinTeam', {
			aggregateRootId: mem.id,
			teamId,
			userId,
			permissions: TeamPermissions.participant
		});
		return mem;
	}

	onUserAppliedToJoinTeam(event) 
	{
		this.id = event.aggregateRootId;
		this.teamId = event.teamId;
		this.userId = event.userId
		this.type = MembershipType.application;
		this.permissions = event.permissions;
	}

	/// Creates a membership directly, without an invitation/application flow
	/// Intended only to be used for creating initial team members when a team is created.
	static createMembership(teamId, userId, permissions)
	{
		let mem = new Membership();
		mem.apply('membershipCreated', {
			aggregateRootId: mem.id,
			teamId,
			userId,
			permissions: permissions
		});
		return mem;
	}

	onMembershipCreated(event) 
	{
		this.id = event.aggregateRootId;
		this.teamId = event.teamId;
		this.userId = event.userId
		this.type = MembershipType.member;
		this.permissions = event.permissions;
	}

	/// Accepts this Invitation - where this invitation was for an existing ivelop user
	/// Performed by the User
	/// Only valid for Invitation membership types
	acceptInvitation()
	{
		if(this.type != MembershipType.invite)
			throw new Error(`Membership ${this.id} for team ${this.teamId} for user ${this.userId} is not an invitation and cannot be Accepted`)

		this.apply('teamInviteAccepted', {
			teamId: this.teamId,
			userId: this.userId,
			permissions: this.permissions,
			invitedByUserId: this.invitedByUserId,
			notifyInviterOnAccept: this.notifyInviterOnAccept
		})
	}

	onTeamInviteAccepted(event)
	{
		this.userId = event.userId;
		this.nonUserEmail = null;
		this.type = MembershipType.member
	}

	/// Withdraws the membership, removing the user from the team
	/// Performed by the User
	/// Only valid for Member membership types
	withdrawMembership()
	{
		if(this.type != MembershipType.member)
			throw new Error(`Membership ${this.id} is not a full membership and so cannot be withdrawn`)

		this.apply('teamMembershipWithdrawn', {
			teamId: this.teamId,
			userId: this.userId,
			mentorIds: this.mentorIds
		})
	}

	onTeamMembershipWithdrawn(event)
	{
		// noop here, an event listener deletes this object.
	}

	/// Removes the membership, removing the user from the team
	/// Performed by a team Admin
	/// Only valid for Member membership types
	removeMembership()
	{
		if(this.type != MembershipType.member)
			throw new Error(`Membership ${this.id} is not a full membership and so cannot be removed`)

		this.apply('teamMembershipRemoved', {
			teamId: this.teamId,
			userId: this.userId,
			mentorIds: this.mentorIds
		})
	}

	onTeamMembershipRemoved(event)
	{
		// noop here, an event listener deletes this object.
	}

	/// Revokes the invitation
	/// Performed by a Team admin
	/// Only valid for Invitation membership types
	revokeInvitation()
	{
		if(this.type != MembershipType.invite)
			throw new Error(`Membership ${this.id} is not an invite and so cannot be revoked`)

		this.apply('teamInvitationRevoked', {
			teamId: this.teamId,
			userId: this.userId,
			nonUserEmail: this.nonUserEmail
		})
	}

	onTeamInvitationRevoked(event)
	{
		// noop here, an event listener deletes this object.
	}

	/// Resends an invitation
	/// Performed by a Team admin
	/// Only valid for Invitation membership types
	resendInvitation(invitedByUserId)
	{
		if(this.type != MembershipType.invite)
			throw new Error(`Membership ${this.id} is not an invite and so cannot be resent`)

		this.apply('teamInvitationResent', {
			teamId: this.teamId,
			userId: this.userId,
			nonUserEmail: this.nonUserEmail,
			invitedByUserId
		})
	}

	onTeamInvitationResent(event)
	{
		// noop here
	}


	/// Rejects an Invitation
	/// Performed by the User. Non users cannot reject an invitation.
	/// Only valid for Invitation membership types
	rejectInvitation()
	{
		if(this.type != MembershipType.invite)
			throw new Error(`Membership ${this.id} is not an invite and so cannot be rejected`)

		this.apply('teamInvitationRejected', {
			teamId: this.teamId,
			userId: this.userId
		})
	}

	onTeamInvitationRejected(event)
	{
		// noop here, an event listener deletes this object.
	}

	/// Rejects an Invitation
	/// Performed by the User. Non users cannot reject an invitation.
	/// Only valid for Invitation membership types
	cancelApplication()
	{
		if(this.type != MembershipType.application)
			throw new Error(`Membership ${this.id} is not an application and so cannot be cancelled`)

		this.apply('teamApplicationCancelled', {
			teamId: this.teamId,
			userId: this.userId
		})
	}

	onTeamApplicationCancelled(event)
	{
		// noop here, an event listener deletes this object.
	}


	/// Approves a Users application
	/// Performed by a Team Admin
	/// Only valid for Application membership types
	approveApplication()
	{
		if(this.type != MembershipType.application)
			throw new Error(`Membership ${this.id} is not an application and so cannot be approved`)

		this.apply('teamApplicationApproved', {
			teamId: this.teamId,
			userId: this.userId,
			permissions: this.permissions
		})
	}

	onTeamApplicationApproved(event)
	{
		this.type = MembershipType.member
	}

	/// Approves a Users application
	/// Performed by a Team Admin
	/// Only valid for Application membership types
	rejectApplication()
	{
		if(this.type != MembershipType.application)
			throw new Error(`Membership ${this.id} is not an application and so cannot be rejected`)

		this.apply('teamApplicationRejected', {
			teamId: this.teamId,
			userId: this.userId
		})
	}

	onTeamApplicationRejected(event)
	{
		// noop here, an event listener deletes this object.
	}


	/// 
	assignMentors(mentorUserIds)
	{
		if(mentorUserIds.indexOf(this.userId) > -1)
			throw new Error(`Cannot assign mentee as a self-mentor ${this.userId}`)

		let existingMentors = [];
		let newMentors = [];
		let removedMentors = [];

		for(var mentorUserId of mentorUserIds)
		{
			if(this.mentorIds.find(m => m == mentorUserId))
				existingMentors.push(mentorUserId)
			else
				newMentors.push(mentorUserId)
		}

		for(var mentorUserId of this.mentorIds)
		{
			if(!mentorUserIds.find(m => m == mentorUserId))
				removedMentors.push(mentorUserId)
		}

		this.apply('mentorsAssignedToMembership', {
			teamId: this.teamId,
			userId: this.userId,
			existingMentors,
			newMentors,
			removedMentors
		})
	}

	onMentorsAssignedToMembership(event)
	{
		this.mentorIds = event.newMentors.concat(event.existingMentors);
	}

	/// Converts the membership into an Administrator
	makeAdmin()
	{
		if(this.permissions == TeamPermissions.admin)
			throw new Error(`Membership ${this.id} is already an Admin`)

		this.apply('membershipPermissionsChangedToAdmin', {
			teamId: this.teamId,
			userId: this.userId
		});
	}

	onMembershipPermissionsChangedToAdmin(event)
	{
		this.permissions = TeamPermissions.admin;
	}

    /// Converts the membership into a Participant
	makeParticipant()
	{
		if(this.permissions == TeamPermissions.participant)
			throw new Error(`Membership ${this.id} is already a Participant`)

		this.apply('membershipPermissionsChangedToParticipant', {
			teamId: this.teamId,
			userId: this.userId
		});
	}

	onMembershipPermissionsChangedToParticipant(event)
	{
		this.permissions = TeamPermissions.participant;
	}


	/// Associates an Invitation for a non-user with an existing user
	/// Valid only for Invitations for NonUsers
	associateNonUserInviteToUser(userId)
	{
		if(this.type != MembershipType.invite)
			throw new Error(`Membership ${this.id} is not an invite`)
		if(this.userId)
			throw new Error(`Membership ${this.id} is already associated with a user`)

		this.apply('teamInviteAssociatedToUser', {
			teamId: this.teamId,
			userId: userId,
			permissions: this.permissions
		});
	}

	onTeamInviteAssociatedToUser(event)
	{
		this.userId = event.userId;
		this.nonUserEmail = null;
	}
}