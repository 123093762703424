<script>
import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/functions.js';
import { getViewUser } from '../../js/readModel.js';
import { navigate, PageShowError } from '@trullock/page-manager'

export const pageName = 'onboarding-team-invited';
export const pageRoute = '/onboarding/teams/invited';
export const title = 'Get started'

export default {
	data() {
		return { 
			invites: [],
			pane: '',
			ready: false
		}
	},
	methods: {
		async show(opts)
		{
			this.currentUser = await getViewUser(getCurrentUserId());
			this.invites = this.currentUser.teamInvitations;

			if(this.invites.length == 0)
				throw new PageShowError('/onboarding/individual', 'No invitations', {}, 'replace')

			this.pane = this.invites.length == 1 ? 'one' : 'many'
			this.ready = true;
		},
		async accept(membershipId) {
			
			this.$refs.form.setSubmitting(true)

			await functions.teamInvitationAccept({
				membershipId
			})
			navigate('/onboarding/individual')

			
			this.$refs.form.setSubmitting(false)
		},
		async reject(membershipId) {
			
			this.$refs.form.setSubmitting(true)

			await functions.teamInvitationReject({
				membershipId
			})
			navigate('/onboarding/individual')

			
			this.$refs.form.setSubmitting(false)
		},
		async rejectAll() {
			await functions.teamInvitationRejectAll()
			navigate('/onboarding')
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div v-if="ready && pane == 'one'" class="container">
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6 my-5">
            <h1 class="display-4 text-center mb-5 mb-md-6">Before we get started&hellip;</h1>

            <h2>You've been invited to join <Strong>{{ invites[0].name }}</Strong></h2>
            <p>If you recognise this team, accept the invitation and join your team mates.</p>
            <p>If you don't recognise the invitation, you can reject it and all will be forgotten.</p>

			<form ref="form">
				<button @click.prevent="accept(invites[0].membershipId)" type="submit" class="btn btn-primary btn-block mb-4">Accept invitation</button>
                <button @click.prevent="reject(invites[0].teamId)" type="submit" class="btn btn-text text-danger">Reject invitation</button>
            </form>
        </div>
    </div>
</div>
<div v-if="ready && pane == 'many'" class="container">
    <div class="row justify-content-center text-center">
        <div class="col-12 col-md-6 my-5">
            <h1 class="display-4 mb-3">Before we get started&hellip;</h1>
            
            <h2>You've been invited to join teams</h2>
            <p class="mb-4">You can only belong to one team at a time, so you need to decide which - if any - of these invitations are appropriate.</p>
            
			<template v-for="invite of invites">
				<h3>{{ invite.name}}</h3>
				<form @submit.prevent="accept(invite.membershipId)" class="mb-4">
					<button type="submit" class="btn btn-primary mr-2">Accept invitation</button>
				</form>
			</template>
            
            <p>If you don't wish to join any of these teams, you can reject the invitations and go it alone.</p>
            <form @submit.prevent="rejectAll">
                <button type="submit" class="btn btn-sm btn-outline-danger mr-2">Reject all invitations</button>
            </form>
        </div>
    </div>
</div>


</template>