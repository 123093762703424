<script>

export default {
	data() {
		return { 
			task: this.task
		}
	},
	props: [ 'task' ]
}

</script>
<template>
<section class="card">
    <div class="card-header">
        <h4 class="card-header-title">Wait for application approval</h4>
    </div>
    <div class="card-body">
        <p>You have applied to join <strong>{{task.teamName}}</strong>, but you need to wait for an admin to approve you before you can get started.</p>
        <p class="small">We've let the team admins know you've applied, but going and nagging them might speed things up ;)</p>
        <p class="small mb-0">We'll let you know when your application has been approved.</p>
    </div>
</section>
</template>