import { addBusinessDays, addDays, format } from 'date-fns'
import { enGB } from 'date-fns/locale/index.js';


export default class DateTime
{
	_date = 0

	constructor(value)
	{
		if(value instanceof DateTime)
			this._date = value._date;
		else if(value instanceof Date)
			this._date = value;
		else if(typeof value == 'number')
			this._date = new Date(value);
		else
		 	throw new Error();
	}

	valueOf()
	{
		return this._date.getTime()
	}

	static now()
	{
		// TODO: check how UTC is handled
		return new DateTime(new Date());
	}

	get year()
	{
		return this._date.getFullYear()
	}

	addBizDays(days)
	{
		return new DateTime(addBusinessDays(this._date, days));
	}

	addDays(days)
	{
		return new DateTime(addDays(this._date, days));
	}


	dateOnly()
	{
		var timePortion = this._date.getTime() % (3600 * 1000 * 24);
		return new DateTime(this._date - timePortion);
	}

	toString()
	{
		return this._date.toLocaleString();
	}

	// https://date-fns.org/v2.29.3/docs/format
	format(fmt)
	{
		return format(this._date, fmt , { locale: enGB });
	}
}