<script>
import DateTime from '../../../functions/domain/dateTime.js';

export default {
	data() {
		return { 
			task: this.task,
			DateTime
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<div class="card">
    <div class="card-header">
        <h4 class="card-header-title">Review feedback from {{ task.mentorName }}</h4>
		<small :class="task.dueOn <= DateTime.now() ? 'text-danger' : 'text-muted'" :aria-title="`This task should be completed before ${task.dueOn.format('P')}`"><span class="fe fe-clock"></span> {{task.dueOn.format('MMMM do')}}</small>
        <a :href="`/reflections/${task.reflectionId}/feedback/${task.mentorId}`" class="btn btn-sm btn-primary">Review feedback <span class="fe fe-arrow-right-circle"></span></a>
    </div>
    <div class="card-body">
		<div v-if="task.dueOn <= DateTime.now()" class="alert alert-warning small" role="alert">
			The feedback deadline has passed, go and nag your mentor to discuss your feedback with you.
		</div>
        <div v-else-if="task.dueOn <= DateTime.now().addBizDays(2)" class="alert alert-warning small" role="alert">
            The feedback deadline is nearly upon you, go and nag your mentor to discuss your feedback with you.
        </div>
        <p class="mb-0">{{task.mentorName}} has given you feedback which you should review and then discuss with them.</p>
    </div>
</div>
</template>