import { MembershipType } from "../teams/membershipType.js"
import { TeamPermissions } from "../teams/teamPermissions.js"

export default class ViewUser {
	id = null
	name = null
	email = null
	avatar = null

	currentReflection = null
	currentIteration = null

	memberships = []
	get membership() {
		return this.memberships.find(m => m.type == MembershipType.member);
	}
	set membership(mem) {
		if(mem)
		{
			this.memberships.push(mem)
		}
		else
		{
			if(this.membership)
				this.memberships.splice(this.memberships.indexOf(this.membership));
		}
	}

	get isMemberOfATeam() {
		return this.membership != null;
	}

	get isAdminOfATeam() {
		return this.membership?.permissions == TeamPermissions.admin;
	}

	isAdminOfTeam(teamId) {
		return this.membership?.permissions == TeamPermissions.admin && this.membership.teamId == teamId;
	}

	get teamApplication() {
		return this.memberships.find(m => m.type == MembershipType.application);
	}
	set teamApplication(app) {
		if(app)
		{
			this.memberships.push(app);
		}
		else 
		{
			if(this.teamApplication)
				this.memberships.splice(this.memberships.indexOf(this.teamApplication))
		}
	}

	get isAppliedToATeam() {
		return this.teamApplication != null;
	}

	get teamInvitations() {
		return this.memberships.filter(m => m.type == MembershipType.invite);
	}

	get isInvitedToATeam() {
		return this.teamInvitations.length > 0
	}

	canApplyToTeam(teamId) {
		return this.memberships.findIndex(m => m.teamId == teamId) == -1;
	}

	static Reflection = class {
		id = null
		name = null
		createdOn = null
		roleId = null
		levelId = null
		roleTitle = null
		levelTitle = null
		dueOn = null
		answersSubmitted = false
		reviewId = null
	}
}