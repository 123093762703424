<script>

export const pageName = 'onboarding-individual';
export const pageRoute = '/onboarding/individual';
export const title = 'Get started'

export default {
	data() {
		return { }
	}
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">How does this work?</h1>
				<p class="text-muted text-center mb-5 mb-md-6">Here's a quick overview to help get you started.</p>
				
				<h2>1. Self-Reflection</h2>
				<p class="mb-5">Every few months you'll do a complete review of how you're performing against a number of a core and role-specific competencies.</p>
				
				<h2>2. Iterations of improvement</h2>
				<p class="mb-5">Between reflections you'll have short iterations/periods of improvement, where you focus on a few criteria to work on at a time.</p>
				
				<h2>3. Track &amp; Repeat</h2>
				<p class="mb-5 mb-md-6">Monitor your progress and be guided to work on the most valuable traits/qualities to develop yourself and your career.</p>
				
				<div class="mb-5">
					<a href="/" class="btn btn-block btn-primary">Get started <span class="fe fe-arrow-right-circle"></span></a>
				</div>
			</div>
		</div>
	</div>
</template>