export function distinct (object, property) {
	var unique = {};
	var d = [];
	for(var i = 0; i < object.length; i++){
		let key = property(object[i]);
		if(!unique[key]){
			d.push(object[i]);
			unique[key] = 1;
		}
	}
	return d;
}

export function groupBy(array, keyFn) {
	const groupedMap = {}
	for (const e of array) {
		const key = keyFn(e);
		const thisArray = groupedMap[key];
		if (thisArray !== undefined) {
			thisArray.push(e);
		}
		else {
			groupedMap[key] = [e]
		}
	}
	return groupedMap;
}

export function chunk(array, size) {
	let chunks = [];
	while(array.length)
		chunks.push(array.splice(0, size))
	return chunks;
}