<script>

export default {
	data() {
		return { 
			currentPage: this.currentPage
		}
	},
	props: [ 'currentPage' ]
}

</script>
<template>
	<div class="row align-items-center">
		<div class="col">
			<ul class="nav nav-tabs nav-overflow header-tabs">
				<li class="nav-item">
					<a href="/" :class="`nav-link ${currentPage == 'dashboard' ? 'active' : null}`">Dashboard</a>
				</li>
				<li class="nav-item">
					<a href="/insights" :class="`nav-link ${currentPage == 'insights' ? 'active' : null}`">Insights</a>
				</li>
			</ul>
		</div>
	</div>
</template>