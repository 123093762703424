import { UserTask } from "./userTask.js";

export default class ReviewFeedbackUserTask extends UserTask
{
	// TODO: these aren't maintained by user changes
	mentorId = null
	mentorName = null
	mentorAvatar = null

	reflectionId = null
	

	get summary() {
		return `Review ${this.mentorName}'s feedback`
	}
}
