import { TeamPermissions } from "./teamPermissions.js"

export default class TeamMemberships {
	id = null
	members = []
	invitations = []
	applications = []

	get admins()
	{
		return this.members.filter(m => m.permissions == TeamPermissions.admin)
	}
}