<script>

export default {
	data() {
		return { 
			task: this.task
		}
	},
	props: [ 'task' ]
}

</script>
<template>
<div class="alert alert-light" role="alert">
	<p><span class="fe fe-info"></span> You have not finished setting up your team. You need to do that before you can start your first review.</p>
	<p class="mb-0 small">At least 1 person must join your team to complete the "people" setup step.</p>
</div>
</template>