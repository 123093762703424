<script>
export const pageName = 'reflections-advice-topic';
export const pageRoute = '/reflections/{reflectionId}/advice';
export const title = 'Choose iteration topic'

import UserRole from '../../../../functions/domain/userRole.js';
import { getRoleById} from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { functions } from '../../../js/functions.js';
import { getReflectionView, getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'
import {navigate} from '@trullock/page-manager'
import { ratingStars } from '../../../js/utils.js';


export default {
	data() {
		return {
			currentUser: null,
			reflectionId: null,
			topics: [],
			ready: false
		}
	},
	methods: {
		ratingStars: ratingStars,
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			let reflectionView = await getReflectionView(opts.reflectionId)
			this.reflectionId = reflectionView.id;

			let role = getRoleById(reflectionView.roleId);
			let roleLevel = role.getLevelById(reflectionView.levelId);

			var userRole = new UserRole(role, roleLevel);
            userRole.applyAnswers(reflectionView.answers);
			userRole.applyFeedback(reflectionView.feedback);
			userRole.applyIterations(reflectionView.iterations);

			this.topics = userRole.level.competencies.flatMap(c => {
				return c.topics.map(t => ({
					competencyId: c.id,
					competencyTitle: c.title,
					topicId: t.id,
					topicTitle: t.title,
					answerRating: t.answerRating
				}))
			})

			this.topics.sort((a, b) => a.answerRating - b.answerRating)
			
			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	
<div v-if="ready" class="container">

	<div class="row">
		<div class="col">
			<div class="header">
				<div class="header-body pt-0">
					<MeNav />
				</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-center mb-4">
		<div class="col-12 col-md-6 text-center">
			<h1>Choose iteration topic</h1>
			<p>Over the coming weeks you'll iterate over some of the below topics and try and improve on them.</p>
			<p>It's not expected that you'll cover all of these topics for many months yet. This isn't a check-box exercise or a race, you need to practice, form habits and take time to reflect on how you can better yourself in each area - and we'll support you in doing that.</p>
			<p>Choose a topic to work on this iteration.</p>
			<p v-if="currentUser.isMemberOfATeam" class="small">Ideally you'll <strong>have a conversation with your manager or mentor</strong> so that they can help you choose the topic which will bring the most value to you and your team.<br />Now would be a good time to go and have a chat with them.</p>
		</div>
	</div>

	<div class="row">
		<div class="col">
			<div class="card">
				<table class="table table-sm table-hover card-table">
					<thead>
						<tr>
							<th class="d-none d-md-table-cell shrink">Priority</th>
							<th>Competency - Topic</th>
							<th class="d-none d-sm-table-cell">Rating <span aria-title="How well each topic scored on your self-reflection"><span class="fe fe-info"></span></span></th>
							<th>Progress <span aria-title="How much work you've done on this in self-development iterations"><span class="fe fe-info"></span></span></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(topic, i) of topics">
							<td class="d-none d-md-table-cell">{{ i + 1 }}</td>
							<td>{{ topic.competencyTitle }} - {{ topic.topicTitle }}</td>
							<td class="d-none d-sm-table-cell" v-html="ratingStars(topic.answerRating)"></td>
							<td>
								<div class="row align-items-center justify-content-center no-gutters">
									<div class="col-auto">
										<span class="mr-2">{{topic.addressedness}}%</span>
									</div>
									<div class="col d-none d-sm-block">
										<div class="progress progress-sm @this.CompletenessColor(topic.Addressedness)">
											<div class="progress-bar p-@topic.Addressedness"></div>
										</div>
									</div>
								</div>
							</td>
							<td class="shrink">
								<a :href="`/reflections/${reflectionId}/advice/${topic.competencyId}/${topic.topicId}`" class="btn btn-sm btn-block btn-outline-primary">Choose<span class="d-none d-sm-inline"> topic <span class="fe fe-arrow-right-circle"></span></span></a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

</template>