<script>
export const pageName = 'reflections-new-choose-level';
export const pageRoute = '/reflections/new/{role}';
export const title = 'New self-reflection'

import { getRoleByPath } from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'

export default {
	data() {
		return {
			roles: [],
			ready: false
		}
	},
	methods: {
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());

			this.role = getRoleByPath(opts.role)
			// TODO: 404

			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav />
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mb-4">
			<div class="col-12 col-md-8 col-lg-6 text-center">
				<h1>Select your level</h1>
				<p>Choose the level which you're currently operating at.</p>
				<p class="small">If you're curious as to what each level entails, you can <a :href="`/browse/roles/${role.path}`" target="_blank">browse all levels <sup class="fe fe-external-link"></sup></a>.</p>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-lg-6">
				<div class="card">
					<div class="card-body">
						<ul class="list-group list-group-flush list">
							<li v-for="level of role.levels" class="list-group-item">
								<div class="row">
									<div class="col">{{ level.icon }} {{ level.title }}</div>
									<div class="col col-auto"><a :href="`/reflections/new/${role.path}/${level.path}`" class="btn btn-sm btn-outline-primary">Choose <span class="fe fe-arrow-right-circle"></span></a></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>