<script>

export default {
	data() {
		return { 
			task: this.task,
			teamPath: this.currentUser.membership.path
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<div class="card">
    <div class="card-header">
        <h4 class="card-header-title">Start review</h4>
        {{  dueOn }}
        <a :href="`/teams/${teamPath}/reviews/start`" class="btn btn-sm btn-primary">Start first review <span class="fe fe-arrow-right-circle"></span></a>
    </div>
    <div class="card-body">
        <p>It's time to start your team's first review. This will kick-off the process of team members self-reflecting and mentors giving feedback.</p>
        <p class="mb-0">Ideally you should only do this once all your team members have joined and you have everything set up as you'd like it, although late-comers can still fully participate.</p>
    </div>
</div>
</template>