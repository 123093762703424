import { ReviewPhase } from "../reviews/reviewPhase.js"
import { TeamState } from "./teamState.js"

export default class ViewTeam {
	id = null
	name = null
	path = null
	createdOn = null
	iterationDurationDays = 0
	reflectionDurationDays = 0
	state = TeamState.BasicDetails
	reviews = []
	reviewMonth0 = 1
	reviewMonth1 = 4
	reviewMonth2 = 7
	reviewMonth3 = 10

	get currentReview()
	{
		return !this.reviews.length ? null : this.reviews[this.reviews.length - 1];
	}
	get currentReviewPhase()
	{
		return this.currentReview?.phase || ReviewPhase.none;
	}

	updatePeopleState()
	{
		this.state |= TeamState.People;
	}

	static Review = class {
		id = null
		name = null
		startedOn = null
		reflectionDurationDays = 0
		phase = 0
		reflectionPhaseEndsOn = null
		startedIteratingOn = null
	}
}