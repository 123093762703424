import Modal from './modal.js';

var $confirmDialog = null, confirmDialog = null;
var positiveAction = null, negativeAction = null;
export function showConfirm(options)
{
	if (!$confirmDialog)
	{
		$confirmDialog = document.$('.js-confirm-dialog');
		confirmDialog = new Modal($confirmDialog);
		$confirmDialog.$('.js-positive').addClickListener(e => {
			$confirmDialog.$('button').disabled = true;
			e.target.setSubmitting(true);
			Promise.resolve(positiveAction?.()).then(() => confirmDialog.hide())
		});
		$confirmDialog.$('.js-negative').addClickListener(e => {
			$confirmDialog.$('button').disabled = true;
			e.target.setSubmitting(true);
			Promise.resolve(negativeAction?.()).then(() => confirmDialog.hide())
		});
	}
	
	positiveAction = options.positiveAction;
	negativeAction = options.negativeAction;

	$confirmDialog.$('.js-title').textContent = options.title;
	$confirmDialog.$('.js-message').innerHTML = options.message;
	let $btnPositive = $confirmDialog.$('.js-positive');
	$btnPositive.textContent = options.positiveText;

	$btnPositive.className = 'btn js-positive';
	$btnPositive.classList.add('btn-' + (options.positiveStyle || 'primary'))
	$btnPositive.setSubmitting(false);

	let $btnNegative = $confirmDialog.$('.js-negative');
	$btnNegative.className = 'btn js-negative';
	$btnNegative.classList.toggle('d-none', options.negativeText === null);
	$btnNegative.classList.add('btn-' + (options.negativeStyle || 'white'))
	$btnNegative.textContent = options.negativeText || 'Cancel';
	$btnNegative.setSubmitting(false);
	confirmDialog.show();
}
