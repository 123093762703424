export default class LiteUser {
	id = null
	name = null
	email = null
	avatar = null

	membership = null
	
	get isMemberOfATeam() {
		return this.membership != null;
	}

	get isAdminOfATeam() {
		return this.membership?.permissions == 'admin';
	}
}