export default class TeamRoles {
	id = null
	includeAllDefaultRoles = true
	defaultRoles = []
	customRoles = []

	filterDefaultRoles(roles)
	{
		if(this.includeAllDefaultRoles)
			return roles;

		return roles.filter(r => this.defaultRoles.findIndex(x => x == r.id) > -1)
	}
}