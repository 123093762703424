<script>
export const pageName = 'reflections-new-start';
export const pageRoute = '/reflections/new/{role}/{level}';
export const title = 'New self-reflection'

import DateTime from '../../../../functions/domain/dateTime.js';
import { getRoleByPath } from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { functions } from '../../../js/functions.js';
import Modal from '../../../js/modal.js';
import { getLiteUsers, getTeamMemberships, getViewTeam, getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'
import {navigate} from '@trullock/page-manager'

export default {
	data() {
		return {
			role: null,
			level: null,
			namePlaceholder: '',
			reflectionName: '',
			reflectionDurationDays: 0,
			filter: '',
			inTeam: false,
			mentors: [],
			members: [],
			allMembers: [],
			ready: false
		}
	},
	methods: {
		showAddMentor() {
			if(!this.modal)
				this.modal = new Modal(this.$refs.modal)
			this.filter = null;
			this.filterMembers();
			this.modal.show()
		},
		addMentor (member) {
			this.mentors.push(member);
			this.modal.hide();
		},
		removeMentor (mentor) {
			let index = this.mentors.find(m => m == mentor);
			this.mentors.splice(index, 1)
		},
		filterMembers() {
			if(!this.filter)
				this.members = this.allMembers
			else
				this.members = this.allMembers.filter(m => {
					let parts = this.filter.split(' ').map(p => p.trim().toLowerCase()).filter(p => p.length)
					return parts.filter(p => m.name.toLowerCase().indexOf(p) > -1).length == parts.length
				})
		},
		async submit() {
			
			this.$refs.form.setSubmitting(true)
			
			let reflectionId = null;
			if(this.currentUser.isMemberOfATeam)
			{
				let mentors = this.mentors.map(m => m.id);
				let result = await functions.reflectionCreateForTeamMember({
					teamId: this.currentUser.membership.teamId,
					reviewId: this.team.currentReview.id,
					name: this.team.currentReview.name,
					roleId: this.role.id,
					levelId: this.level.id,
					dueOn: this.team.currentReview.reflectionPhaseEndsOn,
					mentors
				})
				reflectionId = result.data
			}
			else
			{
				// TODO
			}

			navigate(`/reflections/${reflectionId}/answer/start`)

			this.$refs.form.setSubmitting(false)
		},
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());

			this.role = getRoleByPath(opts.role);
			this.level = this.role.getLevelByPath(opts.level);

			this.namePlaceholder = `e.g. ${DateTime.now().format('MMMM yyyy')}`;
			this.reflectionName = DateTime.now().format('MMMM yyyy')
			this.reflectionDurationDays = 14;

			if(this.currentUser.isMemberOfATeam)
			{
				this.inTeam = true;

				let memberships = await getTeamMemberships(this.currentUser.membership.teamId);
				let userIds = memberships.members.map(m => m.userId)
				userIds = userIds.filter(id => id != this.currentUser.id);
				this.allMembers = await getLiteUsers(userIds);
				this.members = this.allMembers;
				this.filter = null;

				this.mentors = this.members.filter(member => this.currentUser.membership.mentors.findIndex(m => member.id == m) > -1)

				this.team = await getViewTeam(this.currentUser.membership.teamId);
				this.reflectionDurationDays = this.team.currentReview.reflectionDurationDays
			}
			
			this.ready = true;

		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav />
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mb-4">
			<div class="col-12 col-md-8 col-lg-6 text-center">
				<h1>A few final details&hellip;</h1>
				<p>Let's make sure you get the most out of this.</p>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-lg-6">
				<div class="card">
					<div class="card-body">
						<form ref="form" @submit.prevent="submit">

							<fieldset v-if="!inTeam">
								<legend>
									Reflection details
								</legend>
								<!-- @this.TextBoxFor(x => x.ReflectionName).WithPlaceholder(ViewData.NamePlaceholder).WithHelpText("You'll start a new self-reflection every few months, make it easy to identify")
								@this.NumberBoxFor(x => x.ReflectionDuration, 1, 31).WithLabel("Time limit (days)").WithHelpText("We recommend giving yourself a 2-week window to complete this reflection, deadlines are a good thing ;)") -->
							</fieldset>

							<div v-else>
								<h3>Mentors</h3>
								<p>A mentor is usually your manager, but you can choose any and all the people who oversee or contribute to your development. They will have visibility of your self-reflections and will feedback on them.</p>
								<ul class="list-group list-group-flush list mentor-list mb-4">
									<li v-for="mentor of mentors" :class="`list-group-item`">
											<div class="row align-items-center">
												<div class="col-auto">
													<div class="avatar avatar-sm">
														<img :src="mentor.avatar" :alt="`Picture of ${mentor.name}`" class="avatar-img rounded-circle">
													</div>
												</div>
												<div class="col ml-n2">
													<h4 class="mb-1 name">{{  mentor.name  }}</h4>
												</div>
												<div class="col-auto">
													<button @click.prevent="removeMentor(mentor)" class="btn btn-sm btn-outline-danger remove" type="button">Remove <span class="fe fe-x-circle"></span></button>
												</div>
											</div>
										</li>
								</ul>
																
								<button class="btn btn-outline-primary" type="button" @click.prevent="showAddMentor">Add mentor <span class="fe fe-plus-circle"></span></button>
								<hr class="my-4"/>
							</div>
						
							<button type="submit" class="btn mt-4 btn-lg btn-block btn-primary">Start reflection <span class="fe fe-check-circle"></span></button>
						</form>
					</div>
				</div>

				<div v-if="inTeam" ref="modal" class="modal fade js-confirm-dialog d-none" tabindex="-1" aria-modal="true" role="dialog">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content modal-card card">
							<div class="card-header">
									<h4 class="card-header-title">Search team members</h4>
									<button type="button" class="js-close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">×</span>
									</button>
								</div>
								<div class="card-header">
									<div class="input-group input-group-flush input-group-merge">
										<input type="search" v-model="filter" @input="filterMembers" class="form-control form-control-prepended search" placeholder="Search">
										<div class="input-group-prepend">
											<div class="input-group-text">
												<span class="fe fe-search"></span>
											</div>
										</div>
									</div>
								</div>
								<div class="card-body">
									<ul class="list-group list-group-flush list my-n3">
										<li v-for="member of members" class="list-group-item">
											<div class="row align-items-center">
												<div class="col-auto">
													<div class="avatar avatar-sm">
														<img :src="member.avatar" :alt="`Picture of ${member.name}`" class="avatar-img rounded-circle">
													</div>
												</div>
												<div class="col ml-n2">
													<h4 class="mb-1 name">{{  member.name  }}</h4>
												</div>
												<div class="col-auto">
													<button @click.prevent="addMentor(member)" class="btn btn-sm btn-outline-primary add" :disabled="mentors.find(m => m == member)" type="button">Add <span class="fe fe-plus-circle"></span></button>
												</div>
											</div>
										</li>
									</ul>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
</template>