<script>
export const pageName = 'teams-reviews-start';
export const pageRoute = '/teams/{path}/reviews/start';
export const title = 'Start new review'

import { getViewTeamByPath, getViewUser } from '../../../js/readModel.js';
import { functions } from '../../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import TopNav from '../topnav.vue'
import { getCurrentUserId } from '../../../js/auth.js';
import DateTime from '../../../../functions/domain/dateTime.js'

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			reflectionDuration: 0,
			reviewName: '',
			reviewNamePlaceholder: '',
			ready: false
		}
	},

	methods: {
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);

			await functions.reviewStart({
				teamId: this.team.id,
				reviewName: this.reviewName,
				reflectionDuration: this.reflectionDuration
			});
			
			this.$refs.form.setSubmitting(false);

			navigate('/teams/' + this.team.path)
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);
			this.reflectionDuration = this.team.reflectionDurationDays
			let thisYear = DateTime.now().year;
			let reviewNum = this.team.reviews.filter(r => r.startedOn.year == thisYear).length + 1;
			this.reviewNamePlaceholder = `e.g. Review ${reviewNum} - ${thisYear}`;
			this.reviewName = `Review ${reviewNum} - ${DateTime.now().year}`;
			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser" title="Team reviews" current-page="reviews"></TopNav>
		<div class="container">
			<div class="row justify-content-center mb-4">
            <div class="col-12 col-md-8 col-lg-6 text-center">
                <h1>Start a review&hellip;</h1>
                <p>We need a few details to make this run smoothly.</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <form ref="form" @submit.prevent="submit">
							<fieldset>
								<legend>
									Reflection details
								</legend>
								<div class="form-group mb-4">
									<label for="txtTeamsReviewStartName">Review name</label>
									<input type="text" class="form-control" v-model="reviewName" id="txtTeamsReviewStartName" aria-describedby="txtTeamsReviewStartName_Validation" autocomplete="off" :placeholder="reviewPlaceholder" required tabindex="1" />
									<span id="txtTeamsReviewStartName_Validation" class="feedback"></span>
									<span class="form-text text-muted small">You'll start a new review every few months, make it easy to identify.</span>
								</div>
								<div class="form-group mb-0">
									<label for="txtTeamsReviewStartDuration">Reflection duration (in days)</label>
									<input type="number" class="form-control" v-model="reflectionDuration" id="txtTeamsReviewStartDuration" aria-describedby="txtTeamsReviewStartDuration_Validation" autocomplete="off" required tabindex="2" min="1" max="14" />
									<span id="txtTeamsReviewStartDuration_Validation" class="feedback"></span>
									<span class="form-text text-muted small">How long people will have to complete their self-reflections.<br>We recommend 2 weeks.</span>
								</div>
							</fieldset>
							<button type="submit" class="btn mt-4 btn-lg btn-block btn-primary">Start review <span class="fe fe-check-circle"></span></button>
						</form>
                    </div>
                </div>
            </div>
        </div>
		</div>
	</div>
</template>