<script>

export default {
	data() {
		return {
			teamPath: this.teamPath,
			teamState: this.teamState,
			review: this.review,
			reviewStats: this.reviewStats
		};
	},
	props: ['teamPath', 'teamState', 'review', 'reviewStats']
}

</script>
<template>
	<section class="card">
		<div class="card-header">
			<h4 class="card-header-title">Current review : {{ review.name }}</h4>
			<a :href="`/teams/${teamPath}/insights/reviews/${review.id}`" class="btn btn-sm btn-outline-primary">Insights <span class="fe fe-arrow-right-circle"></span></a>
			<a :href="`/teams/${teamPath}/reviews/start`" :class="`btn btn-sm ${true ? 'btn-white' : 'btn-primary'}`">Start next review <span class="fe fe-arrow-right-circle"></span></a>
		</div>
		<div class="card-body">
			<p class="mb-0">Your team should be busy iterating and improving.</p>
		</div>
	</section>
</template>