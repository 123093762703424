import UserRoleTopicLevel from "./userRoleTopicLevel.js"

export default class UserRoleTopic
{
	id = null
	title = null
	path = null
	summary = null
	requiredVsRecommended = false
	
	levels = []

	get requiredLevels()
	{
		return this.levels.filter(l => l.requiredByRole)
	}

	get allRequiredCriteria()
	{
		return this.requiredLevels.map(c => c.criteria).reduce((prev, curr) => prev.concat(curr), []);
	}

	get advisoryCriteria()
	{
		let answers = this.requiredLevels.map(c => c.criteria.filter(fc => fc.answer <= 3)).reduce((prev, curr) => prev.concat(curr), []);
		answers.sort((a, b) => a.answer - b.answer);
		return answers;
	}

	get answerCompleteness()
	{
		let criteria = 0;
		let answeredCriteria = 0;

		this.requiredLevels.forEach(level => {
			criteria += level.criteria.length;
			answeredCriteria += level.criteria.filter(c => c.answer !== null).length
		})

		return (answeredCriteria * 100.0 / criteria)
	}

	// get feedbackCompleteness()
	// {
	// 	let criteria = 0;
	// 	let answeredCriteria = 0;

	// 	this.requiredLevels.forEach(level => {
	// 		criteria += level.criteria.length;
	// 		answeredCriteria += level.criteria.filter(c => c.answer !== null).length
	// 	})

	// 	return (answeredCriteria * 100.0 / criteria)
	// }

	/// Gets the rating for this whole topic.
	/// Will return 0 if any criteria are unanswered
	get answerRating()
	{
		if(this.answerCompleteness != 100)
			return 0;

		if(this.requiredLevels.length == 0)
			return 0;

		let topicScore = 0.0;
		let maxScore = 0;
		let weighting = this.requiredLevels.length;

		for(let level of this.levels)
		{
			if(weighting == 0)
				break;

			maxScore += level.criteria.length * 4 * weighting;
			topicScore += level.criteria.map(c => {
				let aTotal = c.feedback.map(f => f * 3).reduce((p, c) => p + c, 0) + c.answer;
				let answer = aTotal / ((c.feedback.length * 3.0) + 1);
				return answer;
			}).reduce((p, c) => p + c, 0)

			weighting--;
		}

		// Original C#, is this the same?
		// return Math.Round(10 * topicScore / maxScore, 1, MidpointRounding.ToZero);

		let result = 100 * topicScore / maxScore
		result = Math.round(result)
		result /= 10;
		return result;
	}

	/// Gets the adjustment made by feedback
	/// Will return 0 if any criteria are un-fed-back
	get answerFeedback()
	{
		if(this.answerCompleteness != 100)
			return 0;

		if(this.requiredLevels.length == 0)
			return 0;

		let topicScore = 0.0;
		let maxScore = 0;
		let weighting = this.requiredLevels.length;

		for(let level of this.levels)
		{
			if(weighting == 0)
				break;

			maxScore += level.criteria.length * 4 * weighting;
			topicScore += level.criteria.map(c => c.answer).reduce((p, c) => p + c, 0)

			weighting--;
		}

		// Original C#, is this the same?
		// return Math.Round(10 * topicScore / maxScore, 1, MidpointRounding.ToZero);

		let result = this.answerRating - (10 * topicScore / maxScore);
		result *= 10;
		result = Math.round(result);
		result /= 10;
		return result;
	}

	get addressedness()
	{
		let values = this.requiredLevels.map(l => 100.0 * l.critiera.filter(c => c.iteratedOn > 0).length / l.criteria.length);
		let sum = values.reduce((p,c) => p+c, 0);
		let avg = sum / values.length;
		return avg
	}

	constructor(topic)
	{
		this.id = topic.id;
		this.title = topic.title;
		this.path = topic.path;
		this.summary = topic.summary;
		this.requiredVsRecommended = topic.requiredVsRecommended
		this.levels = topic.levels.map(l => new UserRoleTopicLevel(l))
	}
}
