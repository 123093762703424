<script>

export const pageName = 'teams-people';
export const pageRoute = '/teams/{path}/people';
export const title = 'Team members'

import { getCurrentUserId } from '../../js/auth.js';
import { getLiteUsers, getTeamMemberships, getViewTeam, getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import { TeamPermissions } from '../../../functions/domain/teams/teamPermissions.js';
import { functions } from '../../js/functions.js'
import TopNav from './topnav.vue'
import { navigate, refresh } from '@trullock/page-manager'
import Applications from './applications.vue'
import { showConfirm } from '../../js/confirm.js';

export default {
	data() {
		return { 
			currentUser: null,
			team: null,
			teamMemberships: [],
			members: [],
			invitations: [],
			applications: [],
			TeamPermissions,
			ready: false
		}
	},
	methods: {
		async show(opts) {
			let user = await getViewUser(getCurrentUserId());
			let team = await getViewTeamByPath(this.options.path);
			
			// TODO: read security

			let memberships = await getTeamMemberships(team.id);
			let members = await getLiteUsers(memberships.members.map(m => m.userId));

			let inviteeUserIds = memberships.invitations.filter(i => i.userId).map(i => i.userId);
			let applicationUserIds = memberships.applications.map(a => a.userId);
			let userIds = inviteeUserIds.concat(applicationUserIds);
			let users = await getLiteUsers(userIds);

			this.currentUser = user;
			this.team = team;
			this.teamMemberships = memberships;
			this.members = memberships.members.map(m => ({
				membershipId: m.membershipId,
				joinedOn: m.joinedOn,
				isAdmin: m.permissions == TeamPermissions.admin,
				user: members.find(u => u.id == m.userId)
			}));
			this.invitations = memberships.invitations.map(i => ({
				membershipId: i.membershipId,
				isAdmin: i.permissions == TeamPermissions.admin,
				sentOn: i.sentOn,
				nonUserEmail: i.nonUserEmail,
				user: users.find(u => u.id == i.userId)
			}));
			this.applications =  memberships.applications.map(a => ({
				membershipId: a.membershipId,
				requestedOn: a.requestedOn,
				user: users.find(u => u.id == a.userId)
			}))
			
			this.ready = true;
		},
		async invitationRevoke(membershipId) {
			await functions.teamInvitationRevoke({
				membershipId
			});
			navigate(`/teams/${this.team.path}/people`)
		},
		async invitationResend(membershipId) {
			await functions.teamInvitationResend({
				membershipId
			});
			navigate(`/teams/${this.team.path}/people`)
		},
		async makeParticipant(membershipId) {
			await functions.teamMakeParticipant({
				membershipId
			});
			navigate(`/teams/${this.team.path}/people`)
		},
		async makeAdmin(membershipId) {
			await functions.teamMakeAdmin({
				membershipId
			});
			navigate(`/teams/${this.team.path}/people`)
		},
		async removeMember(membershipId) {
			showConfirm({
				title: 'Confirm member removal',
				message: `By removing this person from your team, they will no longer be able to collaborate in team reviews. They will retain access to all past self-reflections and mentor feedback and their data will remain in the team's historical performance record.<br/><span class="small mb-0">Your subscription will be reduced by 1 seat, ensure you <a href="/teams/${this.team.path}/billing" target="_blank">understand how billing works <sup class="fe fe-external-link"></sup></a> first.</span>`,
				positiveStyle: 'danger',
				positiveText: 'Remove',
				positiveAction: async () => {
					await functions.teamRemoveMembership({
						membershipId
					});
					refresh()
				}
			})
			
		}
	},
	components: { TopNav, Applications },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<TopNav :team="team" :user="currentUser" current-page="people"></TopNav>
		<main class="container">
			<div class="row">
				<div class="col12 col-xl-4 order-xl-last">
					<div class="card">
						<div class="card-body">
							<div class="list-group list-group-flush my-n3">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Pending applications to join
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{teamMemberships.applications.length}}
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Pending invitations to join
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{teamMemberships.invitations.length}}
											</small>
										</div>
									</div>
								</div>

								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Members
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{teamMemberships.members.length}}
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Admins
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{teamMemberships.members.filter(m => m.permissions == TeamPermissions.admin).length}}
											</small>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="col-12 col-xl-8">

					<section class="card">
						<div class="card-header">
							<h4 class="card-header-title">Applications to join</h4>
						</div>
						<Applications v-if="applications.length" :currentUser="currentUser" :team="team" :applications="applications"></Applications>
						<div v-else class="card-body">
							<p class="small text-muted mb-0">There are no pending applications.</p>
						</div>
					</section>
					<section class="card">
						<div class="card-header">
							<h4 class="card-header-title">Invitations to join</h4>
							<a :href="`/teams/${team.path}/people/invite`" :class="`btn btn-sm btn-outline-primary ${currentUser.isAdminOfTeam(team.id) ? '' : 'disabled'}`">Invite people <span class="fe fe-arrow-right-circle"></span></a>
						</div>
						<table v-if="invitations.length" class="table table-sm table-nowrap table-hover card-table">
							<thead>
								<tr>
									<th>Person</th>
									<th class="shrink">Permissions</th>
									<th class="d-none d-md-table-cell">Sent</th>
									<th class="shrink"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="invitation of invitations">
									<td>
										<div v-if="invitation.user" class="row align-items-center">
											<div class="col-auto">
												<div class="avatar avatar-sm dropdown-toggle">
													<img :src="invitation.user.avatar" :alt="invitation.user.name" class="avatar-img rounded-circle">
												</div>
											</div>
											<div class="col">
												<div class="mb-0">{{invitation.user.name}}</div>
												<div class="text-muted small">{{invitation.user.email}}</div>
											</div>
										</div>
										<template v-else>
											{{invitation.nonUserEmail}}
										</template>
									</td>
									<td class="text-center">
										{{invitation.isAdmin ? "Admin" : "Non-admin"}}
									</td>
									<td class="d-none d-md-table-cell">{{invitation.sentOn}}</td>
									<td>
										<div class="dropdown">
											<button class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<i class="fe fe-more-vertical"></i>
											</button>
											<div class="dropdown-menu dropdown-menu-right">
												<form @submit.prevent="invitationResend(invitation.membershipId)">
													<button type="submit" class="dropdown-item text-primary" :disabled="!currentUser.isAdminOfTeam(team.id)">Re-send Invitation</button>
												</form>
												<form @submit.prevent="invitationRevoke(invitation.membershipId)">
													<button type="submit" class="dropdown-item text-danger" :disabled="!currentUser.isAdminOfTeam(team.id)">Revoke</button>
												</form>
											</div>
										</div>	
									</td>
								</tr>
							</tbody>
						</table>
						<div v-else class="card-body">
							<p class="small text-muted mb-0">There are no pending invitations.</p>
						</div>
					</section>

					<section class="card">
						<div class="card-header">
							<h4 class="card-header-title">Members</h4>
						</div>
						<table class="table table-sm table-nowrap table-hover card-table">
							<thead>
								<tr>
									<th>Person</th>
									<th class="shrink">Permissions</th>
									<th class="shrink"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="member of members">
									<td>
										<div class="row align-items-center">
											<div class="col-auto d-none d-sm-block">
												<div class="avatar avatar-sm">
													<img :src="member.user.avatar" :alt="member.user.name" class="avatar-img rounded-circle">
												</div>
											</div>
											<div class="col">
												<h4 class="mb-1">{{member.user.name}}</h4>
												<span class="text-muted d-none d-sm-block">Joined {{member.joinedOn}}</span>
											</div>
										</div>
									</td>
									<td class="text-center text-muted">
										{{member.isAdmin ? "Admin" : "Non-admin"}}
									</td>
									<td>
										<div class="dropdown">

											<button class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<i class="fe fe-more-vertical"></i>
											</button>

											<div class="dropdown-menu dropdown-menu-right">
												<a :href="`/teams/${team.path}/people/${member.user.id}`" class="dropdown-item"><span class="fe fe-eye"></span> Insights</a>
												<template v-if="!member.isAdmin && member.user.id != currentUser.id">
													<form @submit.prevent="makeAdmin(member.membershipId)">
														<button type="submit" class="dropdown-item text-primary"><span class="fe fe-user-plus"></span> Make admin</button>
													</form>
													<button type="button"  @click.prevent="removeMember(member.membershipId)" class="dropdown-item text-danger"><span class="fe fe-trash-2"></span> Remove</button>
												</template>
												<template v-else-if="members.filter(m => m.isAdmin).length > 1 && member.user.id != currentUser.id">
													<form @submit.prevent="makeParticipant(member.membershipId)">
														<button type="submit" class="dropdown-item text-primary"><span class="fe fe-user-minus"></span> Make non-admin</button>
													</form>
													<button type="button" @click.prevent="removeMember(member.membershipId)" class="dropdown-item text-danger"><span class="fe fe-trash-2"></span> Remove</button>
												</template>
												<template v-else>
													<button type="button" aria-title="You cannot demote the only team admin" class="dropdown-item disabled"><span class="fe fe-user-minus"></span> Make non-admin</button>
													<button type="button" aria-title="You cannot remove the only team admin" class="dropdown-item disabled"><span class="fe fe-trash-2"></span> Remove</button>
												</template>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</section>
				</div>
			</div>
		</main>
    </div>
</template>