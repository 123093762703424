<script>
import { ReviewPhase } from "../../../../../functions/domain/reviews/reviewPhase.js";
import DateTime from "../../../../../functions/domain/dateTime.js"
import { completenessColor } from "../../../../js/utils.js";

export default {
	data() {
		return {
			ReviewPhase,
			DateTime,
			teamPath: this.teamPath,
			teamState: this.teamState,
			review: this.review,
			reviewStats: this.reviewStats
		};
	},
	methods: {
		completenessColor
	},
	props: ['teamPath', 'teamState', 'review', 'reviewStats']
}

</script>
<template>
	<section class="card">
		<div class="card-header">
			<h4 class="card-header-title">Current review : {{ review.name }}</h4>
			<a :href="`/teams/${teamPath}/reviews/${review.id}/view`" class="btn btn-sm btn-outline-primary mr-2">View participation</a>
			<a :href="`/teams/${teamPath}/reviews/end-reflection-phase`" :class="`btn btn-sm ${review.reflectionPhaseEndsOn > DateTime.now() ? 'btn-white' : 'btn-primary'}`">Start iterating <span class="fe fe-arrow-right-circle"></span></a>
		</div>
		<div class="card-body">
			
			<p v-if="review.reflectionPhaseEndsOn > DateTime.now()" class="small">Team members and mentors have until <strong>{{ review.reflectionPhaseEndsOn }}</strong> to complete their self-reflections and provide feedback on them.</p>
			
			<div v-else>
				<p class="small">Team members and mentors should have completed their reflections and feedback by <strong>{{ review.reflectionPhaseEndsOn }}</strong>.</p>
				<p class="small">You should now end the reflection phase and move on to the iteration phase.</p>
			</div>
			
		<div class="row align-items-center">
			<div class="col-4">
				<div class="small mb-2">Started self-reflections</div>
				<div class="row align-items-center">
					<div class="col-auto">
						<div class="mr-2 small">{{ reviewStats.reflectionStartedness }}%</div>
					</div>
					<div class="col">
						<div :class="`progress progress-sm ${completenessColor(reviewStats.reflectionStartedness)}`">
							<div :class="`progress-bar progress-${reviewStats.reflectionStartedness}`"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-4">
				<div class="small mb-2">Completed self-reflections</div>
				<div class="row align-items-center">
					<div class="col-auto">
						<div class="mr-2 small">{{ reviewStats.reflectionSubmittedness }}%</div>
					</div>
					<div class="col">
						<div :class="`progress progress-sm ${completenessColor(reviewStats.reflectionSubmittedness)}`">
							<div :class="`progress-bar progress-${reviewStats.reflectionSubmittedness}`"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-4">
				<div class="small mb-2">Completed mentor feedback <span class="fe fe-info" aria-title="This % may fluctuate at the start of a new review period, as people update who their mentors are."></span></div>
				
						<div v-if="reviewStats.reflectionFeedbackness > 0" class="row align-items-center no-gutters">
							<div class="col-auto">
								<span class="mr-2 small text-muted">{{reviewStats.reflectionFeedbackness}}%</span>
							</div>
							<div class="col">
								<div :class="`progress progress-sm ${completenessColor(reviewStats.reflectionFeedbackness)}`">
									<div :class="`progress-bar progress-${reviewStats.reflectionFeedbackness}`"></div>
								</div>
							</div>
						</div>
						
						<div v-else class="row align-items-center">
							<div class="col-auto">
								<span class="small text-muted">N/A</span>
							</div>
						</div>
						
			</div>
		</div>
		</div>
	</section>
</template>