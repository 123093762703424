export default class UserRoleTopicLevelCriterion
{
	id = null
	text = null
	answer = null
	feedback = []
	iteratedOn = 0

	constructor(criterion)
	{
		this.id = criterion.id;
		this.text = criterion.text;
	}
}
