<script>
import DateTime from '../../../functions/domain/dateTime.js';

export default {
	data() {
		return { 
			task: this.task,
			DateTime
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<section class="card">
	<div class="card-header">
		<h4 class="card-header-title">Complete self-reflection questions</h4>
		<small :class="task.dueOn <= DateTime.now() ? 'text-danger' : 'text-muted'" :aria-title="`This task should be completed before ${task.dueOn.format('P')}`"><span class="fe fe-clock"></span> {{task.dueOn.format('MMMM do')}}</small>
		<a :href="`/reflections/${task.reflectionId}/answer/next`" class="btn btn-sm btn-primary">Answer next set of questions <span class="fe fe-arrow-right-circle"></span></a>
	</div>
	<div class="card-body">
		<div v-if="task.dueOn <= DateTime.now()" class="alert alert-warning small" role="alert">
			<span class="fe fe-alert-circle"></span> The submission deadline has passed, expect your team leader to come looking for you.<br />Get your answers in quick!
		</div>
		<p class="mb-0">You've still got some more questions to answer.<br />You need to complete all of the self-reflection questions before we can move to the next stage.</p>
	</div>
</section>
</template>