<script>

// Participant

import CompleteReflectionAnswersUserTask from '../../../functions/domain/users/userTasks/completeReflectionAnswersUserTask.js';
import CompleteReflectionAnswers from './completeReflectionAnswers.vue'

import ProvideFeedbackUserTask from '../../../functions/domain/users/userTasks/provideFeedbackUserTask.js';
import ProvideFeedback from './provideFeedback.vue'

import ReviewFeedbackUserTask from '../../../functions/domain/users/userTasks/reviewFeedbackUserTask.js';
import ReviewFeedback from './reviewFeedback.vue'

import StartIterating from './startIterating.vue'
import StartIteratingUserTask from '../../../functions/domain/users/userTasks/startIteratingUserTask.js';

import StartReflection from './startReflection.vue'
import StartReflectionUserTask from '../../../functions/domain/users/userTasks/startReflectionUserTask.js';

import SubmitReflectionAnswers from './submitReflectionAnswers.vue'
import SubmitReflectionAnswersUserTask from '../../../functions/domain/users/userTasks/submitReflectionAnswersUserTask.js';

import WaitForApplicationApproval from './waitForApplicationApproval.vue'
import WaitForApplicationApprovalUserTask from '../../../functions/domain/users/userTasks/waitForApplicationApprovalUserTask.js';

import WaitForIterating from './waitForIterating.vue'
import WaitForIteratingUserTask from '../../../functions/domain/users/userTasks/waitForIteratingUserTask.js';

import WaitForFeedback from './waitForFeedback.vue'
import WaitForFeedbackUserTask from '../../../functions/domain/users/userTasks/waitForFeedbackUserTask.js';

import WaitForReviewToStart from './waitForReviewToStart.vue'
import WaitForReviewToStartUserTask from '../../../functions/domain/users/userTasks/waitForReviewToStartUserTask.js';

export default {
	data() {
		return { 
			task: this.task,
			currentUser: this.currentUser,

			CompleteReflectionAnswersUserTask,
			ProvideFeedbackUserTask,
			ReviewFeedbackUserTask,
			StartIteratingUserTask,
			StartReflectionUserTask,
			SubmitReflectionAnswersUserTask,
			WaitForApplicationApprovalUserTask,
			WaitForIteratingUserTask,
			WaitForFeedbackUserTask,
			WaitForReviewToStartUserTask
		}
	},
	props: [ 'task', 'currentUser' ],
	components: {
		CompleteReflectionAnswers,
		ProvideFeedback,
		ReviewFeedback,
		StartIterating,
		StartReflection,
		SubmitReflectionAnswers,
		WaitForApplicationApproval,
		WaitForIterating,
		WaitForFeedback,
		WaitForReviewToStart
	}
}

</script>
<template>
	<CompleteReflectionAnswers v-if="(task instanceof CompleteReflectionAnswersUserTask)" :task="task" :currentUser="currentUser"></CompleteReflectionAnswers>
	<ProvideFeedback v-else-if="(task instanceof ProvideFeedbackUserTask)" :task="task" :currentUser="currentUser"></ProvideFeedback>
	<ReviewFeedback v-else-if="(task instanceof ReviewFeedbackUserTask)" :task="task" :currentUser="currentUser"></ReviewFeedback>
	<StartIterating v-else-if="(task instanceof StartIteratingUserTask)" :task="task" :currentUser="currentUser"></StartIterating>
	<StartReflection v-else-if="(task instanceof StartReflectionUserTask)" :task="task" :currentUser="currentUser"></StartReflection>
	<SubmitReflectionAnswers v-else-if="(task instanceof SubmitReflectionAnswersUserTask)" :task="task" :currentUser="currentUser"></SubmitReflectionAnswers>
	<WaitForApplicationApproval v-else-if="(task instanceof WaitForApplicationApprovalUserTask)" :task="task" :currentUser="currentUser"></WaitForApplicationApproval>
	<WaitForIterating v-else-if="(task instanceof WaitForIteratingUserTask)" :task="task" :currentUser="currentUser"></WaitForIterating>
	<WaitForFeedback v-else-if="(task instanceof WaitForFeedbackUserTask)" :task="task" :currentUser="currentUser"></WaitForFeedback>
	<WaitForReviewToStart v-else-if="(task instanceof WaitForReviewToStartUserTask)" :task="task" :currentUser="currentUser"></WaitForReviewToStart>
	<div v-else>
		Missing UserTask vue
	</div>
</template>