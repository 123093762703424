<script>
export const pageName = 'teams-reviews-endReflectionPhase';
export const pageRoute = '/teams/{path}/reviews/end-reflection-phase';
export const title = 'End self reflection phase'

import { getViewTeamByPath, getViewUser } from '../../../js/readModel.js';
import { functions } from '../../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import TopNav from '../topnav.vue'
import { getCurrentUserId } from '../../../js/auth.js';
import DateTime from '../../../../functions/domain/dateTime.js'

export default {
	data() {
		return {
			currentUser: null,
			team: null,

			reflectionPhaseEndsOn: 0,
			reflectionDurationDays: 0,
			iterationDurationDays: 0,
			endingEarly: false,

			ready: false
		}
	},

	methods: {
		submit: async function() {
			this.$refs.form.setSubmitting(true);

			await functions.reviewBeginIterating({ id: this.team.currentReview.id});
			
			this.$refs.form.setSubmitting(false);

			navigate('/teams/' + this.team.path)
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);
			
			this.reflectionPhaseEndsOn = this.team.currentReview.reflectionPhaseEndsOn;
			this.reflectionDurationDays = this.team.currentReview.reflectionDurationDays;
			this.iterationDurationDays = this.team.currentReview.iterationDurationDays;
			// TODO: handle incompleteness?
			this.endingEarly = this.team.currentReview.reflectionPhaseEndsOn.dateOnly() < DateTime.now().dateOnly()

			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}

</script>
<template>
	<main v-if="ready" class="container">
		<TopNav :team="team" :user="currentUser" current-page="reviews"></TopNav>
		<div class="row justify-content-center mb-4">
			<div class="col-12 col-md-8 col-lg-6 text-center">
				<h1>Let's get people improving&hellip;</h1>
				<p class="text-muted">We're ready if you are.</p>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12 col-md-8">
				<div v-if="endingEarly" class="alert alert-warning mb-5" role="alert">
					<h3><span class="fe fe-alert-circle mr-2"></span> It's a bit early for this&hellip;</h3>
					<p>Team members still have until <strong>{{reflectionPhaseEndsOn}}</strong> to complete their self-reflections and mentor-feedback, so you're trying to end it a bit early.</p>
					<p>They were originally told they had {{ reflectionDurationDays }} days to do their tasks, so they might feel short-changed if you end this phase early.<br /></p>
					<p class="mb-0">You can end this reflection phase now if you need to, but be aware of the potentially negative consequences for team morale and trust in this process if you don't communicate your reasons well.</p>
				</div>
				
				<p class="text-center">This will encourage team members to start iterating over their identified weaknesses, spending {{ iterationDurationDays }} days at a time on a few key aspects of themselves.</p>
				<p class="text-center">The iteration process will repeat until you schedule another review in a few months time.</p>
				<p class="mb-5 small text-center">People who have not yet completed their self-reflections or mentor-feedback will be encouraged to do so, they won't be prevented from continuing or miss out.</p>
				
				<form @submit.prevent="submit" ref="form">
					<button type="submit" class="btn btn-lg btn-block btn-primary">Start iterating <span class="fe fe-check-circle"></span></button>
				</form>
			</div>
		</div>


		</main>
</template>