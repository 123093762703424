<script>

export default {
	data() {
		return { 
			task: this.task
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<section class="card">
	<div class="card-header">
		<h4 class="card-header-title">Wait for the next phase of the review</h4>
		<small class="text-muted" :aria-title="`This task should be completed before ${task.dueOn.format('P')}`"><span class="fe fe-clock"></span> {{task.dueOn.format('MMMM do')}}</small>
	</div>
	<div class="card-body">
		<p>It's time to chill out for a bit while the rest of your team get their self-reflections submitted.</p>
		<p>After that, your team leader will move the review process on to the next phase - iterating - where you'll work on improving and developing yourself.</p>
		<p class="mb-0">We'll let you know when there's a new action for you, it should happen around {{task.dueOn.format('MMMM do')}}.</p>
	</div>
</section>
</template>