<script>
export const pageName = 'teams-reviews';
export const pageRoute = '/teams/{path}/reviews';
export const title = 'Reviews'

import { getCurrentUserId } from '../../../js/auth.js';
import { getReviewStats, getViewTeamByPath, getViewUser } from '../../../js/readModel.js';
import { functions } from '../../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import TopNav from '../topnav.vue'
import { format as formatDate } from 'date-fns'; 
import { TeamState } from '../../../../functions/domain/teams/teamState.js';
import Progress from './progress.vue'
import CurrentReview from './common/currentReview.vue';

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			TeamState: TeamState,
			reviewStats: null,
			reviews: [],
			currentReview: null,
			formatDate,
			ready: false
		}
	},

	methods: {
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);
			
			await functions.teamApply({ teamId: this.team.id });
			
			this.$refs.form.setSubmitting(false);

			navigate('/')
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);
			this.reviews = this.team.reviews.filter(r => r.id != this.team.currentReview?.id);
			this.currentReview = this.team.currentReview;
			if(this.team.currentReview)
				this.reviewStats = await getReviewStats(this.team.currentReview.id)
			this.ready = true;
		}
	},
	components: { TopNav, Progress, CurrentReview },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser" current-page="reviews"></TopNav>
		<section class="container">
			<div class="row">
				<div class="col12 col-xl-4 order-xl-last">

					<Progress :review="currentReview"></Progress>
					<div class="card">
						<div class="card-body">
							<div class="list-group list-group-flush my-n3">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Iteration duration
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{ team.iterationDurationDays }} days
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												First review cycle
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{formatDate(new Date(2020, team.reviewMonth0 - 1, 1), 'MMMM')}}
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Second review cycle
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{formatDate(new Date(2020, team.reviewMonth1 - 1, 1), 'MMMM')}}
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Third review cycle
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{formatDate(new Date(2020, team.reviewMonth2 - 1, 1), 'MMMM')}}
											</small>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Fourth review cycle
											</h5>
										</div>
										<div class="col-auto">
											<small class="text-muted">
												{{formatDate(new Date(2020, team.reviewMonth3 - 1, 1), 'MMMM')}}
											</small>
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
				<div class="col-12 col-xl-8">
					
					<div v-if="team.state != TeamState.Complete" class="alert alert-warning" role="alert">
						<h4><span class="fe fe-info"></span> Finnish team setup</h4>
						You have not finished <a :href="`/teams/${team.path}`">setting up your team</a>. You need to do that before you can start your first review.
					</div>

					<CurrentReview :teamPath="team.path" :teamState="team.state" :review="currentReview" :reviewStats="reviewStats"></CurrentReview>
					<section class="card">
						<div class="card-header">
							<h4 class="card-header-title">Past reviews</h4>
						</div>
						<table v-if="reviews.length" class="table table-sm table-nowrap table-hover card-table">
							<thead>
								<tr>
									<th>Name</th>
									<th>Reflecting Started</th>
									<th>Iterating Started</th>
									<th class="shrink">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="review in reviews">
									<td>{{review.name}}</td>
									<td>{{review.startedOn}}</td>
									<td>{{review.startedIteratingOn}}</td>
									<td>
										<a :href="`/teams/${team.path}/reviews/${review.id}/view`" class="btn btn-sm btn-outline-primary">View participation</a>
									</td>
								</tr>
							</tbody>
						</table>
						<div v-else class="card-body">
							<p class="small mb-0">Your team doesn't yet have any past reviews.</p>
						</div>
					</section>
				</div>
			</div>
		</section>
	</div>
</template>