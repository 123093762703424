<script>

export default {
	data() {
		return { 
			task: this.task
		}
	},
	props: [ 'task' ]
}

</script>
<template>
<div class="card">
    <div class="card-header">
        <h4 class="card-header-title">Applications pending</h4>
        Now
        <a :href="`/teams/${task.teamPath}/people`" class="btn btn-sm btn-primary">Manage applications <span class="fe fe-arrow-right-circle"></span></a>
    </div>
    <div class="card-body">
        <p class="mb-0">You have people waiting to join your team, go and let them in!</p>
    </div>
</div>
</template>