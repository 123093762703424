import { average } from "../../math.js"
import reflectionState from "./reflectionState.js"
import Stats from "./stats.js"

export default class ReflectionView
{
	id = null
	userId = null
	createdOn = null
	name = null
	roleId = null
	levelId = null
	state = reflectionState.Unsubmitted
	answers = {}
	feedback = []
	competencyTopicStats = {}
	currentIterationIndex = -1
	iterations = []
	dueOn = null

	get stats()
	{
		let stats = Object.values(this.competencyTopicStats).map(x => Object.values(x)).reduce((p, c) => p.concat(c), []);
		if(!stats.length)
			return Stats.Zero;

		let retval = new Stats();
		retval.completeness = average(stats, s => s.completeness)
		retval.rating = average(stats, s => s.rating)
		retval.feedback = average(stats, s => s.feedback)
		
		return retval;
	}

	markFeedbackAsReviewed(mentorId) {
		let feedback = this.feedback.find(f => f.mentorId == mentorId);
		feedback.reviewed = true;
	}

	static Feedback = class {
		mentorId = null
		mentorName = null
		answers = {}
		changedAnswers = {}
		providedOn = null
		reviewed = false
	}

	static Iteration = class {
		id = null
		competencyId = null
		topicId = null
		startedOn = null
		endsOn = null
		criteria = []

		applyResults(effort, improvement){
			for(let criterion of this.criteria)
			{
				if(effort[criterion.id])
					criterion.effort = effort[criterion.id]

				if(improvement[criterion.id])
				criterion.improvement = improvement[criterion.id]
			}
		}

		static Criterion = class {
			id = null
			effort = 0
			improvement = 0
		}
	}
}