export default {
  "id": "13D21F5D-59A4-459C-9DC3-7E76E7805C54",
	"title": "🛠️ Engineering",
	"path": "engineering-competencies",
	"theme": "excitement",
	"summary": "The Engineering team are responsible for building and maintaining the solutions that members and partners use. Each Engineering role also has a specific set of role competencies, unique to each area of specialisation. Utilise this information to understand current skill levels, as well as identifying areas for development.",
	"topics": [
    {
      "id": "6764B15A-0006-4BD1-9B22-96E5187394D0",
			"title": "📋 Testing",
			"path": "testing",
			"theme": "excitement",
			"summary": "Test Engineers are people with an analytical and logical mindset, who have an eye for detail and care about waste. The first step in a Test Engineers career is performing manual testing. They will build test plans, test scripts and discover bugs. Advancement is found through automation and continuous improvement. A Test Engineer will coach quality to those around them, calling out when standards are not met.",
			"levels": [
				{
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "",
					"criteria": [
            {
              "id": "47C1C27F-FEAA-4933-957A-BFCE04EDAA95",
              "text": "Able to follow test scripts and understand how to execute them",
              "clues": [
                "You need not start with some formalised, strict definition of a \"test script\". Anecdotal requirements from a client or product owner will do. Search out requirements and test functionality in accordance with them. What opportunites exist for you to turn weakly defined testing criteria into better defined, more clearly written or widely distributed fomalised acceptance criteria?",
                "Its important to understand the purpose for tests. Not just in general but also specifically each one you execute. \"When I press the button the light should turn on\" is fine, but understanding the why, the purpose, the reason will enable you to better execute the test. In seeking understanding for the Why, you will almost certainly discover new requiremnts and critera which would otherwise be overlooked. Maybe the light needs to come on quickly, or should be a certain colour or luminosity. Find instances where you can ",
                "Your ability to execute a test will require product knowledge and probably technical knowledge. Seek both as deeply as possible. Do you understand the feature you are testing, or are you just mindlessly following instructions? The more knowledge every team member has for a product the better its quality will be."
              ]
            },
            {
              "id": "C168185B-DB4F-4BAE-96D5-F22F8F0C64E3",
              "text": "Takes pride in intellectual curiosity",
              "clues": [
                "It should frustrate you when issues, opportunities and problems are not being fully understood. The next time you (or you see someone else) shrug off a problem with a \"Huh, who knows?\", stop. Mean Time To Detection is a critical Key Performance Indictator for monitoring teams for a reason. There is huge value in finding problems as soon as possible. You can't solve a problem until you know it exists. Investigating the issue you've just found now, is paramount. If you don't do it now, someone has to repeat the time you've spent coming across it and investigate and ponder it all over again. This is not only a waste of that resource, but also potential negative effects will continue to happen, incurring cost or increasing risk."
              ]
            },
            {
              "id": "9246BBAF-6C70-4E02-8325-687914875B9B",
              "text": "Basic understanding of project-specific technologies",
              "clues": [
                "You need to know what you're dealing with and how it works. Unfortunately, one of the fastest ways to alienate yourself from colleages and clients is to not understand what you're really doing. This shouldn't be intimidating however, not knowing something is absolutely fine. Not asking for help or researching youself however, is not.",
                "If you are suffering with low self-confidence or imposter syndrome, we want to help you with that, and following this guidance will definitely increase both your abilities and confidence. It will take more than a few paragraphs here to fully overcome it though. For now, start with this thought experiment: your low self-confidence or belief that you are an imposter are your thoughts about yourself. Separately from that is your actual ability, and separate from that is other people's perception of you and your abilities. Moreover, your thoughts about yourself are also concerned with others' perception of you. So what can we do about all of these? Enthusiasm. Enthusiasm will get you a very long way. Everyone has empathy for the enthusiasic, so embody this. It's extremely hard to have negative views of a person who is clearly trying hard to learn and improve themselves. Unfortunately cyncis and trolls exist, so be prepared to encounter them. And ignore them.",
                "Try to identify why you don't know the topics in enough details. There is no wrong or bad answer and nothing to be ashamed of, but you need to be honest with yourself in order to take the first step to improvemenet.",
                "Is it simply that you need to dedicate some time to researching it? Put time in your diary right now. Don't be frugal either, the value gained from you truly understanding key concepts will immediately pay for itself against the time you spend swotting up. If you are unfortunate enough for this not to be appreciated by your seniors, you're going to have to make your own case studies. Take a bit of your own time to ivest in yourself. Keep some stats on time taken learning vs value gained in quality/speed of testing and show it to your seniors. If nothing else this will be excellent practice for other case-making skills.",
                "Do you need help form colleagues? Make time, speak to them, explain to them how them helping you also helps them. You understanding something you currently dont"
              ]
            },
            {
              "id": "E28A7317-B978-4658-B80D-32BB5F9E88D4",
              "text": "Understands that a Tester is complementary to a Developer and not a gatekeeper/examiner",
              "clues":[
                "Of course there are cases where formal sign-off and gatekeep are required, but thats not what we're talking about here. For every other activity and part of your daily role and interaction with the rest of the team, you should be an assistance. If you find yourself gatekeeping, perhaps because quality is low and bugs are prevelant, dig. What's the cause of this? Seek to quantify and qualify the reasons.","If you were instructed to stop acting as a gatekeeper, what would your counter argument be and why? Perhaps something like \"If we do that there will be lots more bugs in production\". Analyse why that would be, would there be a pattern to that? Bugs of a certain type, or in a certain feature, or created by a certain team? Gatekeeping behaviour is a highly inefficient response to underlying problems. Follow the rabbit hole and get to the underlying issues. By solving those, you need never even have the discussion about whether gatekeeping is good or bad, because quality will have risen sufficiently that many of the pro-gatekeeping arguments will be void."
              ]
            },
            {
              "id": "C2825256-286D-4A4B-AB9B-08274A694E3B",
              "text":
                "Appreciates the importance of clear and concise communication in describing issues and their reproduction steps",
                "clues": [
                  "There is an entire topic on communication, but here we specifically refer to the communication of issue reproduction steps.",
                  "If you put a swear jar on your desk and inserted a pound/euro/dollar for every time you either said or heard said: \"It doesn't work\" or \"It's broken\", how quickly would it fill up? Banish these not just useless but worse, wasteful unprofessionalisms. We're not going to teach you how to file bugs here, go and Google it. What you need to do is appreciate how important clear and precise communication is, especailly with issue-reproduction instructions.",
                  "Remember that your communication will likely be read by someone (often future you)) after you've forgotten the details of it. The next time you find yourself writing something brief and thinking you know what it means, stop yourself. Help both future you and your colleagues. Apply self discipline, it will soon become a habit."
                ]
            },
            {
              "id": "B1FF869C-4A1E-4F27-8806-89475ADE85F4",
              "text": "Appreciates the importance of Accessibility considerations and can test for them",
              "clues": [
                "Inclusivity and accessibility are both a moral and legal obligation. Subscribe yourself to both of those reasons.",
                "For a team completely unfamiliar with making accessibility considerations, taking them on can be an expensive and often-considered distracting endeavour. However there are both strategies for dealing with this, and the legal costs of not being legally compliant are almost certainly higher.",
                "There are myriad resources on "
              ]
            },
            {
              "id": "D1141554-A4B0-4282-96D4-9FAFAFEA15FD",
              "text":
                "Well practiced in the use of relevant end-user technologies, e.g. Browsers, Android, iOS, Windows, Mac and understand their differences in paradigms"
            }
          ]
				},
				{
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "",
					"criteria": [
            {
              "id": "7CCC55F0-BF52-472C-B261-0B015ECEF5F3",
              "text": "Have a good working knowledge of all relevant aspects of the subject under test"
            },
            {
              "id": "FF716C0E-0A3A-4D2F-B378-C4C79394B210",
              "text": "Can author and maintain automated tests"
            },
            {
              "id": "E2A11028-7DCE-4440-B4F3-B054CC201876",
              "text": "Understands and applies the different types of tests"
            },
            {
              "id": "DFB73DAA-7679-4DA5-8A71-AA6720B61480",
              "text": "Enjoys the challenge of exploratory testing to find oversights and obscurities"
            },
            {
              "id": "51CB1254-656D-48B3-8F52-3CD2A5390A33",
              "text": "Ensures testing is considered during all stages of development"
            },
            {
              "id": "4F8FA11B-F097-4787-9487-81D248B6FB81",
              "text": "Holds the team accountable for quality and not individuals"
            },
            {
              "id": "D78BB039-1C3D-4DC1-97B4-898B3DD5907A",
              "text":
                "Has self-confidence to speak out and promote risk mitigation and test considerations as appropriate during design/development"
            }
          ]
				},
				{
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "",
					"criteria": [
            {
              "id": "8CB445F6-7515-4F03-9AE8-0F5F91223782",
              "text": "Understands risk models and can apply them as part of the software design process"
            },
            {
              "id": "6AD2AE95-305E-4B51-9BBE-E10E5D33D465",
              "text": "Actively seeks to minimise system complexity in order to simplify quality control"
            },
            {
              "id": "A81196B6-3DA6-4EEF-A081-3CA84C85EC7A",
              "text":
                "Champions and takes ownership of quality within the team. Works with the team to improve quality at every opportunity"
            },
            {
              "id": "C2AA950E-52BE-4B26-A623-E07D1922314B",
              "text": "Promotes a quality mindset within all members of the team"
            },
            {
              "id": "3DB85D0B-38FE-42EB-8627-76C44C8F9EB4",
              "text": "Understands and can reason where the value lies in automated tests"
            },
            {
              "id": "1C6AC0BD-B525-467F-9937-D29823A146C3",
              "text":
                "Can reason about signal-to-noise levels within tests and coaches others to strive for a high level"
            },
            {
              "id": "7F5676BE-8A7B-47DC-9A51-44BEE7DD102D",
              "text": "Actively identifies opportunities for automation and sees it implemented"
            }
          ]
				},
				{
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "",
					"criteria": [
            {
              "id": "C93FE337-268A-44C9-A31E-02F6EE62482C",
              "text": "Operates primarily as a coach and not an executor of testing"
            },
            {
              "id": "985226BB-F963-42DE-AC49-204BB1536B32",
              "text": "Actively coaches team and helps them become Level 4 Testers in their own right"
            },
            {
              "id": "B0790676-9CE5-4958-A36C-CCC025D1BFD9",
              "text": "Actively strives to push testing as early as possible within the development process"
            },
            {
              "id": "673A0AA6-DE8D-40B2-81BB-FE695332DF12",
              "text":
                "Understands that the maximum value of a Tester comes from issue-prevention and Developer education and actively seeks to implement this"
            },
            {
              "id": "6F3AD259-C51B-4B09-BCDE-E5F2C3C45FA6",
              "text":
                "Monitors and maintains statistics on types of issue, and feeds back common occurrences to the team to facilitate education/process change"
            }
          ]
				}
			]
		},
		{
      "id": "B87FEB2E-447C-4288-8157-374AF9D4DF15",
			"title": "🎀 Front End Engineering",
			"path": "front-end-engineering",
			"theme": "excitement",
			"summary": "Front End Engineers start their career with a knowledge of how to build basic web pages. As they advance, so will their knowledge of HTML, CSS and JavaScript. As technical skill develops, understanding of quality and delivery of value will also improve. A senior Front End Engineer will take responsibility for the web estate, seeking continuous improvement and setting out best practices for the rest of the Engineering team.            ",
			"levels": [
				{
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "",
					"criteria": [
            {
              "id": "BE328D84-043E-446F-803D-E4AD24A160F3",
              "text": "Good knowledge of semantics of HTML elements"
            },
            {
              "id": "21EE741B-5E81-4339-A22F-306CB6BC3874",
              "text": "Understands the importance of cross-browser/device testing"
            },
            {
              "id": "6ED6A8D6-D98B-47EB-9396-8EC4EBB11DD8",
              "text": "Good knowledge of CSS selectors, their specificity and precedence rules and how they cascade"
            },
            {
              "id": "86D74685-88FD-45E9-ADBD-A9FA681EC96F",
              "text": "Good knowledge of all CSS properties and their values"
            },
            {
              "id": "5A988D53-3342-4CF6-B4AA-CCF59027B77D",
              "text": "Understands basics of HTTP 1.1"
            },
            {
              "id": "544A523A-B94A-4AF8-8D6C-87EE274D775A",
              "text": "Good working knowledge of Javascript syntax"
            },
            {
              "id": "7945F7F4-D08E-4D40-A574-D24A17385A6C",
              "text": "Appreciates the importance of Accessibility considerations"
            },
            {
              "id": "41CC4A4F-F13A-439D-B127-79BF15EA4287",
              "text": "Competent user of IDEs, both GUIs and command line"
            }
          ]
				},
				{
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "",
					"criteria": [
            {
              "id": "06325DBE-159C-4DB0-B261-E1E78B154D47",
              "text":
                "Appreciation for Responsive Design, its pros/cons and can make reasoned arguments for its applicability"
            },
            {
              "id": "DB745758-F758-4CC4-A6E2-851E7119E707",
              "text": "Proactively identifies browser/device support requirements and can implement against them"
            },
            {
              "id": "17111020-8B77-4146-996B-F26643AE0962",
              "text": "Demonstrable ability to apply more advanced CSS techniques such as FlexBox"
            },
            {
              "id": "41352252-109D-4833-A2CF-53F95C06B2ED",
              "text": "Appreciates different CSS authoring practices and can argue their pros/cons"
            },
            {
              "id": "EFAA61B9-17F7-474F-AD34-A00B51CFAEA2",
              "text": "Understanding of HTTP 1.1 vs 2.x and how to leverage support to build performant applications"
            },
            {
              "id": "CACC9895-95DC-4C3B-8536-F0F19085A182",
              "text": "Experienced with supportive Javascript libraries and can justify their use"
            },
            {
              "id": "CA854345-DC62-45AF-93F5-6A71033506CD",
              "text": "Good understanding of Aria and function/operation of assistive technologies and their support"
            },
            {
              "id": "F7FA571D-F300-4BD9-949E-068FB86937D4",
              "text": "Appreciates graceful degradation and progressive enhancement"
            }
          ]
				},
				{
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "",
					"criteria": [
            {
              "id": "01FA00FB-C3AF-4DB2-BBE7-C0D14478FD7C",
              "text": "Good understanding of DOM eventing and familiar with all modern HTML APIs"
            },
            {
              "id": "C9B96CC7-2AF8-441D-9B7A-AEE4FE4E3A68",
              "text": "Able to write and debug Javascript tests"
            },
            {
              "id": "E1026BBA-F449-481F-9F0C-E7A87A2F3FA3",
              "text": "Proactively establishes and regulates performance budgets"
            },
            {
              "id": "8117AC9A-0A1A-4B5A-AD9C-0D1A68E0474A",
              "text": "Practiced in advanced Javascript syntax such as Prototypes"
            },
            {
              "id": "0369B2ED-7C60-46A8-A9BD-861ECA483F9C",
              "text": "Solid understanding of HTTP and Browser caching strategies and implementations."
            },
            {
              "id": "72B165DE-DC07-424F-AC20-20E51CEAD1C8",
              "text": "Good understanding of CDNs and network latency effects"
            },
            {
              "id": "FAC8B99A-611C-43C1-9276-619FF33B2F05",
              "text":
                "Proactively and ethically identifies a11y needs of users and makes conscious efforts to support them"
            },
            {
              "id": "E58C0686-94E0-4106-99F0-903FC2BEB375",
              "text": "Understands software engineering principles such as SoC and SOLID"
            }
          ]
				},
				{
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "",
					"criteria": [
            {
              "id": "E37C043B-B275-4069-9C3F-799681F853A4",
              "text": "Able to contribute improvements to build/bundling/compression processes"
            },
            {
              "id": "AB6E8325-7868-45BB-950A-FC7F7B93BC47",
              "text":
                "Champions good Front End Engineering practices within the wider team and coaches those with lesser knowledge"
            },
            {
              "id": "7A03F921-00C2-417D-925E-A6739A047294",
              "text":
                "Appreciates and can apply different software architecture patterns, such as event-driven, message busses, MVC."
            },
            {
              "id": "35EB1CE0-ED91-4B92-B5C8-DF80FE35BD6B",
              "text": "Understands software coupling and cohesion and how it applies to front end development"
            },
            {
              "id": "665E4415-29D2-4549-91BF-93D448010526",
              "text":
                "Good understanding of wireless networking including WIFI and Gs and how it applies to internet performance"
            },
            {
              "id": "C21CBFEB-8AD5-4BC2-AA62-F9CF90A6D49F",
              "text": "Understands the browser rendering process and how to diagnose performance issues"
            }
          ]
				}
			]
		},
		{
      "id": "AAB62E05-FF14-49F2-8DC1-51ACA6FAD163",
			"title": "🔧 Software Engineering",
			"path": "software-engineering",
			"theme": "excitement",
			"summary": "A Software Engineer starts their career with a base level of understanding of software programming. A newbie should be able to build something from scratch and know how to integrate changes into existing software. As a Software Engineer’s career progresses skills such as database knowledge, architecture and scalability will begin to be considerations, as will UX and customer experience (where appropriate). A Software Engineer should work hard to stay up to date with industry standards and emerging technologies. When a Software Engineer becomes senior, behaviours like coaching others, mentoring and architectural design will be expected. ",
			"levels": [
				{
					"title": "👩‍🎓 Level 1",
					"path": "1",
					"summary": "Capable",
					"criteria": [
            {
              "id": "BAEDC240-F888-4D32-BED4-5D5FC97E102E",
              "text": "Understands the importance of version control and its best practices"
            },
            {
              "id": "02E1EDCB-0CBF-4943-B539-4C13AD9CFFAF",
              "text": "Actively participates in and welcomes critical code review"
            },
            {
              "id": "9C8058CC-5C9C-48B9-8632-3690EE8FED71",
              "text": "Self motivated and able to identify knowledge gaps"
            },
            {
              "id": "846281AB-8FF4-4701-AF6A-1986EC814CC3",
              "text": "Abides by coding standards"
            },
            {
              "id": "320F2E3E-1B49-49BF-944D-FDB298DBBFAD",
              "text": "Has a good working knowledge of relevant programming languages"
            },
            {
              "id": "E320AE78-28D5-4960-8D66-671F22E72C20",
              "text": "Learns to write correct code, following guidance and training materials"
            },
            {
              "id": "223116B4-C42A-4F3A-99F3-C5E56CAC7D9D",
              "text": "Adheres to delivery/deployment practices"
            },
            {
              "id": "DB1F95D1-54F7-4BC3-AA75-702C0E322760",
              "text": "Helps the team, does what needs doing"
            },
            {
              "id": "CD673564-C8EF-4B81-BF4F-7F7D146C282C",
              "text": "Seeks guidance from other engineers, rather than answers"
            },
            {
              "id": "3C134DB5-BBC8-4B15-89B6-0FCB03A425CF",
              "text": "Delivers assigned tasks, working with a more senior team member"
            },
            {
              "id": "5194C227-3353-4E07-B4B0-8690B1291903",
              "text": "Improves documentation that is incorrect"
            },
            {
              "id": "37B4059D-6975-4DF8-96A7-ED6496221CAA",
              "text": "Actively participates in Retrospective and other team ceremonies"
            },
            {
              "id": "986D32E7-66E3-4490-87FF-F14CA6AC838E",
              "text": "Fixes simple bugs"
            }
          ]
				},
				{
					"title": "👨‍🔧 Level 2",
					"path": "2",
					"summary": "Develops technical solutions",
					"criteria": [
            {
              "id": "652384A2-E2C0-4DB2-81F1-DA3D95D9AD2E",
              "text": "Appreciates and advocates the reasoning for coding standards"
            },
            {
              "id": "D5287953-D31D-4E15-A52D-BD386153AA72",
              "text": "Able to deliver most tasks without significant assistance"
            },
            {
              "id": "713040A5-D0A1-4C40-86A0-50F582C33026",
              "text": "Has a good working knowledge of relevant areas of codebase and its dependencies"
            },
            {
              "id": "D738F71D-AEF3-4653-B095-5FF188F675EF",
              "text": "Submits PRs to OSS/internal dependency projects to improve functionality/fix bugs"
            },
            {
              "id": "C1910BEE-EF8A-406A-9B95-7E266E826222",
              "text": "Understands and recognises common coding patterns and understands their applicability"
            },
            {
              "id": "53998C1B-63C5-4D7C-8538-FE8A37605C21",
              "text": "Able to dissect and debug existing code"
            },
            {
              "id": "9E1EE383-1612-4F4D-9847-E8DB5224463E",
              "text":
                "Familiar with and practices decompiling binaries and finding external sources to improve understanding"
            },
            {
              "id": "F996F35C-3A28-4493-AC7F-5BA261C276E1",
              "text":
                "Manages their own time effectively, prioritises their workload well, on time for meetings, aware when blocking others and unblocks"
            },
            {
              "id": "CA5B1CA2-1EC5-43EC-8C95-E66C0320F810",
              "text": "Competent at dividing problems into iterative steps"
            },
            {
              "id": "1ABACBC4-51AA-4843-9416-197C278FC16D",
              "text": "Fixes bugs involving a number of services and can investigate production issues"
            },
            {
              "id": "6A5BC94E-5562-4329-87EE-B30A299F0B3A",
              "text": "Uses appropriate algorithms and data structures to solve problems"
            },
            {
              "id": "E1480D7E-62BB-4137-B091-AAAB39E371DE",
              "text":
                "Aware of relevant Computer Science topics such as concurrency, efficient CPU usage, caching and naming"
            },
            {
              "id": "455C0253-D202-4F8A-961E-C8BE313AC271",
              "text": "Writes automated unit and acceptance tests"
            }
          ]
				},
				{
					"title": "👩‍🏫 Level 3",
					"path": "3",
					"summary": "Experienced",
					"criteria": [
            {
              "id": "8C813C6A-2979-4431-8486-74B5255FD551",
              "text": "Champions the team, focuses on tasks that contribute to team goals"
            },
            {
              "id": "3022F2C4-5ABA-48DA-835B-761A21765051",
              "text": "Sets and upholds QA standards"
            },
            {
              "id": "A14B1536-ABA8-4B1C-9926-8AA8C38816E5",
              "text": "Identifies patterns of weakness within the team, and collaborates to provide solutions"
            },
            {
              "id": "E440D56A-A3EA-469F-951F-F10D79D9DA8E",
              "text": "Able to translate functional requirements into technical ones"
            },
            {
              "id": "0B4D44EB-45FD-47A8-95C8-3AED3B8F5A3D",
              "text": "Ensures that everything shipped meets users needs."
            },
            {
              "id": "FB9CD96B-B138-40BC-BABB-23EE1CC25B2B",
              "text":
                "Understands and appreciates the level of fidelity needed for different iterations of a features development"
            },
            {
              "id": "1EDC0EC5-438D-426D-B428-C352D8E58BAA",
              "text": "Escalates and delegates appropriately"
            }
          ]
				},
				{
					"title": "🧙 Level 4",
					"path": "4",
					"summary": "Virtuoso",
					"criteria": [
            {
              "id": "453027A1-D512-47F2-8BD2-C2F772613AA3",
              "text": "Delivers most value by effectively working through others"
            },
            {
              "id": "7D5FE3AE-5015-4741-BDF7-CC35A6FA99AF",
              "text":
                "Sets squad rhythm and routines - facilitates agreement on how they all work together to discover, define and ship features or experiments to meet their goals"
            },
            {
              "id": "689A4BD2-9250-494B-B467-D49406233542",
              "text":
                "Works collaboratively to get the best out of all of the people in their squad, involving all disciplines throughout"
            },
            {
              "id": "77B63D37-79B6-4571-8CB1-44680D632CDE",
              "text": "Takes proactive steps to build and maintain team health, e.g. resolve conflict, counter inertia"
            },
            {
              "id": "AE79BAD5-2BCD-4348-B895-2B60BCD0F91E",
              "text":
                "Acts as technical point person for the squad - does whatever is needed to unblock or support the squad and their delivery"
            },
            {
              "id": "91508E32-52D5-4A54-9DDD-04BC22B72BBC",
              "text": "Ensures the squad has the skills and resources it needs to ship autonomously"
            },
            {
              "id": "B5483CA0-FF3D-4109-9C5F-E901CB519232",
              "text":
                "Servant leader who empowers the squad to achieve more and be ambitious (test sooner, generate more ideas, think broadly), valuing outcome over output"
            },
            {
              "id": "FA0E8F2B-F0D1-43EB-8017-F1F2E32CFAAE",
              "text":
                "Ensures the squad are aware of what other squads are working on and how that relates to their squad’s goals"
            },
            {
              "id": "F9C1E0AA-4F09-453E-B83C-0B820D8C31FE",
              "text": "Has an ability to craft clear and compelling technical visions"
            },
            {
              "id": "D1493756-4610-44F1-B3D4-2E5F44F7B86B",
              "text": "Be responsible for the quality of technical deliverables"
            },
            {
              "id": "1615E935-9805-4B46-A7C0-5B93A9532E94",
              "text": "Act as the interface between Product and Engineering"
            }
          ]
				}
			]
		}
	]
}
