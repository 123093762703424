import UserRoleTopic from "./userRoleTopic.js"

export default class UserRoleCompentency
{
	id = null
	title = null
	path = null
	summary = null
	
	topics = []

	get allRequiredCriteria()
	{
		return this.topics.map(c => c.allRequiredCriteria).reduce((prev, curr) => prev.concat(curr), []);
	}

	get adviceRating()
	{
		let ratings = this.topics.map(t => t.answerRating);
		let sum = ratings.reduce((prev, curr) => prev + curr, 0.0)
		return sum * 100.0 / ratings.length
	}

	constructor(competency)
	{
		this.id = competency.id;
		this.title = competency.title;
		this.path = competency.path;
		this.summary = competency.summary;
		
		this.topics = competency.topics.map(t => new UserRoleTopic(t))
	}
}