import { v4 as uuid } from "uuid"
import DateTime from "../../dateTime.js"

export class UserTask
{
	id = null
	userId = null
	createdOn = null
	appliesFrom = null
	remindOn = null
	remindedCount = 0
	dueOn = null
	get order() { return 0; }

	get summary() { return 'Task needs completing'; }

	constructor()
	{
		this.id = uuid();
		this.createdOn = DateTime.now()
	}

	getNextRemindOn(previouslyRemindedOn)
	{
		if(this.remindedCount >= 3)
			return null;

		return previouslyRemindedOn.addBizDays(2);
	}
}

export class AdminTask extends UserTask
{
	// DB search optimisation
	_isAdmin = true
}