import RoleCompetency from './roleCompetency.js';
import RoleTopicLevel from './roleTopicLevel.js';
import RoleTopic from './roleTopic.js';

import coreCompetencies from './core-competencies.js'
import engineeringCompetencies from './engineering-competencies.js'
import engineeringDept from './engineering-dept.js'
import operationsDept from './operations-dept.js'
import designDept from './design-dept.js';

function buildRoles(department)
{
	let competencies = allCompetencies();

	for(var c of competencies)
	{
		// TODO: move to a proper datatype
		c.getTopicById = function(id){
			return this.topics.find(t => t.id == id);
		}

		// TODO: move to a proper datatype
		c.getTopicByPath = function(path){
			return this.topics.find(t => t.path == path);
		}

		for(var t of c.topics)
		{
			// TODO: move to a proper datatype
			t.getLevelByPath = function(path){
				return this.levels.find(l => l.path == path);
			}

			for(var l of t.levels)
			{
				for(var criterion of l.criteria)
				{
					if(!criterion.clues)
						criterion.clues = [];
				}
			}
		}
	}

	for(var role of department.roles)
	{
		// TODO: move to a proper datatype
		role.getLevelById = function(id) {
			return this.levels.find(l => l.id == id)
		}
		// TODO: move to a proper datatype
		role.getLevelByPath = function(path) {
			return this.levels.find(l => l.path == path)
		}

		for(var level of role.levels)
		{
			// populate empty competencies
			level.competencies.competencies = []
			// TODO: move to a proper datatype
			level.competencies.getCompetencyById = function(id) {
				return this.competencies.find(c => c.id == id);
			}
			level.competencies.getCompetencyByPath = function(path) {
				return this.competencies.find(c => c.path == path);
			}
			level.competencies.getHighestRequiredTopicLevel = function(topicId) {
				return this.competencies.flatMap(c => c.topics).find(t => t.id == topicId)?.levels.findLast(l => l.requiredByRole);
			}

			let requiredLevels = level.competencies.required.map(p => ({
				path: p,
				required: true
			}));
			let optionalLevels = (level.competencies.recommended ?? []).map(p => ({
				path: p,
				required: true
			}));

			let roleLevels = requiredLevels.concat(optionalLevels);
			roleLevels.sort();
			
			let currentCompetency = null;
			let currentCompetencyTopics = [];

			for(var roleLevel of roleLevels)
			{
				let parts = roleLevel.path.split('/');

				let sourceComp = competencies.find(c => c.path == parts[0]);

				if(parts[0] != currentCompetency?.path)
				{
					currentCompetencyTopics = [];
					currentCompetency = new RoleCompetency(sourceComp, currentCompetencyTopics);
					level.competencies.competencies.push(currentCompetency);
				}

				let sourceTopic = sourceComp.topics.find(r => r.path == parts[1]);

				let roleTopicLevels = [];
				let found = false;

				for(var sourceLevel of sourceTopic.levels)
				{
					roleTopicLevels.push(new RoleTopicLevel(sourceLevel, !found));
					if(sourceLevel.path == parts[2])
						found = true;
				}

				currentCompetencyTopics.push(new RoleTopic(sourceTopic, roleLevel.required, roleTopicLevels))
			}
		}
	}

}

buildRoles(engineeringDept);
buildRoles(operationsDept);
buildRoles(designDept);

export function allDepartments()
{
	return [engineeringDept, operationsDept, designDept];
}

export function allRoles()
{
	return engineeringDept.roles.concat(operationsDept.roles).concat(designDept.roles);
}

export function allCompetencies()
{
	return [coreCompetencies, engineeringCompetencies];
}

export function getCompetencyById(id)
{
	return allCompetencies().find(c => c.id == id)
}

export function getCompetencyByPath(path)
{
	return allCompetencies().find(c => c.path == path)
}

export function getRoleById(id)
{
	return allRoles().find(r => r.id == id);
}

export function getRoleByPath(path)
{
	return allRoles().find(r => r.path == path);
}