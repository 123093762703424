<script>
import DateTime from '../../../functions/domain/dateTime.js'

export default {
	data() {
		return { 
			task: this.task,
			DateTime
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<section class="card">
    <div class="card-header">
        <h4 class="card-header-title">Focus your development</h4>
        <small :class="task.dueOn <= DateTime.now() ? 'text-danger' : 'text-muted'" :aria-title="`This task should be completed before ${task.dueOn.format('P')}`"><span class="fe fe-clock"></span> {{task.dueOn.format('P')}}</small>
        <a :href="`/reflections/${task.reflectionId}/advice`" class="btn btn-sm btn-primary">Start iterating <span class="fe fe-arrow-right-circle"></span></a>
    </div>
    <div class="card-body">
        <p class="mb-0">It's time to start iterating to develop your skills.<br/>You need to determine which areas are most valuable for you to focus your effort on.</p>
    </div>
</section>
</template>