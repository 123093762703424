<script>
export const pageName = 'teams-roles';
export const pageRoute = '/teams/{path}/roles';
export const title = 'Team roles'

import { TeamState } from '../../../functions/domain/teams/teamState.js';
import { getCurrentUserId } from '../../js/auth.js';
import { getTeamRoles, getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import { allRoles as getAllRoles } from '../../../functions/domain/roles/roleStore.js';
import { functions } from '../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import TopNav from './topnav.vue'

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			TeamState: TeamState,
			includeAllDefaultRoles: true,
			customRoles: [],
			defaultRoles: [],
			selectedDefaultRoles: [],
			ready: false
		}
	},

	methods: {
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);

			if(this.includeAllDefaultRoles)
			{
				await functions.teamIncludeAllDefaultRoles({
					id: this.team.id
				});
			}
			else
			{
				let selectedRoles = this.defaultRoles.filter(r => r.selected).map(r => r.id);
				await functions.teamIncludeOnlySpecificDefaultRoles({
					id: this.team.id,
					roles: selectedRoles
				})
			}

			this.$refs.form.setSubmitting(false);

			navigate('/teams/' + this.team.path)
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);
			
			let teamRoles = await getTeamRoles(this.team.id);
			let allRoles = getAllRoles();
			
			this.includeAllDefaultRoles = teamRoles.includeAllDefaultRoles;
			this.defaultRoles = allRoles.map(r => ({
				id: r.id,
				title: r.title,
				selected: teamRoles.includeAllDefaultRoles || teamRoles.defaultRoles.findIndex(dr => dr == r.id) != -1
			}));

			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser" current-page="roles"></TopNav>
		<div class="container">
			<form @submit.prevent="submit" ref="form" novalidate class="mb-6">
				<div class="alert alert-light" role="alert">
					<p class="mb-0 small"><span class="fe fe-info"></span> Editing the roles for your team will only affect self-reflections started after you make changes. If you have changes to make its best to do this before you start a new review.</p>
				</div>
				<div class="row">
					<div class="col-12 col-lg-6">
						<section class="card">
							<div class="card-header">
								<h4 class="card-header-title">Default roles</h4>
							</div>
							<div class="card-body">
								<p class="small">Choose which ivelop default roles you would like to be available to your team.</p>
								<div class="form-group">
									<div class="custom-control custom-checkbox">
										<input type="checkbox" class="custom-control-input" name="agree" v-model="includeAllDefaultRoles" value="on" id="chkTeamRolesIncludeAllDefault" aria-describedby="chkTeamRolesIncludeAllDefault_Validation">
										<label class="custom-control-label" for="chkTeamRolesIncludeAllDefault">Include all default roles</label>
									</div>
									<span class="form-text small text-muted mt-2 mb-0">Automatically include all current and future default roles.</span>
									<span id="chkTeamRolesIncludeAllDefault_Validation" class="feedback"></span>
								</div>
							</div>
							<table class="table table-sm table-nowrap table-hover card-table">
								<thead>
									<tr>
										<th>Role</th>
										<th>Available</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="role in defaultRoles">
										<td>{{ role.title }}</td>
										<td class="shrink text-center">
											<div class="form-group">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input" v-model="role.selected" :value="true" :disabled="includeAllDefaultRoles" :id="`chkTeamRolesIncludeAllDefault_${role.id}`" aria-describedby="chkTeamRolesIncludeAllDefault_Validation">
													<label class="custom-control-label" :for="`chkTeamRolesIncludeAllDefault_${role.id}`"></label>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td colspan="2">
											<button type="submit" class="btn btn-lg btn-primary"> {{ (team.state & TeamState.Roles) ? 'Save changes' : 'Confirm configuration' }} <span class="fe fe-check-circle"></span></button>
										</td>
									</tr>
								</tfoot>
							</table>
						</section>
					</div>
					<div class="col-12 col-lg-6">
						<section class="card">
							<div class="card-header">
								<h4 class="card-header-title">Custom roles <span class="badge badge-light">Unavailable in beta</span></h4>
								<!-- <a href="/teams/@ViewData.Team.Path/roles/custom/create" class="btn btn-sm btn-outline-primary">Create role <span class="fe fe-plus-circle"></span></a> -->
							</div>
							<div class="card-body">
								<p class="small mb-0">Configure custom roles specific to your team.</p>
							</div>
							<table class="table table-sm table-nowrap table-hover card-table">
								<thead>
									<tr>
										<th>Role</th>
										<th class="shrink">Available</th>
										<th class="shrink">Actions</th>
									</tr>
								</thead>
								<tbody>
									<!-- <tr>
										<td>@role.Title</td>
										<td class="shrink text-center">
											@this.CheckBoxFor(x => x.SelectedDefaultRoles, role.Id).WithoutValidationMessage().WithLabel("").WithId("chkSelected_" + role.Id.ToString().Replace('-', '_')).AddClass("no-ajax-validate")
										</td>
									</tr> -->
									<tr>
										<td colspan="2">
											Your team does not have any custom roles.
										</td>
									</tr>
								</tbody>
							</table>
						</section>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>