<script>
export const pageName = 'teams-reviews-view';
export const pageRoute = '/teams/{path}/reviews/{reviewId}/view';
export const title = 'View review'

import { getReviewStats, getViewTeamByPath, getViewUser } from '../../../js/readModel.js';
import TopNav from '../topnav.vue'
import { getCurrentUserId } from '../../../js/auth.js';

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			stats: null,
			ready: false
		}
	},

	methods: {
		tickOrCross(boolean)
        {
            if (boolean)
                return '<span class="fe fe-check-circle text-success"><span class="sr-only">Yes</span></span>';

            return '<span class="fe fe-x-circle text-danger"><span class="sr-only">No</span></span>';
        },

		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);
			this.stats = await getReviewStats(this.team.currentReview.id)
			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser" title="Team reviews" current-page="reviews"></TopNav>
		<main class="container">
			<div class="row justify-content-center">
				<div class="col12 col-xl-4 order-xl-last">
					<div class="card">
						<div class="card-body">
							<div class="list-group list-group-flush my-n3">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Reflections started
											</h5>
										</div>
										<div class="col-auto">
											<div class="small">{{stats.reflectionStartedness.toFixed(0)}}%</div>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Reflections submitted
											</h5>
										</div>
										<div class="col-auto">
											<div class="small">{{stats.reflectionSubmittedness.toFixed(0)}}%</div>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Feedback received
											</h5>
										</div>
										<div class="col-auto">
											<div class="small">
												<span v-if="stats.reflectionFeedbackness != null">{{stats.reflectionFeedbackness.toFixed(0)}}%</span>
												<span v-else class="text-muted">N/A</span>
											</div>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Started reflecting on
											</h5>
										</div>
										<div class="col-auto">
											<div class="small">
												{{stats.startedReflectingOn}}
											</div>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Reflection duration
											</h5>
										</div>
										<div class="col-auto">
											<div class="small">
												{{ stats.reflectionDurationDays }} days
											</div>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<h5 class="mb-0">
												Started iterating on
											</h5>
										</div>
										<div class="col-auto">
											<div class="small">
												<span v-if="stats.startedIteratingOn != null">stats.startedIteratingOn</span>
												<span v-else class="text-muted">Not started</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-8">

					<section class="card">
						<div class="card-header">
							<h4 class="card-header-title">Participation</h4>
						</div>
						<div class="mb-0">
							<table class="table table-sm table-nowrap table-hover card-table sortable">
								<thead>
									<tr>
										<th class="shrink">Name</th>
										<th class="text-center">Reflection<br />started</th>
										<th class="text-center">Reflection<br />submitted</th>
										<th class="text-center">Feedback<br />provided</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="[userId, user] of Object.entries(stats.userTasks)">
										<td>{{ user.userName }}</td>
										<td class="text-center" v-html="tickOrCross(user.reflectionStarted)"></td>
										<td class="text-center" v-html="tickOrCross(user.reflectionSubmitted)"></td>
										<td class="text-center">
											<span v-if="!stats.getFeedbackForUser(userId)" class="text-muted">-</span>
											<span v-else>{{stats.getFeedbackForUser(userId).providedFeedback}}/{{ stats.getFeedbackForUser(userId).totalFeedback }}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</section>
				</div>
			</div>
		</main>

	</div>
</template>