<script>

export const pageName = 'teams-create';
export const pageRoute = '/teams/create';
export const title = 'Create team'

import { getCurrentUserId } from '../../js/auth.js';
import { getViewUser } from '../../js/readModel.js';
import { replace } from '@trullock/page-manager'
import { functions } from '../../js/functions.js'

export default {
	data() {
		return { 
			teamName: '',
			teamPath: '',
			userIsMemberOfATeam: false,
			ready: false
		}
	},
	methods: {
		generatePath: function () {
			this.teamPath = this.teamName.replace(/[^a-z0-9\-_]/gi, '_').replace(/_{2,}/gi, '_').toLowerCase();
		},
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true)

			await functions.teamCreate({
				name: this.teamName,
				path: this.teamPath
			});

			replace('/onboarding/teams/' + this.teamPath);
			
			this.$refs.form.setSubmitting(false)
		},
		async show(opts) {
			let user = await getViewUser(getCurrentUserId());
			
			if(user.isMemberOfATeam)
			{
				this.userIsMemberOfATeam = true;
				this.teamPath = user.membership.path
			}
			else
			{
				this.userIsMemberOfATeam = false;
				// TODO: do on show, isnt mounted yet
				//this.$refs.form.reset();
			}

			this.ready = true;
		}
	},
	props: [ 'options' ]
}

</script>

<template>
<template v-if="ready">
	<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-lg-6 col-xl-8" v-if="userIsMemberOfATeam">
            <div class="header mt-md-5">
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">New team</h6>
                            <h1 class="header-title">Create a new team</h1>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="text-center mb-4">You are already part of a team</h2>
            <p class="text-center mb-4">You cannot create a team while you are currently a member of another team.</p>
            <p class="text-center mb-4"><a :href="`/teams/${teamPath}`" class="btn btn-lg btn-primary">Go to team <span class="fe fe-arrow-right-circle"></span></a></p>
        </div>
		<div class="col-12 col-lg-6 col-xl-8 js-form" v-if="!userIsMemberOfATeam">
            <div class="header mt-md-5">
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">New team</h6>
                            <h1 class="header-title">Create a new team</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alert alert-light">
                <h3 class="mb-4">Tips to get the most out of teams</h3>
                <p>We recommend you use a single <strong>ivelop</strong> team to represent your whole company.</p>
                <h4>How teams work</h4>
                <ul>
                    <li>A user can only belong to one team at a time.</li>
                    <li>Teams are completely independent of each other, no data or reporting is shared.</li>
                    <li>New employees can join your team and ex-employees can leave your team at any time.</li>
                    <li>The existence of your team will be public - this makes it easier for new people to find your team.</li>
                </ul>

            </div>

            <hr class="mt-5 mb-5">

            <h2>Team details</h2>
            <form class="mb-6" ref="form" @submit.prevent="submit">
				<fieldset class="mb-4">
					<legend>
						Team details
					</legend>
					<div class="form-group mb-4">
						<label for="txtTeamsCreateName">Name</label>
						<input type="text" class="form-control" v-model="teamName" id="txtTeamsCreateName" aria-describedby="txtTeamsCreateName_Validation" autocomplete="off" placeholder="e.g. ACME Inc." required tabindex="1" @input="generatePath" />
						<span id="txtTeamsCreateName_Validation" class="feedback"></span>
						<span class="form-text text-muted small">You can't change this later.</span>
					</div>
				</fieldset>
				<fieldset>
					<div class="row">
						<div class="col">
							<div class="form-group mb-4">
								<label for="txtTeamsCreateName">Path</label>
								<input type="text" class="form-control" v-model="teamPath" id="txtTeamsCreatePath" aria-describedby="txtTeamsCreatePath_Validation" autocomplete="off" placeholder="e.g. acme-inc" required tabindex="2" />
								<span id="txtTeamsCreatePath_Validation" class="feedback"></span>
								<span class="form-text text-muted small">This will form part of the address that your teams' members see in their browser address bar and links sent to them via email. You can't change this later.</span>
							</div>
						</div>
						<div class="col">
							<div class="card bg-light border">
								<div class="card-body">
									<p class="mb-2">Path requirements</p>
									<p class="small text-muted mb-2">The path must be unique within <strong>ivelop</strong> and contain only the following characters:</p>
									<ul class="small text-muted pl-4 mb-0">
										<li>a-z (lowercase only)</li>
										<li>0-9</li>
										<li>_</li>
										<li>-</li>
									</ul>

								</div>
							</div>
						</div>
					</div>
				</fieldset>
				<button type="submit" class="btn btn-block btn-primary">Create team <span class="fe fe-check-circle"></span></button>
			</form>
        </div>
	</div>
</div>
</template>
</template>