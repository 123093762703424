import {registerClassForPersistence} from './automapper.js'


import ViewTeam from './teams/viewTeam.js';
import Team from './teams/team.js';
import TeamBenchmarks from './teams/teamBenchmarks.js';
import TeamRoles from './teams/teamRoles.js';
import TeamMemberships from './teams/teamMemberships.js';
import Membership from './teams/membership.js';

registerClassForPersistence('Team', Team);
registerClassForPersistence('Membership', Membership);
registerClassForPersistence('TeamBenchmarks', TeamBenchmarks);
registerClassForPersistence('TeamRoles', TeamRoles);
registerClassForPersistence('TeamMemberships', TeamMemberships);
registerClassForPersistence('ViewTeam', ViewTeam);



import Reflection from './reflections/reflection.js';
import ReflectionSummary from './reflections/reflectionSummary.js';
import ReflectionView from './reflections/reflectionView.js';
import ReflectionHistory from './reflections/reflectionsHistory.js';

registerClassForPersistence('Reflection', Reflection);
registerClassForPersistence('ReflectionSummary', ReflectionSummary);
registerClassForPersistence('ReflectionView', ReflectionView);
registerClassForPersistence('ReflectionHistory', ReflectionHistory);



import User from './users/user.js'
import ViewUser from './users/viewUser.js';
import LiteUser from './users/liteUser.js';

registerClassForPersistence('User', User);
registerClassForPersistence('ViewUser', ViewUser);
registerClassForPersistence('LiteUser', LiteUser);



import Review from './reviews/review.js';
import ReviewStats from './reviews/reviewStats.js';

registerClassForPersistence('Review', Review);
registerClassForPersistence('ReviewStats', ReviewStats);


import ActionTeamApplicationsUserTask from "./users/userTasks/members/admins/actionTeamApplicationsUserTask.js"
import SetupTeamUserTask from "./users/userTasks/members/admins/setupTeamUserTask.js";
import StartFirstReviewUserTask from "./users/userTasks/members/admins/startFirstReviewUserTask.js"

import CompleteReflectionAnswersUserTask from "./users/userTasks/completeReflectionAnswersUserTask.js";
import ProvideFeedbackUserTask from "./users/userTasks/provideFeedbackUserTask.js";
import ReviewFeedbackUserTask from "./users/userTasks/reviewFeedbackUserTask.js";
import StartIteratingUserTask from "./users/userTasks/startIteratingUserTask.js";
import StartReflectionUserTask from "./users/userTasks/startReflectionUserTask.js";
import SubmitReflectionAnswersUserTask from "./users/userTasks/submitReflectionAnswersUserTask.js"
import WaitForApplicationApprovalUserTask from "./users/userTasks/waitForApplicationApprovalUserTask.js";
import WaitForIteratingUserTask from "./users/userTasks/waitForIteratingUserTask.js";
import WaitForFeedbackUserTask from "./users/userTasks/waitForFeedbackUserTask.js";
import WaitForReviewToStartUserTask from "./users/userTasks/waitForReviewToStartUserTask.js";

registerClassForPersistence('ActionTeamApplicationsUserTask', ActionTeamApplicationsUserTask);
registerClassForPersistence('SetupTeamUserTask', SetupTeamUserTask);
registerClassForPersistence('StartFirstReviewUserTask', StartFirstReviewUserTask);

registerClassForPersistence('CompleteReflectionAnswersUserTask', CompleteReflectionAnswersUserTask);
registerClassForPersistence('ProvideFeedbackUserTask', ProvideFeedbackUserTask);
registerClassForPersistence('ReviewFeedbackUserTask', ReviewFeedbackUserTask);
registerClassForPersistence('StartIteratingUserTask', StartIteratingUserTask);
registerClassForPersistence('StartReflectionUserTask', StartReflectionUserTask);
registerClassForPersistence('SubmitReflectionAnswersUserTask', SubmitReflectionAnswersUserTask);
registerClassForPersistence('WaitForApplicationApprovalUserTask', WaitForApplicationApprovalUserTask);
registerClassForPersistence('WaitForIteratingUserTask', WaitForIteratingUserTask);
registerClassForPersistence('WaitForFeedbackUserTask', WaitForFeedbackUserTask);
registerClassForPersistence('WaitForReviewToStartUserTask', WaitForReviewToStartUserTask);