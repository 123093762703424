import { UserTask } from "./userTask.js";

export default class WaitForApplicationApprovalUserTask extends UserTask
{
	teamName = null
	get order() { return 1 }
	
	getNextRemindOn(previouslyRemindedOn)
	{
		return null;
	}
}
