<script>
export const pageName = 'reflections-review-feedback';
export const pageRoute = '/reflections/{reflectionId}/feedback/{mentorId}';
export const title = 'Review feedback'

import { getRoleById} from '../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/functions.js';
import { getLiteUser, getReflectionView, getViewUser } from '../../js/readModel.js';
import MeNav from '../menav.vue'
import {navigate} from '@trullock/page-manager'
import { feedbackAdjustment } from '../../js/utils.js';

export default {
	data() {
		return {
			currentUser: null,
			user: null,
			reflectionView: null,
			role: null,
			level: null,
			competencies: [],
			feedback: null,
			increasedAnswers: 0,
			staticAnswers: 0,
			decreasedAnswers: 0,
			feedbackDisagreementRate: 0,
			feedbackAdjustment: 0,
			ready: false
		}
	},
	methods: {
		async markAsReviewed() {

			this.$refs.form.setSubmitting(true)

			await functions.reflectionReviewFeedback({
				reflectionId: this.reflectionView.id,
				mentorId: this.feedback.mentorId
			})

			// tood: say thanks?
			navigate(`/`)
				
			this.$refs.form.setSubmitting(false)
		},
		feedbackAdjustmentView: feedbackAdjustment,
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			this.reflectionView = await getReflectionView(opts.reflectionId)
			this.user = await getLiteUser(this.reflectionView.userId);
			
			this.role = getRoleById(this.reflectionView.roleId);
			this.roleLevel = this.role.getLevelById(this.reflectionView.levelId);

			this.feedback = this.reflectionView.feedback.find(f => f.mentorId == opts.mentorId)

			this.competencies = this.roleLevel.competencies.competencies.map(c => ({
				title: c.title,
				path: c.path,
				summary: c.summary,
				topics: c.topics.map(t => ({
					title: t.title,
					path: t.path,
					summary: t.summary,
					levels: t.requiredLevels.map(l => ({
						title: l.title,
						path: l.path,
						summary: l.summary,
						criteria: l.criteria.map(c => ({
							id: c.id,
							text: c.text,
							answer: this.reflectionView.answers[c.id],
							feedbackAnswer: this.feedback.answers[c.id]
						}))
					}))
				}))
			}))

			this.feedbackAdjustment = this.reflectionView.stats.feedback;
			this.increasedAnswers = this.feedback.changedAnswers.filter(ca => this.reflectionView.answers[ca] < this.feedback.answers[ca]).length
			this.staticAnswers = Object.keys(this.feedback.answers).length - this.feedback.changedAnswers.length;
			this.decreasedAnswers = this.feedback.changedAnswers.filter(ca => this.reflectionView.answers[ca] > this.feedback.answers[ca]).length
			this.feedbackDisagreementRate = this.feedback.changedAnswers.length * 100 / Object.keys(this.feedback.answers).length
			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	
<div class="container" v-if="ready">

	<div class="row">
		<div class="col">
			<div class="header">
				<div class="header-body pt-0">
					<MeNav />
				</div>
			</div>
		</div>
	</div>

	<form @submit.prevent="markAsReviewed" ref="form">
		<div class="row justify-content-center">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Review and discuss {{feedback.mentorName}}'s feedback</h4>
						<button type="submit" class="btn btn-sm btn-primary">Mark as reviewed <span class="fe fe-check-circle"></span></button>
					</div>
					<div class="card-body">
						<p>You should review this feedback carefully and discuss it with your mentor.</p>
						<p>Schedule a meeting with them to go through the information below, remember to discuss:</p>
						<ul>
							<li>Your mentor's overall opinion</li>
							
							<li v-if="feedbackDisagreementRate == 0">The significance of you both agreeing 100% on your rating.</li>
							<li v-else>The significance of {{feedbackDisagreementRate.toFixed(1)}}% of the criteria being answered differently between you and your mentor, and the reasoning for this.</li>
							
							<li v-if="increasedAnswers == 1">The 1 increased criterion specifically</li>
							<li v-else-if="increasedAnswers > 1">The {{ increasedAnswers }} increased criteria specifically</li>
						
							<li v-if="decreasedAnswers == 1">The 1 decreased criterion specifically</li>
							<li v-else-if="decreasedAnswers > 1">The {{decreasedAnswers}} decreased criteria specifically</li>
							
						</ul>
					</div>
				</div>

				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Feedback summary</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-6">
								<div class="list-group list-group-flush mb-4">
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Mentor</small>
											</div>
											<div class="col-auto">
												<time class="small text-muted" datetime="1988-10-24">{{ feedback.mentorName }}</time>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Provided on</small>
											</div>
											<div class="col-auto">
												<small class="text-muted">{{feedback.providedOn.format('P')}}</small>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-6 border-left">
								<div class="list-group list-group-flush mb-4">
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Rating adjustment <span aria-title="The impact this feedback had on the overall rating for this self-reflection."><span class="fe fe-info text-muted"></span></span></small>
											</div>
											<div class="col-auto">
												<a class="small text-muted" v-html="feedbackAdjustmentView(feedbackAdjustment)"></a>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Rating disagreement <span aria-title="The % of criteria which the mentors answer was different from the self-refleciton answer."><span class="fe fe-info text-muted"></span></span></small>
											</div>
											<div class="col-auto">
												<a class="small text-muted">{{feedbackDisagreementRate.toFixed(1)}}%</a>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Criteria unchanged  <span aria-title="The number of criteria given the same answers in mentor feedback than the original self-reflection."><span class="fe fe-info text-muted"></span></span></small>
											</div>
											<div class="col-auto">
												<small class="text-muted">{{staticAnswers}}</small>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Criteria changed  <span aria-title="The number of criteria given answers different in mentor feedback than the original self-reflection."><span class="fe fe-info text-muted"></span></span></small>
											</div>
											<div class="col-auto">
												<small class="text-muted">{{feedback.changedAnswers.length}}</small>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Increased criteria <span aria-title="The number of criteria given answers higher in mentor feedback than the original self-reflection."><span class="fe fe-info text-muted"></span></span></small>
											</div>
											<div class="col-auto">
												<a class="small text-muted">{{increasedAnswers}} <span class="fe fe-arrow-up fe-heavy text-success"></span></a>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<small>Decreased criteria <span aria-title="The number of criteria given answers lower in mentor feedback than the original self-reflection."><span class="fe fe-info text-muted"></span></span></small>
											</div>
											<div class="col-auto">
												<a class="small text-muted">{{decreasedAnswers}} <span class="fe fe-arrow-down fe-heavy text-danger"></span></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				
				<form class="mb-4">
					<template v-for="competency of competencies">
						<h2 class="">{{competency.title}}</h2>
						<p class="small border-bottom pb-3 mb-5">{{competency.summary}}</p>

						<template v-for="topic of competency.topics">
							<div v-for="level of topic.levels" class="card">
								<div class="card-header">
									<h4 class="card-header-title">{{competency.title}} - {{topic.title}} - {{level.title}}</h4>
								</div>
								<table class="table table-sm table-hover card-table">
									<thead>
										<tr>
											<th>Criteria</th>
											<th colspan="2">Never</th>
											<th></th>
											<th colspan="2" class="text-right">Always</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="criterion of level.criteria">
											<td>{{ criterion.text }}</td>
											<td class="shrink pr-0">
												<div class="custom-control custom-radio" :aria-title="criterion.answer == 0 ? 'Mentee\'s original answer' : null">
													<input type="radio" :name="`answers-${criterion.id}`" value="0" v-model="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 0 ? 'previous-value' : null}`" :id="`cbReviewFeedback${criterion.id}-0`">
													<label class="custom-control-label color-0"></label>
												</div>
											</td>
											<td class="shrink pr-0">
												<div class="custom-control custom-radio" :aria-title="criterion.answer == 1 ? 'Mentee\'s original answer' : null">
													<input type="radio" :name="`answers-${criterion.id}`" value="1" v-model="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 1 ? 'previous-value' : null}`" :id="`cbReviewFeedback${criterion.id}-1`">
													<label class="custom-control-label color-1"></label>
												</div>
											</td>
											<td class="shrink pr-0">
												<div class="custom-control custom-radio" :aria-title="criterion.answer == 2 ? 'Mentee\'s original answer' : null">
													<input type="radio" :name="`answers-${criterion.id}`" value="2" v-model="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 2 ? 'previous-value' : null}`" :id="`cbReviewFeedback${criterion.id}-2`">
													<label class="custom-control-label color-2"></label>
												</div>
											</td>
											<td class="shrink pr-0">
												<div class="custom-control custom-radio" :aria-title="criterion.answer == 3 ? 'Mentee\'s original answer' : null">
													<input type="radio" :name="`answers-${criterion.id}`" value="3" v-model="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 3 ? 'previous-value' : null}`" :id="`cbReviewFeedback${criterion.id}-3`">
													<label class="custom-control-label color-3"></label>
												</div>
											</td>
											<td class="shrink pr-0">
												<div class="custom-control custom-radio" :aria-title="criterion.answer == 4 ? 'Mentee\'s original answer' : null">
													<input type="radio" :name="`answers-${criterion.id}`" value="4" v-model="criterion.feedbackAnswer" :class="`custom-control-input ${criterion.answer == 4 ? 'previous-value' : null}`" :id="`cbReviewFeedback${criterion.id}-4`">
													<label class="custom-control-label color-4"></label>
												</div>
											</td>
											<td class="shrink">
												<span v-if="criterion.answer != criterion.feedbackAnswer" aria-title="Mentor disagreed with mentee's answer"><span class="fe fe-alert-circle text-primary"></span></span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</template>
					</template>
				</form>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12 col-md-6 mb-6">
					<button type="submit" class="btn btn-block btn-primary">Mark as reviewed <span class="fe fe-check-circle"></span></button>
			</div>
		</div>
	</form>
</div>

</template>