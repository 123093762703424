import { UserTask } from "./userTask.js";

export default class StartReflectionUserTask extends UserTask
{
	reviewId = null

	get summary() {
		return 'Start a self reflection of how you\'re doing'
	}
}
