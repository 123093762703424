<script>

export const requireAuth = false;
export const pageName = 'account-reset-password';
export const pageRoute = '/account/reset-password';
export const title = 'Reset password'
export const furniture = 'plain';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { validation } from '../../js/language.js'
import { navigate, PageShowError } from '@trullock/page-manager';

export default {
	data() {
		return {
			email: ''
		 }
	},
	methods: {
		submit: async function() {

			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);
			
			try
			{
				await sendPasswordResetEmail(getAuth(), this.email);
				navigate('/account/reset-password/sent')
			}
			catch(error) 
			{
				this.$refs.form.setValidationResult(validation[error.code] || validation['error']);
			}

			this.$refs.form.setSubmitting(false);
		},
		show: function(opts) {
			if(getAuth().currentUser)
				throw new PageShowError('/', 'Already signed in', {}, 'replace')

			this.email = opts.email || '';
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-5 col-xl-4 my-5">
			<h1 class="display-4 text-center mb-3">Reset password</h1>
			<p class="text-muted text-center mb-5">Let's get you signed in.</p>
			<form novalidate @submit.prevent="submit" ref="form">
				<fieldset class="mb-4">
					<legend>Account details</legend>
					<div class="form-group mb-4">
						<label for="txtSignInEmail">Email</label>
						<input type="email" class="form-control" name="email" v-model="email" id="txtResetPasswordEmail" aria-describedby="txtResetPasswordEmail_Validation" autocomplete="email" placeholder="e.g. your.name@example.com" required tabindex="1" />
						<span id="txtResetPasswordEmail_Validation" class="feedback"></span>
					</div>
				</fieldset>
				<button type="submit" class="btn btn-lg btn-block btn-primary mb-3">Send Email</button>
				<div class="text-center">
					<p class="small text-muted mb-2">Remembered your details? <a href="/account/sign-in">Sign in</a>.</p>
                	<p class="small text-muted">Not got an account yet? <a href="/account/sign-up">Sign up</a> now.</p>
				</div>
			</form>
		</div>
	</div>
</div>
</template>