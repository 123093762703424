<script>
export const pageName = 'teams-join';
export const pageRoute = '/teams/{path}/join';
export const title = 'Join team'

import { getCurrentUserId } from '../../js/auth.js';
import { getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import { functions } from '../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import { showToast } from '../../js/toast.js';

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			pane: 'join',
			ready: false
		}
	},

	methods: {
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);
			
			await functions.teamApply({ teamId: this.team.id });
			
			this.$refs.form.setSubmitting(false);

			navigate('/')

			showToast({
				message: 'Application sent'
			})
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);

			this.pane = this.currentUser.canApplyToTeam(this.team.id) ? 'join' : 'cantjoin';
			

			this.ready = true;
		}
	},
	components: {  },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-6 col-xl-8">
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">
								<h6 class="header-pretitle">{{ team.name }}</h6>
								<h1 class="header-title">Join team</h1>
							</div>
						</div>
					</div>
				</div>

				<div v-if="pane == 'join'">
					<p>You are requesting to join the team <strong>{{ team.name }}</strong>.</p>
					<p>This will allow you to collaborate with your colleagues and work collectively to give each other feedback and iterate together on your self-improvement goals.</p>
					<form @submit.prevent="submit" ref="form" class="mb-6">
						<button type="submit" class="btn btn-block btn-primary">Apply to join <span class="fe fe-check-circle"></span></button>
					</form>
				</div>
				<div v-else>
					<p>You are already either a member of, have applied to or have been invited to join this team.</p>
					<a href="/" class="btn btn-block btn-primary">Go to dashboard</a>
				</div>
			</div>
		</div>
	</div>
</template>