<script>
import { ReviewPhase } from '../../../../functions/domain/reviews/reviewPhase.js';

export default {
	data() {
		return {
			review: this.review,
			ReviewPhase: ReviewPhase
		}
	},
	props: [ 'review' ]
}

</script>
<template>
	<div class="card">
	<div class="card-header">
		<h4 class="card-header-title">Review phase</h4>
	</div>
	<div class="card-body">
		<div class="list-group list-group-flush list-group-activity my-n3">
			<div class="list-group-item">
				<div :class="`row ${!review ? 'text-muted' : null}`">
					<div class="col-auto">
						<div class="avatar avatar-sm">
							<div :class="`avatar-title font-size-lg rounded-circle ${!review ? 'text-white bg-primary' : 'text-success bg-success-soft'}`">
								<i class="fe fe-play-circle"></i>
							</div>
						</div>
					</div>
					<div class="col ml-n2">
						<h5 class="mb-1">1. Start a review</h5>
						<p class="small text-gray-700 mb-0">A new chapter of team analysis</p>
					</div>
				</div>
			</div>
			<div class="list-group-item">
				<div :class="`row ${review?.phase == ReviewPhase.reflecting ? 'text-muted' : null}`">
					<div class="col-auto">
						<div class="avatar avatar-sm">
							<div :class="`avatar-title font-size-lg rounded-circle ${review?.phase == ReviewPhase.reflecting ? 'text-white bg-primary' : review?.phase == ReviewPhase.iterating ? 'text-success bg-success-soft' : 'text-primary bg-primary-soft'}`">
								<i class="fe fe-check-circle"></i>
							</div>
						</div>
					</div>
					<div class="col ml-n2">
						<h5 class="mb-1">2. Reflect and feedback</h5>
						<p class="small text-gray-700 mb-0">
							{{ review ? `Due to end ${review.reflectionPhaseEndsOn}` : 'Self-reflection and mentor feedback' }}
						</p>
					</div>
				</div>
			</div>
			<div class="list-group-item">
				<div :class="`row ${review?.phase == ReviewPhase.reflecting ? 'text-muted' : null}`">
					<div class="col-auto">
						<div class="avatar avatar-sm">
							<div :class="`avatar-title font-size-lg rounded-circle ${review?.phase == ReviewPhase.iterating ? 'text-white bg-primary' : 'text-primary bg-primary-soft'}`">
								<i class="fe fe-crosshair"></i>
							</div>
						</div>
					</div>
					<div class="col ml-n2">
						<h5 class="mb-1">3. Set priorities and iterate</h5>
						<p class="small text-gray-700 mb-0">Determine team direction and make progress</p>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>

</template>