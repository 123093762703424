<script>
export const pageName = 'teams-billing';
export const pageRoute = '/teams/{path}/billing';
export const title = 'Billing'

import { TeamState } from '../../../functions/domain/teams/teamState.js';
import { getCurrentUserId } from '../../js/auth.js';
import { getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import { functions } from '../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import TopNav from './topnav.vue'

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			TeamState: TeamState,
			accepted: false,
			ready: false
		}
	},

	methods: {
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);

			await functions.teamMarkBillingAsConfigured({
				id: this.team.id
			});
			
			this.$refs.form.setSubmitting(false);

			navigate('/teams/' + this.team.path)
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);
			
			this.accepted = !!(this.team.state & TeamState.Billing)

			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser" current-page="billing"></TopNav>
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-lg-6">
					<div class="card">
						<div class="card-header">
							<h4 class="card-header-title">Billing</h4>
						</div>
						<div class="card-body">
							<p><strong>ivelop</strong> is completely free during beta.</p>
							<p>Once the beta period is over however, you will need to subscribe to one of our paid tiers.<br />You'll then need to come back here and set up your billing details. We'll let you know when this is required.</p>
							<form v-if="!accepted" @submit.prevent="submit" ref="form" class="mb-0">
								<button type="submit" class="btn btn-primary btn-block">Confirm <span class="fe fe-check-circle"></span></button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>