import { v4 as uuid } from 'uuid'

export function updateFormGroupIds($formGroup){

	var $checks = $formGroup.$('.form-check, .custom-radio');
	if($checks){
		$checks.forEach(updateFormGroupIds);
		return;
	}

	var $input = $formGroup.$('textarea,input,select');
	if(!$input)
		return;
		
	var $label = $formGroup.$('label');
	var $validation = $formGroup.$('.feedback');

	$input.id = 'inp' + uuid().replaceAll('-', '_');
	if ($label)
		$label.htmlFor = $input.id;

	if ($validation) {
		$validation.id = $input.id + '_validation';
		$input.setAttribute('aria-describedby', $validation.id);
	}
}


export function completenessColor(percent) {
	if (percent == 100)
		return "color-4";

	if (percent >= 75)
		return "color-3";

	if (percent >= 50)
		return "color-2";

	if (percent >= 25)
		return "color-1";

	// use default blue as its nicer
	if (percent == 0)
		return null;

	return "color-0";
}

export function feedbackAdjustment(feedback){
	if(feedback == 0)
		return `0.0 <span class="fe fe-arrow-right fe-heavy text-primary"></span>`;

	if(feedback < 0)
		return `${feedback.toFixed(1)} <span class="fe fe-arrow-down fe-heavy text-danger"></span>`

	return `+${feedback.toFixed(1)} <span class="fe fe-arrow-up fe-heavy text-success"></span>`
}

export function ratingStars(rating) {
	if (rating < 1)
		return `<span class="small badge badge-light mr-1">${rating.toFixed(1)}</span> <span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span>`;

	if (rating < 2)
		return `<span class="small badge badge-light mr-1">${rating.toFixed(1)}</span> <span class="fe fe-star text-color-0 align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span>`;

	if (rating < 4)
		return `<span class="small badge badge-light mr-1">${rating.toFixed(1)}</span> <span class="fe fe-star text-color-1 align-middle"></span><span class="fe fe-star text-color-1 align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span>`;

	if (rating < 6)
		return `<span class="small badge badge-light mr-1">${rating.toFixed(1)}</span> <span class="fe fe-star text-color-2 align-middle"></span><span class="fe fe-star text-color-2 align-middle"></span><span class="fe fe-star text-color-2 align-middle"></span><span class="fe fe-star text-muted align-middle"></span><span class="fe fe-star text-muted align-middle"></span>`;

	if (rating < 9)
		return `<span class="small badge badge-light mr-1">${rating.toFixed(1)}</span> <span class="fe fe-star text-color-3 align-middle"></span><span class="fe fe-star text-color-3 align-middle"></span><span class="fe fe-star text-color-3 align-middle"></span><span class="fe fe-star text-color-3 align-middle"></span><span class="fe fe-star text-muted align-middle"></span>`;

	return `<span class="small badge badge-light mr-1">${rating.toFixed(1)}</span> <span class="fe fe-star text-color-4 align-middle"></span><span class="fe fe-star text-color-4 align-middle"></span><span class="fe fe-star text-color-4 align-middle"></span><span class="fe fe-star text-color-4 align-middle"></span><span class="fe fe-star text-color-4 align-middle"></span>`;
}

export function delay(ms)
{
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	})
}