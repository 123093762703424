import UserRoleLevel from "./userRoleLevel.js"
import Stats from "./reflections/stats.js"
import { average} from "../math.js"

export default class UserRole
{
	id = null
	icon = null
	title = null
	path = null
	
	level = null

	get requiredCriteria()
	{
		return this.level.allRequiredCriteria.length
	}

	constructor(role, roleLevel)
	{
		this.id = role.id;
		this.icon = role.icon;
		this.title = role.title;
		this.path = role.path;

		this.level = new UserRoleLevel(roleLevel);
	}

	applyAnswers(answers)
	{
		for(let criterion of this.level.allRequiredCriteria)
		{
			if(criterion.id in answers)
				criterion.answer = answers[criterion.id];
		}
	}
	

	applyFeedback(feedback)
	{
		for(let criterion of this.level.allRequiredCriteria)
		{
			if(criterion.id in feedback)
				criterion.feedback.push(feedback[criterion.id]);
		}
	}

	applyIterations(iterations)
	{
		for(let criterion of this.level.allRequiredCriteria)
		{
			for (let iteration of iterations)
			{
				if (iteration.criteria.filter(c => c.id == criterion.id).length)
					criterion.iteratedOn++;
			}
		}
	}

	getCompetencyTopicStats()
	{
		return this.level.competencies.reduce((map, c) => {
			let topicMap = c.topics.reduce((map, t) => {
				let stats = new Stats()
				stats.completeness = t.answerCompleteness;
				stats.rating = t.answerRating;
				stats.feedback = t.answerFeedback
				
				map[t.id] = stats;
				return map;
			}, {})
			map[c.id] = topicMap;
			return map;
		}, {})
	}

	getStats()
	{
		let criteria = this.level.allRequiredCriteria;
		let answeredCriteria = criteria.filter(c => c.answer !== null).length;
		let completeness = criteria.length == 0 ? 0 : (answeredCriteria * 100.0 / criteria.length);

		let topicsToRate = this.level.competencies.map(c => c.topics).reduce((p, c) => p.concat(c), []).filter(t => t.answerCompleteness == 100);
		let rating = topicsToRate.length == 0 ? 0 : Math.round(average(topicsToRate, t => t.answerRating));

		let stats = new Stats()
		stats.completeness = completeness;
		stats.rating = rating;
		return stats;
	}
}