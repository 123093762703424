
import { getFunctions, httpsCallable } from 'firebase/functions'
import { serializeData } from '../../functions/functions.js';

let p = new Proxy({}, {
	get: function(target, prop, receiver)
	{
		let fns = getFunctions();
		return function(data) {
			let serialzised = serializeData(data);
			return httpsCallable(fns, prop)(serialzised)
		};
	}
});

export const functions = p;