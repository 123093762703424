<script>

export const pageName = 'browse-roles';
export const pageRoute = '/browse/roles';
export const title = 'Browse roles'

import { allDepartments } from '../../../functions/domain/roles/roleStore.js';
		
export default {
	data() {
		return {
			departments: allDepartments()
		}
	}
};


</script>
<template>
	<div class="header">
		<div class="container">
			<div class="header-body">
				<nav class="h5" aria-label="breadcrumb">
					<ol class="breadcrumb pt-0 pb-1">
						<li class="breadcrumb-item"><a href="/">Home</a></li>
						<li class="breadcrumb-item active" aria-current="page">Roles</li>
					</ol>
				</nav>
				<h1 class="header-title">Browse roles</h1>
			</div>
		</div>
	</div>
	<main class="container">
		<div class="row justify-content-center">
			<div v-for="department of departments" class="col-12 col-lg-6">
				<section class="card">
					<div class="card-header">
						<h4 class="card-header-title">{{department.title}}</h4>
					</div>
					<div class="mb-0">
						<table class="table table-sm table-nowrap table-hover card-table">
							<tbody>
								<tr v-for="role of department.roles">
									<td>{{ role.icon }} {{ role.title }}</td>
									<td class="shrink">
										<a :href="`/browse/roles/${role.path}`" class="btn btn-sm btn-outline-primary">Browse levels <span class="fe fe-arrow-right-circle"></span></a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</div>
	</main>
</template>