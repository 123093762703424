import AggregateRoot from "../aggregateRoot.js";
import { TeamState } from "./teamState.js";

export default class Team extends AggregateRoot
{
	name = null
	path = null
	iterationDurationDays = null
	reflectionDurationDays = null
	allDefaultRolesAvailable = true
	availableDefaultRoles = []
	state = 0;

	static create(id, name, path)
	{
		let team = new Team();
		team.#create(id, name, path);
		return team;
	}

	#create(id, name, path)
	{
		this.apply('teamCreated', {
			aggregateRootId: id,
			name, 
			path,
			iterationDurationDays: 14,
			reflectionDurationDays: 14
		});
	}

	onTeamCreated(event) 
	{
		this.id = event.aggregateRootId;
		this.name = event.name;
		this.path = event.path;
		this.iterationDurationDays = event.iterationDurationDays;
		this.reflectionDurationDays = event.reflectionDurationDays;
		this.allDefaultRolesAvailable = true;
		this.availableDefaultRoles = [];
		this.state = TeamState.BasicDetails;
	}

	changeIterationSettings(iterationDurationDays)
	{
		if(this.iterationDurationDays == iterationDurationDays)
			return;
		
		this.apply('teamIterationSettingsChanged', {
			iterationDurationDays: iterationDurationDays
		})
	}

	onTeamIterationSettingsChanged(event)
	{
		this.iterationDurationDays = event.iterationDurationDays;
	}

	changeReflectionSettings(reflectionDurationDays)
	{
		if(this.reflectionDurationDays == reflectionDurationDays)
			return;
		
		this.apply('teamRelectionSettingsChanged', {
			reflectionDurationDays: reflectionDurationDays
		})
	}

	onTeamReflectionSettingsChanged(event)
	{
		this.reflectionDurationDays = event.reflectionDurationDays;
	}

	changeReviewCycles(reviewMonth0, reviewMonth1, reviewMonth2, reviewMonth3)
	{
		if(this.reviewMonth0 == reviewMonth0 && this.reviewMonth1 == reviewMonth1 && this.reviewMonth2 == reviewMonth2 && this.reviewMonth3 == reviewMonth3)
			return;
		
		this.apply('teamReviewCyclesChanges', {
			reviewMonth0: reviewMonth0,
			reviewMonth1: reviewMonth1,
			reviewMonth2: reviewMonth2,
			reviewMonth3: reviewMonth3
		})
	}

	onTeamReviewCyclesChanges(event)
	{
		this.reviewMonth0 = event.reviewMonth0;
		this.reviewMonth1 = event.reviewMonth1;
		this.reviewMonth2 = event.reviewMonth2;
		this.reviewMonth3 = event.reviewMonth3;
	}
	
	markSettingsAsReviewed()
	{
		if(this.state & TeamState.Settings)
			return;
		
		this.apply('teamSettingsReviewed', {
			setupComplete: ((this.state & TeamState.Complete) | TeamState.Settings) == TeamState.Complete
		})
	}

	onTeamSettingsReviewed(event)
	{
		this.state |= TeamState.Settings;
	}

	includeAllDefaultRoles()
	{
		if(this.allDefaultRolesAvailable)
			return;
		
		this.apply('teamDefaultRoleAvailabilityChanged', {
			includeAllDefaultRoles: true,
			specificRolesToInclude: []
		})
	}


	includeOnlySpecificDefaultRoles(roles)
	{
		this.apply('teamDefaultRoleAvailabilityChanged', {
			includeAllDefaultRoles: false,
			specificRolesToInclude: roles 
		})
	}

	onTeamDefaultRoleAvailabilityChanged(event)
	{
		this.allDefaultRolesAvailable = event.includeAllDefaultRoles;
		this.availableDefaultRoles = event.specificRolesToInclude;
	}
	
	markRolesAsReviewed()
	{
		if(this.state & TeamState.Roles)
			return;
		
		this.apply('teamRolesReviewed', { 
			setupComplete: ((this.state & TeamState.Complete) | TeamState.Roles)  == TeamState.Complete
		})
	}

	onTeamRolesReviewed(event)
	{
		this.state |= TeamState.Roles;
	}
	
	markBillingAsReviewed()
	{
		if(this.state & TeamState.Billing)
			return;
		
		this.apply('teamBillingConfigured', {
			setupComplete: ((this.state & TeamState.Complete) | TeamState.Billing) == TeamState.Complete
		})
	}

	onTeamBillingConfigured(event)
	{
		this.state |= TeamState.Billing;
	}
	
	markPeopleAsConfigured()
	{
		if(this.state & TeamState.People)
			return;
		
		this.apply('teamPeopleConfigured', {
			setupComplete: ((this.state & TeamState.Complete) | TeamState.People)  == TeamState.Complete
		})
	}

	onTeamPeopleConfigured(event)
	{
		this.state |= TeamState.People;
	}
}