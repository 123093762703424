<script>

// TODO: treeshaking: https://www.chartjs.org/docs/latest/getting-started/integration.html
import Chart from 'chart.js/auto';


export default {
	data() {
		return {
			data: this.data
		}
	},
	mounted() {
		if(this.data.length <= 1)
			return;


		new Chart(this.$refs.chart, {
			type: 'line',
			options: {
				scales: {
					y: {
						
						grid: {
							drawTicks: false,
							display: false
						},
						ticks: { 
							display: false
						}
					},
					x: {
						grid: {
							drawTicks: false,
							display: false
						},
						ticks: { 
							display: false
						}
					}
				},
				plugins: {
					tooltip:
					{
						enabled: false
					}
				}
			},
			
			data: {
				labels: this.data,
				datasets: [
					{
						label: 'foo',
						data: this.data,
						borderWidth: 2,
						borderColor: '#B1C2D9',
						pointRadius: 0
					}
				]
			}
		});
	},
	props: [ 'data' ]
}

</script>
<template>
	<div  v-if="data.length > 1" class="chart">
		<canvas class="chart-canvas" ref="chart"></canvas>
	</div>
</template>