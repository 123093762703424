export default class RoleTopic {
	id = null
	title = null
	path = null
	summary = null
	requiredByRole = false
	levels = []
	
	constructor(level, requiredByRole, levels)
	{
		this.id = level.id;
		this.title = level.title;
		this.path = level.path;
		this.summary = level.summary;
		this.levels = levels;
		this.requiredByRole = requiredByRole;
	}

	get allCriteria()
	{
		return this.levels.flatMap(l => l.criteria);
	}

	get requiredLevels()
	{
		return this.levels.filter(l => l.requiredByRole);
	}

	get requiredCriteria()
	{
		return this.requiredLevels.flatMap(l => l.criteria);
	}

	get expectedLevel()
	{
		return this.levels.findLast(l => l.requiredByRole);
	}

	get levelAbove()
	{
		return this.levels.find(l => !l.requiredByRole);
	}

	getLevelByPath(path){
		return this.levels.find(l => l.path == path);
	}
}