<script>
export const pageName = 'teams-settings';
export const pageRoute = '/teams/{path}/settings';
export const title = 'Team settings'

import { TeamState } from '../../../functions/domain/teams/teamState.js';
import { getCurrentUserId } from '../../js/auth.js';
import { getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import { functions } from '../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import TopNav from './topnav.vue'

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			reviewMonth0: 1,
			reviewMonth1: 3,
			reviewMonth2: 0,
			reviewMonth3: 0,
			iterationDurationDays: 14,
			reflectionDurationDays: 14,
			isFirstEdit: true,
			ready: false
		}
	},
	methods: {
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);

			await functions.teamChangeSettings({
				id: this.team.id,
				iterationDurationDays: this.iterationDurationDays,
				reflectionDurationDays: this.reflectionDurationDays,
				reviewMonth0: this.reviewMonth0,
				reviewMonth1: this.reviewMonth1,
				reviewMonth2: this.reviewMonth2,
				reviewMonth3: this.reviewMonth3,
			});

			this.$refs.form.setSubmitting(false);

			navigate('/teams/' + this.team.path)
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);

			this.reviewMonth0 = this.team.reviewMonth0;
			this.reviewMonth1 = this.team.reviewMonth1;
			this.reviewMonth2 = this.team.reviewMonth2;
			this.reviewMonth3 = this.team.reviewMonth3;

			this.iterationDurationDays = this.team.iterationDurationDays;
			this.reflectionDurationDays = this.team.reflectionDurationDays;
			this.isFirstEdit = !(this.team.state & TeamState.Settings);

			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser" current-page="settings"></TopNav>
		<div class="container">
			<form @submit.prevent="submit" ref="form" novalidate class="mb-6">
				<div class="row">

					<div class="col-12 col-lg-6">

						<div class="card">
							<div class="card-header">
								<h4 class="card-header-title">Review cycle</h4>
							</div>
							<div class="card-body">
								<p class="small">We recommend a quarterly cycle of reviews where your team reflects on its performance four times a year. This does not mean you need to change your pay/promotion review process - if you only do that annually or every 6 months for example - but we do recommend you align the ivelop review quarters to your current cycle.</p>
								<p class="small">For example, if you currently do your annual appraisals in February, set the review cycles below to February, May, August and November.</p>
								<fieldset class="mb-4">
									<legend>
										Review cycle settings
									</legend>
									
									<div class="form-group mb-4">
										<label for="ddlTeamSettingsReviewMonth0">First review cycle</label>
										<select class="form-control" v-model="reviewMonth0" id="ddlTeamSettingsReviewMonth0" aria-describedby="ddlTeamSettingsReviewMonth0_Validation">
											<option value="1">January</option>
											<option value="2">February</option>
											<option value="3">March</option>
											<option value="4">April</option>
											<option value="5">May</option>
											<option value="6">June</option>
											<option value="7">July</option>
											<option value="8">August</option>
											<option value="9">Septemper</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</select>
										<span id="ddlTeamSettingsReviewMonth0_Validation" class="feedback"></span>
										<span class="form-text text-muted small"></span>
									</div>
									<div class="form-group mb-4">
										<label for="ddlTeamSettingsReviewMonth1">Second review cycle</label>
										<select class="form-control" v-model="reviewMonth1" id="ddlTeamSettingsReviewMonth1" aria-describedby="ddlTeamSettingsReviewMonth1_Validation">
											<option value="1">January</option>
											<option value="2">February</option>
											<option value="3">March</option>
											<option value="4">April</option>
											<option value="5">May</option>
											<option value="6">June</option>
											<option value="7">July</option>
											<option value="8">August</option>
											<option value="9">Septemper</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</select>
										<span id="ddlTeamSettingsReviewMonth1_Validation" class="feedback"></span>
										<span class="form-text text-muted small"></span>
									</div>
									<div class="form-group mb-4">
										<label for="ddlTeamSettingsReviewMonth2">Third review cycle</label>
										<select class="form-control" v-model="reviewMonth2" id="ddlTeamSettingsReviewMonth2" aria-describedby="ddlTeamSettingsReviewMonth2_Validation">
											<option value="0">None</option>
											<option value="1">January</option>
											<option value="2">February</option>
											<option value="3">March</option>
											<option value="4">April</option>
											<option value="5">May</option>
											<option value="6">June</option>
											<option value="7">July</option>
											<option value="8">August</option>
											<option value="9">Septemper</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</select>
										<span id="ddlTeamSettingsReviewMonth2_Validation" class="feedback"></span>
										<span class="form-text text-muted small">Choose None if you want to skip a 3rd yearly review, although we don't recommend doing that.</span>
									</div>
									<div class="form-group mb-4">
										<label for="ddlTeamSettingsReviewMonth3">First review cycle</label>
										<select class="form-control" v-model="reviewMonth3" id="ddlTeamSettingsReviewMonth3" aria-describedby="ddlTeamSettingsReviewMonth3_Validation">
											<option value="0">None</option>
											<option value="1">January</option>
											<option value="2">February</option>
											<option value="3">March</option>
											<option value="4">April</option>
											<option value="5">May</option>
											<option value="6">June</option>
											<option value="7">July</option>
											<option value="8">August</option>
											<option value="9">Septemper</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</select>
										<span id="ddlTeamSettingsReviewMonth3_Validation" class="feedback"></span>
										<span class="form-text text-muted small">Choose None if you want to skip a 3rd yearly review, although we don't recommend doing that.</span>
									</div>
								</fieldset>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6">
						<div class="card">
							<div class="card-header">
								<h4 class="card-header-title">Reflections and Iterations</h4>
							</div>
							<div class="card-body">
								<p class="small">In each review cycle, team members will be prompted to conduct a self-reflection on their performance. Once completed they will iterate over a to-do list of improvement areas for the remainder of the review cycle.</p>
								<fieldset class="mb-4">
									<legend>
										Reflection settings
									</legend>
									
									<div class="form-group mb-4">
										<label for="txtTeamSettingsIterationDurationDays">Iteration duration (in days)</label>
										<input type="number" class="form-control" v-model="iterationDurationDays" id="txtTeamSettingsIterationDurationDays" aria-describedby="txtTeamSettingsIterationDurationDays_Validation" min="1" max="31" required tabindex="2" />
										<span id="txtTeamSettingsIterationDurationDays_Validation" class="feedback"></span>
										<span class="form-text text-muted small">How long team members spend on a single iteration of improvement.<br/>We recommend 2 or 3 weeks.</span>
									</div>
									
									<div class="form-group mb-4">
										<label for="txtTeamSettingsReflectionDurationDays">Default Reflection duration (in days)</label>
										<input type="number" class="form-control" v-model="reflectionDurationDays" id="txtTeamSettingsReflectionDurationDays" aria-describedby="txtTeamSettingsReflectionDurationDays_Validation" min="1" max="31" required tabindex="2" />
										<span id="txtTeamSettingsReflectionDurationDays_Validation" class="feedback"></span>
										<span class="form-text text-muted small">Default value for how long team members have to complete their self-reflections and mentor feedback. This can be overridden when starting a new Review.<br/>We recommend 2 or 3 weeks.</span>
									</div>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-12 col-lg-6 mb-6">
						<button type="submit" class="btn btn-primary btn-block">{{isFirstEdit ? "Confirm team settings" : "Update team"}} <span class="fe fe-check-circle"></span></button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>