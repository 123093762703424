<script>

export const requireAuth = false;
export const pageName = 'account-sign-in';
export const pageRoute = '/account/sign-in';
export const title = 'Sign-in'
export const furniture = 'plain';

import { getAuth, GoogleAuthProvider, OAuthProvider , signInWithPopup, signInWithEmailAndPassword, fetchSignInMethodsForEmail, GithubAuthProvider } from 'firebase/auth'
import { validation } from '../../js/language.js'
import { getAdditionalUserInfo } from 'firebase/auth'
import { showToast } from '../../js/toast.js';
import { navigate, PageShowError } from '@trullock/page-manager';
import { getLiteUser } from '../../js/readModel.js';
import { publish } from '@trullock/pubsub'
import { functions } from '../../js/functions.js'

export default {
	data() {
		return {
			email: '',
			password: ''
		 }
	},
	methods: {
		resetAuthErrors: function(){
			this.$refs.btnGoogle.classList.remove('glow');
			this.$refs.btnGithub.classList.remove('glow');
			this.$refs.btnMicrosoft.classList.remove('glow');
			this.$refs.txtPassword.classList.remove('glow');
		},
		resetPassword: function(e){
			e.stopPropagation();
			navigate(e.target.getAttribute('href'), { email: this.email })
		},
		submit: async function() {
			this.resetAuthErrors();
			
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true)

			try
			{
				let result = await signInWithEmailAndPassword(getAuth(), this.email, this.password)

				let liteUser = await getLiteUser(result.user.uid);
				publish('user changed', liteUser);
				
				navigate('goal');

				this.analytics(result);
				
				if(this.pendingCred)
				{
					result.user.linkWithCredential(this.pendingCred).then(result => {
						showToast({
							style: 'success',
							message: this.pendingCredLinkedMessage
						})
					}, e => console.error(e));
				}
			}
			catch(error)
			{
				if(error.code == 'auth/wrong-password')
				{
					return fetchSignInMethodsForEmail(getAuth(), this.email).then(methods => {
						if(methods.indexOf('password') == -1)
						{
							this.glowAuthProviderMethods(methods);
							this.$refs.form.setValidationResult(validation['auth/no-password']);
						}
						else
						{
							this.$refs.form.setValidationResult(validation[error.code] || validation['error']);
						}
					});
				}
				
				this.$refs.form.setValidationResult(validation[error.code] || validation['error']);
			}

			this.$refs.form.setSubmitting(false)
		},
		ssoGoogle: function() {
			var provider = new GoogleAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Google account');
		},
		ssoGithub: function() {
			var provider = new GithubAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Github account');
		},
		ssoMicrosoft: function() {
			var provider = new OAuthProvider('microsoft.com');
			this.signInWithSSO(provider, 'Account successfully linked to your Microsoft account');
		},
		signInWithSSO: function(provider, linkedMessage){
			this.resetAuthErrors();
			this.$refs.form.reset(); // to prevent chrome suggesting to save unused details in the form
			
			this.$refs.form.setSubmitting(true, this.$refs.btnSignIn)

			signInWithPopup(getAuth(), provider).then(async signInResult => {
				//publish('user auth-state-changed', result.user);
				let isInvitedToATeam = false;

				let info = getAdditionalUserInfo(signInResult);
				if(info.isNewUser)
				{
					let createResult = await this.issueCreateUserCommand(signInResult.user.displayName);
					isInvitedToATeam = createResult.isInvitedToATeam;
				}

				let liteUser = await getLiteUser(signInResult.user.uid);
				if(!liteUser.emailVerified && signInResult.user.emailVerified)
				{
					await this.emailVerify(signInResult.user.displayName);
					liteUser = await this.emailVerify();
				}
				publish('user changed', liteUser);

				navigate('goal', { fallback: isInvitedToATeam ? '/onboarding/teams/invited' : info.isNewUser ? '/onboarding' : '/'});

				this.analytics(signInResult);

				if(this.pendingCred)
				{
					signInResult.user.linkWithCredential(this.pendingCred).then(result => {
						showToast({
							style: 'success',
							message: this.pendingCredLinkedMessage
						})
					}, e => console.error(e));
				}
			}).catch(error => {

				if(error.code == 'auth/account-exists-with-different-credential')
				{
					this.handleExistingAuth(error, linkedMessage);
					return;
				}

				console.error(error);
				this.$refs.form.setValidationResult(validation[error.code] || validation['error']);
			}).finally(() => {
				
				this.$refs.form.setSubmitting(false, this.$refs.btnSignIn)
			})

		},
		analytics: function(userCredential){
		// TODO:
		// firebase.analytics().logEvent(userCredential.additionalUserInfo?.isNewUser ? 'sign-up' : 'login', {
		// 	method: userCredential.credential.providerId
		// });
		},
		handleExistingAuth: function(error, linkedMessage){
			this.pendingCred = error.credential;
			this.pendingCredLinkedMessage = linkedMessage
			this.email = error.email;

			fetchSignInMethodsForEmail(getAuth(), error.email).then(methods => {
				this.glowAuthProviderMethods(methods);
			});

			this.$refs.form.setValidationResult(validation[error.code]);
		},
		glowAuthProviderMethods: function(methods) {
			if(methods.indexOf('google.com') > -1)
				this.$refs.btnGoogle.classList.add('glow');
			if(methods.indexOf('github.com') > -1)
				this.$refs.btnGithub.classList.add('glow');
			if(methods.indexOf('microsoft.com') > -1)
				this.$refs.btnMicrosoft.classList.add('glow');
			if(methods.indexOf('password') > -1)
				this.$refs.txtPassword.classList.add('glow');
		},
		issueCreateUserCommand: async function(name)
		{
			let result = await functions.userCreate({ name })
			return result.data;
		},
		emailVerify: async function(){
			await functions.userEmailVerify();
		},

		show: function(opts) {
			if(getAuth().currentUser)
				throw new PageShowError('/', 'Already signed in', {}, 'replace')
			
			this.resetAuthErrors();
			this.pendingCred = null;
			this.pendingCredLinkedMessage = null;
			this.$refs.form.reset();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-12 col-md-5 col-xl-4 my-5">
			<h1 class="display-4 text-center mb-3">Sign in</h1>
			<p class="text-muted text-center mb-5">Carry on improving.</p>
			<div class="text-center mb-2">
				<button @click.prevent="ssoGoogle" ref="btnGoogle" class="btn btn-white btn-auth"><img alt="Google icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" width="18" height="18"> Sign in with Google</button>
			</div>
			<div class="text-center mb-2">
				<button @click.prevent="ssoGithub" ref="btnGithub" class="btn btn-white btn-auth"><img alt="Github icon" src="../../img/github.svg" width="18" height="18"> Sign in with Github</button>
			</div>
			<div class="text-center mb-4 d-none">
				<button @click.prevent="ssoMicrosoft" ref="btnMicrosoft" class="btn btn-white btn-auth"><img alt="Microsoft icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg" width="18" height="18"> Sign in with Microsoft</button>
			</div>
			<hr class="mb-4" />
			<form novalidate @submit.prevent="submit" ref="form">
				<input type="hidden" name="returnUrl" />
				<fieldset class="mb-4">
					<legend>Login credentials</legend>
					<div class="form-group mb-4">
						<label for="txtSignInEmail">Email</label>
						<input type="email" class="form-control" name="email" v-model="email" id="txtSignInEmail" aria-describedby="txtSignInEmail_Validation" autocomplete="email" placeholder="e.g. your.name@example.com" required tabindex="1" />
						<span id="txtSignInEmail_Validation" class="feedback"></span>
					</div>
					<div class="form-group mb-4">
						<label for="txtSignInPassword">Password</label>
						<div class="input-group">
							<input type="password" name="password" v-model="password" placeholder="Your password" class="form-control form-control-appended" autocomplete="current-password" required minlength="8" id="txtSignInPassword" ref="txtPassword" aria-describedby="txtSignInPassword_Validation" tabindex="3">
							<div class="input-group-append">
								<button class="btn btn-outline-secondary js-reveal" tabindex="-1" type="button"><span class="fe fe-eye"></span><span class="sr-only">Show/Hide password</span></button>
							</div>
						</div>
						<span id="txtSignInPassword_Validation" class="feedback"></span>
					</div>
				</fieldset>
				<button type="submit" ref="btnSignIn" class="btn btn-lg btn-block btn-primary mb-3">Sign In</button>
				<div class="text-center">
					<p class="small text-muted mb-2"><a href="/account/reset-password" @click.prevent="resetPassword">Reset your password</a> if you've forgotten it.</p>
					<p class="small text-muted">Not got an account yet? <a href="/account/sign-up">Sign up</a> now.</p>
				</div>
			</form>
		</div>
	</div>
</div>
</template>