import { UserTask } from "./userTask.js";

export default class ProvideFeedbackUserTask extends UserTask
{
	menteeId = null
	menteeName = null
	menteeAvatar = null
	reflectionId = null
	
	get order() { return 1 }

	get summary() {
		return 'Provide feedback to ' + this.menteeName
	}
}
