import UserRoleCompentency from "./userRoleCompetency.js"

export default class UserRoleLevel
{
	id = null
	icon = null
	title = null
	path = null
	summary = null
	
	competencies = []

	get allRequiredCriteria()
	{
		return this.competencies.map(c => c.allRequiredCriteria).reduce((prev, curr) => prev.concat(curr), []);
	}

	constructor(roleLevel)
	{
		this.id = roleLevel.id;
		this.icon = roleLevel.icon;
		this.title = roleLevel.title;
		this.path = roleLevel.path;
		this.summary = roleLevel.summary;
		
		this.competencies = roleLevel.competencies.competencies.map(c => new UserRoleCompentency(c))
	}
}