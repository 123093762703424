import AggregateRoot from "../aggregateRoot.js";

export default class User extends AggregateRoot
{
	name = null
	email = null
	avatar = null
	
	static create(id, name, email, avatarUrl, emailVerified)
	{
		let user = new User();
		user.#create(id, name, email, avatarUrl, emailVerified);
		return user;
	}

	#create(id, name, email, avatar, emailVerified)
	{
		this.apply('userCreated', {
			aggregateRootId: id,
			name, 
			email,
			avatar,
			emailVerified
		});
	}

	onUserCreated(event) 
	{
		this.id = event.aggregateRootId;
		this.name = event.name;
		this.email = event.email;
		this.avatar = event.avatar;
		this.emailVerified = event.emailVerified;
	}

	emailVerify() {
		if(this.emailVerified)
			return;

		this.apply('userEmailVerified')
	}

	onUserEmailVerified(event) {
		this.emailVerified = true;
	}

	changeName(name){
		if(name == this.name)
			return;

		this.apply('userNameChanged', { name })
	}

	onUserNameChanged(event){
		this.name = event.name;
	}

	changeAvatar(path){
		if(path == this.avatar)
			return;

		this.apply('userAvatarChanged', { path })
	}

	onUserAvatarChanged(event){
		this.path = event.path;
	}

	changeReminderPreferences(receiveReminders)
	{
		if(this.receiveReminders == receiveReminders)
			return;

		this.apply('userReminderPreferencesChanged', {
			receiveReminders
		})
	}

	onUserReminderPreferencesChanged(event) {
		this.receiveReminders = event.receiveReminders;
	}

	delete()
	{
		this.apply('userDeleted', { })
	}

	onUserDeleted(event) {
		// noop an event listener deletes this object
	}
}