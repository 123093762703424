<script>
	
export const pageName = 'teams-invite';
export const pageRoute = '/teams/{path}/people/invite';
export const title = 'Invite people'

import { getCurrentUserId } from '../../js/auth.js';
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import TopNav from './topnav.vue'

import { replace } from '@trullock/page-manager'

export default {
	data() {
		return {
			inviteUrl: null,
			teamName: '',
			teamPath: '',
			currentUser: null,
			userIsMemberOfATeam: false,
			pane: 'step1',
			copiedVisible: false,
			invitees: [],
			notifyOnAccept: true,
			ready: false
		}
	},
	methods: {
		copyInviteUrl: function() {
			this.$refs.inviteUrl.select();
			document.execCommand('copy');
			this.$refs.inviteUrl.selectionStart = this.$refs.inviteUrl.selectionEnd;

			this.copiedVisible = true;
			window.setTimeout(() => {
				this.copiedVisible = false;
			}, 5000);
		},
		submitStep1: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);

			let addresses = this.addresses.split('\n');
			addresses = addresses
				.map(a => a.toLowerCase().trim())
				.map(a => a.endsWith(',') ? a.substr(0, a.length - 1) : a)
				.filter(a => a.length > 0);
				// TODO: remove invalid stuff
				// TODO: parse "ann other <a.other@example.com>"

			addresses = [...new Set(addresses)];

			let invitees = (await httpsCallable(getFunctions(), 'teamInviteStep1')({
				teamId: this.team.id,
				addresses: addresses
			})).data;

			this.invitees = invitees.map(i => ({
				email: i.email,
				user: i.user,
				admin: false
			}))
			
			if(invitees.length == 0)
				this.pane = 'no-invitees';
			else
				this.pane = 'step2'
			
			this.$refs.form.setSubmitting(false);
		},
		submitStep2: async function() {

			this.$refs.formStep2.setSubmitting(true);

			let response = (await httpsCallable(getFunctions(), 'teamInviteStep2')({
				teamId: this.team.id,
				invitees: this.invitees,
				notifyOnAccept: this.notifyOnAccept
			})).data;

			replace('/teams/' + this.team.path + '/people');

			this.$refs.formStep2.setSubmitting(false);
		},
		backToStep1: function() {
			this.pane = 'step1'
		},
		removeInvitee: function(inviteeIndex) {
			this.invitees.splice(inviteeIndex)
		},
		show: async function() {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(this.options.path);
			this.inviteUrl = window.location.protocol + '//' + window.location.host + '/teams/' + this.team.path + '/join';
			this.invitees = [];
			this.pane = 'step1';
			this.addresses = '';
			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}
</script>
<template>
	<div v-if="ready" class="container">
		<TopNav :team="team" :user="currentUser" current-page="people"></TopNav>

		<div class="row justify-content-center">
			<div class="col-12 col-lg-6 col-xl-6" v-if="pane != 'step2'">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Share to invite</h4>
					</div>
					<div class="card-body">
						<p>Share this link with anyone who you want to join this team by email, Slack or how ever you like.</p>
						<p>Once the person has visited the link, you will need to confirm their application to join from the team dashboard.</p>
						<form>
							<fieldset>
								<legend class="sr-only">
									Team details
								</legend>
								<div class="form-group mb-4">
									<label for="txtTeamsInviteUrl">Invite link</label>
									<input type="text" class="form-control" v-model="inviteUrl" id="txtTeamsInviteUrl" readonly ref="inviteUrl" />
								</div>
							</fieldset>
							<button class="btn btn-outline-primary" @click.prevent="copyInviteUrl">Copy <span class="fe fe-copy"></span></button>
							<span class="ml-2 badge badge-success badge-copied" v-if="copiedVisible">Copied!</span>
						</form>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 col-xl-6" v-if="pane == 'step1' || pane == 'no-invitees'">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Invite by email</h4>
					</div>
					<div class="card-body" v-if="pane == 'step1'">
						<p>Provide the email addresses for all the people you want to invite.</p>
						<p>We'll automatically ignore duplicates and anyone who's already a member of, invited to join or applied join to this team.</p>
						<form class="js-addresses" @submit.prevent="submitStep1" ref="form">
							<fieldset class="mb-2">
								<legend>
									Invitee email addresses
								</legend>
								<div class="form-group mb-4">
									<label for="txtTeamsInviteAddresses">Email addresses</label>
									<textarea type="text" class="form-control" v-model="addresses" id="txtTeamsInviteAddresses" aria-describedby="txtTeamsInviteAddresses_Validation" autocomplete="off" placeholder="joe.bloggs@example.com,&#10;jane.doe@example.co.uk,&#10;&quot;Ann Other&quot; &lt;a.other@example.com&gt;" required tabindex="1"></textarea>
									<span id="txtTeamsInviteAddresses_Validation" class="feedback"></span>
									<span class="form-text text-muted small">Ideally one address per line, but we'll do our best to understand whatever you provide.</span>
								</div>
							</fieldset>
							<button type="submit" class="btn btn-block btn-primary">Next <span class="fe fe-arrow-right-circle"></span></button>
						</form>
					</div>
					<div class="card-body" v-if="pane == 'no-invitees'">
						<p>We couldn't identify any new people to invite. All of the addresses you provided are for people who are already existing members, have already been invited to join or have already applied to join.</p>
						<button @click.prevent="backToStep1" class="btn btn-primary btn-block mb-0 js-back"><span class="fe fe-arrow-left-circle"></span> Back to step 1</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-8" v-if="pane == 'step2'">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Invite by email</h4>
					</div>
					<form ref="formStep2" @submit.prevent="submitStep2">
						<div class="card-body">
							<p class="mb-0">Double check who you want to invite and choose the permissions for each person.</p>
						</div>
						<div class="mb-0">
							<table class="table table-nowrap table-hover card-table">
								<thead>
								<tr>
									<th>Person to invite</th>
									<th>Permissions <span aria-title="Admins have the power to control all the team settings in the same way you do."><span class="fe fe-info"></span></span></th>
									<th class="shrink"></th>
								</tr>
								</thead>
								<tbody>
									<tr v-for="(invitee, index) of invitees">
										<td>
											<template v-if="invitee.user">
												<div class="row align-items-center">
													<div class="col-auto">
														<div class="avatar avatar-sm dropdown-toggle">
															<img :src="invitee.user.avatar" :alt="invitee.user.name" class="avatar-img rounded-circle">
														</div>
													</div>
													<div class="col">
														<div class="mb-0">{{ invitee.user.name }}</div>
														<div class="text-muted small">{{ invitee.email }}</div>
													</div>
												</div>
												<input type="hidden" v-model="invitee.email" />
											</template>
											<template v-else>
												<div class="form-group">
													<input type="text" class="form-control" v-model="invitee.email" readonly />
												</div>
											</template>
										</td>
										<td>
											<div class="form-group">
												<select class="form-control" v-model="invitee.admin">
													<option value="true">Admin</option>
													<option value="false">Non-admin</option>
												</select>
											</div>
										</td>
										<td class="shrink">
											<button v-if="invitees.length > 1" @click.prevent="removeInvitee(index)" class="btn btn-sm btn-outline-danger">Remove</button>
										</td>
									</tr>
								</tbody>
								<tfoot>
								<tr>
									<td colspan="2">
										<div class="field form-group custom-control custom-checkbox">
											<input type="checkbox" id="chkNotifyOnAcceptance" name="NotifyOnAcceptance" v-model="notifyOnAccept" class="form-control custom-control-input no-ajax-validate">
											<label class="custom-control-label" for="chkNotifyOnAcceptance">Notify me when these people accept their invitations</label>
											<span class="form-text text-muted small">We'll send you an email when each person accepts.</span>
										</div>
									</td>
								</tr>
								</tfoot>
							</table>
						</div>
						<div class="card-body">
							<p class="small text-center text-muted">By inviting these people you understand that for each person that accepts their invitations, you will be adding a seat to your subscription.<br/><a href="/teams/@ViewData.Team.Path/billing" target="_blank">View subscription details <sup class="fe fe-external-link"></sup></a></p>
							<button type="submit" class="btn btn-lg btn-block btn-primary">Send invite email{{invitees.length != 1 ? "s" : ""}} and update subscription <span class="fe fe-check-circle"></span></button>
							<button @click.prevent="backToStep1" class="btn btn-white btn-block mb-0 js-back"><span class="fe fe-arrow-left-circle"></span> Back to step 1</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>