<script>
export const pageName = 'insights';
export const pageRoute = '/insights';
export const title = 'Your insights'

import { getCompetencyById } from '../../../functions/domain/roles/roleStore.js';
import { average } from '../../../functions/math.js';
import { getCurrentUserId } from '../../js/auth.js';
import { getReflectionsHistory, getViewUser } from '../../js/readModel.js';
import MeNav from '../menav.vue'
import TimeSeriesChart from '../timeSeriesChart.vue';
import TableChart from '../tableChart.vue';


export default {
	data() {
		return { 
			currentUser: null,
			history: null,
			statsOverTime: null,
			ratingOverTime: null,
			ratingOverTimeLabels: null,
			ready: false
		}
	},

	methods: {
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.history = await getReflectionsHistory(getCurrentUserId());
		
			let competencyTopicStatsOverTime = this.history.getCompetencyTopicStatsOverTime();
			this.statsOverTime = Object.keys(competencyTopicStatsOverTime).map(ck =>
			{
				var competency = getCompetencyById(ck);

				return {
					displayName: competency.title,
					topics: Object.keys(competencyTopicStatsOverTime[ck]).map(tk =>
					{
						var topic = competency.getTopicById(tk);
						return {
							displayName: topic.title,
							statsOverTime: competencyTopicStatsOverTime[ck][tk],
							averageStats: average(competencyTopicStatsOverTime[ck][tk], x => x)
						};
					})
				};
			});


			this.ratingOverTime = this.history.reflections.map(r => r.rating),
			// TODO: localise
			this.ratingOverTimeLabels = this.history.reflections.map(r => r.createdOn.format("dd MMM yy"))

			this.ready = true;
		}
	},
	components: { MeNav, TimeSeriesChart, TableChart },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav current-page="insights" />
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center">
			
			<div class="col-12 col-lg-8">
				<p>This page becomes most useful after a few reflection cycles. It allows you to track your progress across all competencies to see how you are progressing.</p>
				<TimeSeriesChart title="Your overall rating over time" :labels="ratingOverTimeLabels" :data="ratingOverTime"></TimeSeriesChart>
				<section class="card">
					<div class="card-header">
						<h4 class="card-header-title">Your competency ratings over time</h4>
					</div>
						<div v-if="statsOverTime.length > 1" class="table-responsive mb-0">
						<table v-for="competency of statsOverTime" class="table table-sm table-nowrap table-hover card-table">
							<thead>
							<tr>
								<th>{{ competency.displayName }}</th>
								<th class="shrink">Rating</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="topic of competency.topics">
								<td>{{ topic.displayName }}</td>
								<td>
									<TableChart :data="topic.statsOverTime.map(s => s.rating)"></TableChart>
								</td>
							</tr>
							</tbody>
						</table>
						</div>
						<div v-else class="card-body">
							<p class="small text-muted mb-0">There is not yet enough data to display</p>
						</div>
				</section>
			</div>

		</div>
	</div>
</template>
