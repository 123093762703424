import * as pageManager from '@trullock/page-manager';
import { createApp } from 'vue';
import Page from '../js/page.js';

// TODO: make this be automatic somehow

import * as PageLoading from './loading.vue'
registerVuePage(PageLoading);
import * as PageError from './error.vue'
registerVuePage(PageError);
import * as PageError404 from './error-404.vue'
registerVuePage(PageError404);




import * as PageDashboard from './dashboard/dashboard.vue'
registerVuePage(PageDashboard);

import * as PageCompetenciesBrowse from './roles/browse-competencies.vue'
registerVuePage(PageCompetenciesBrowse);

import * as PageCompetenciesBrowseTopicLevels from './roles/browse-competency-topic-levels.vue'
registerVuePage(PageCompetenciesBrowseTopicLevels);

import * as PageBrowseRoles from './roles/browse-roles.vue'
registerVuePage(PageBrowseRoles);

import * as PageBrowseRolesLevel from './roles/browse-roles-level.vue'
registerVuePage(PageBrowseRolesLevel)

import * as PageBrowseRolesMatrix from './roles/browse-roles-matrix.vue'
registerVuePage(PageBrowseRolesMatrix);

import * as PageTeamsCreate from './teams/create.vue'
registerVuePage(PageTeamsCreate);
import * as PageTeamsOverview from './teams/overview.vue'
registerVuePage(PageTeamsOverview)
import * as PageTeamsPeopleInvite from './teams/invite.vue'
registerVuePage(PageTeamsPeopleInvite)
import * as PageTeamsPeople from './teams/people.vue'
registerVuePage(PageTeamsPeople)
import * as PageTeamsSettings from './teams/settings.vue'
registerVuePage(PageTeamsSettings)
import * as PageTeamsRoles from './teams/roles.vue'
registerVuePage(PageTeamsRoles)
import * as PageTeamsBilling from './teams/billing.vue'
registerVuePage(PageTeamsBilling)
import * as PageTeamsJoin from './teams/join.vue'
registerVuePage(PageTeamsJoin)
import * as PageTeamsLeave from './teams/leave.vue'
registerVuePage(PageTeamsLeave)
import * as PageTeamsReviews from './teams/reviews/reviews.vue'
registerVuePage(PageTeamsReviews)
import * as PageTeamsReviewsStart from './teams/reviews/start.vue'
registerVuePage(PageTeamsReviewsStart)
import * as PageTeamsReviewsView from './teams/reviews/view.vue'
registerVuePage(PageTeamsReviewsView)
import * as PageTeamsReviewsEndReflectionPhase from './teams/reviews/endReflectionPhase.vue'
registerVuePage(PageTeamsReviewsEndReflectionPhase)



import * as PageReflectionsNewChooseRole from './reflections/new/chooseRole.vue'
registerVuePage(PageReflectionsNewChooseRole);
import * as PageReflectionsNewChooseRoleLevel from './reflections/new/chooseRoleLevel.vue'
registerVuePage(PageReflectionsNewChooseRoleLevel);
import * as PageReflectionsNewReflection from './reflections/new/newReflection.vue'
registerVuePage(PageReflectionsNewReflection);

import * as PageReflectionsAnswerStart from './reflections/answer/start.vue'
registerVuePage(PageReflectionsAnswerStart);
import * as PageReflectionsAnswerTopic from './reflections/answer/topic.vue'
registerVuePage(PageReflectionsAnswerTopic);
import * as PageReflectionsAnswerTopicLevel from './reflections/answer/topicLevel.vue'
registerVuePage(PageReflectionsAnswerTopicLevel);
import * as PageReflectionsAnswerAll from './reflections/answer/all.vue'
registerVuePage(PageReflectionsAnswerAll);
import * as PageReflectionsAnswerAnswered from './reflections/answer/answered.vue'
registerVuePage(PageReflectionsAnswerAnswered);
import * as PageReflectionsAnswerNext from './reflections/answer/next.vue'
registerVuePage(PageReflectionsAnswerNext);

import * as PageReflectionsAnswerView from './reflections/answer/view.vue'
registerVuePage(PageReflectionsAnswerView);

import * as PageReflectionsSubmit from './reflections/submit.vue'
registerVuePage(PageReflectionsSubmit);

import * as PageReflectionsProvideFeedback from './reflections/provideFeedback.vue'
registerVuePage(PageReflectionsProvideFeedback);

import * as PageReflectionsWaitForFeedback from './reflections/waitForFeedback.vue'
registerVuePage(PageReflectionsWaitForFeedback);

import * as PageReflectionsWaitForIterating from './reflections/waitForIterating.vue'
registerVuePage(PageReflectionsWaitForIterating);

import * as PageReflectionsReviewFeedback from './reflections/reviewFeedback.vue'
registerVuePage(PageReflectionsReviewFeedback);



import * as PageReflectionsAdviceTopic from './reflections/advice/topic.vue'
registerVuePage(PageReflectionsAdviceTopic);
import * as PageReflectionsAdviceCriteria from './reflections/advice/criteria.vue'
registerVuePage(PageReflectionsAdviceCriteria);



import * as PageInsights from './insights/insights.vue'
registerVuePage(PageInsights)
import * as PageInsightsMentee from './insights/mentee.vue'
registerVuePage(PageInsightsMentee)



import * as PageOnboarding from './onboarding/onboarding.vue'
registerVuePage(PageOnboarding);
import * as PageOnboardingTeamSearch from './onboarding/search-team.vue'
registerVuePage(PageOnboardingTeamSearch);
import * as PageOnboardingTeamInvited from './onboarding/invited.vue'
registerVuePage(PageOnboardingTeamInvited);
import * as PageOnboardingTeam from './onboarding/team.vue'
registerVuePage(PageOnboardingTeam);
import * as PageOnboardingIndividual from './onboarding/individual.vue'
registerVuePage(PageOnboardingIndividual);


import './account/sign-out.js';

import * as PageAccountSignIn from './account/sign-in.vue'
registerVuePage(PageAccountSignIn);
import * as PageAccountSignUp from './account/sign-up.vue'
registerVuePage(PageAccountSignUp);
import * as PageAccountResetPassword from './account/reset-password.vue'
registerVuePage(PageAccountResetPassword);
import * as PageAccountTeam from './account/team.vue'
registerVuePage(PageAccountTeam);
import * as PageAccountPreferences from './account/preferences.vue'
registerVuePage(PageAccountPreferences);
import * as PageAccountTermsOfService from './account/terms-of-service.vue'
registerVuePage(PageAccountTermsOfService);

function registerVuePage(page)
{
	pageManager.registerPage(page.pageName, page.pageRoute, class extends Page {

		static fetchHTML = false
		static requireAuth = page.requireAuth !== false;
		static furniture = page.furniture || 'full';

		get title() {
			if(this.app._instance.ctx.title)
				return this.app._instance.ctx.title + ' | ivelop';
			if(page.title)
				return page.title + ' | ivelop';
			
			return 'ivelop';
		}

		beforeHide() {
			if(this.app._instance.ctx.beforeHide)
				return this.app._instance.ctx.beforeHide();
			return true;
		}

		async boot(opts) {
			this.app = createApp(page.default, { options: opts });
			this.app.mount(this.$page)	
			
			// diabolical Vue hack, this will probably break between Vue version upgrades
			if(this.app._instance.ctx.boot)
				await Promise.resolve(this.app._instance.ctx.boot(opts));
		}

		async update(opts)
		{
			// diabolical Vue hack, this will probably break between Vue version upgrades
			// TODO: dont call show, can we have a new method or always call update (on show)
			if(this.app._instance.ctx.show)
				await Promise.resolve(this.app._instance.ctx.show(opts));
			await super.update(opts);
		}

		async show(opts)
		{
			// diabolical Vue hack, this will probably break between Vue version upgrades
			if(this.app._instance.ctx.show)
				await Promise.resolve(this.app._instance.ctx.show(opts));
			await super.show(opts);
		}

		async hide()
		{
			// diabolical Vue hack, this will probably break between Vue version upgrades
			if(this.app._instance.ctx.hide)
				await Promise.resolve(this.app._instance.ctx.hide());
			await super.hide();
		}
	})
}