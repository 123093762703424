import { UserTask } from "./userTask.js";

export default class StartIteratingUserTask extends UserTask
{
	reviewId = null
	reflectionId = null

	get summary() {
		return 'Start iterating to develop your skills'
	}
}
