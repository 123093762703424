export default {
  "id":  "5DD512E4-5F88-4185-B1B3-0DB2A39EC9C6",
  "title": "🛠️ Engineering",
  "path": "engineering",
  "roles": [
    {
      "id": "C5928135-2965-4C01-AFB3-27C6CCED3BDF",
      "icon": "👷",
      "title": "Software Engineer",
      "path": "software-engineer",
      "levels": [
		{
			"id": "0f44e2e4-3f2f-463c-8c32-f5bee8d6caf6",
			"icon": "👷",
			"title": "TEST DO NOT USE",
			"path": "example",
			"summary": "The Junior Engineer is responsible for working with the team around them to both deliver solutions and hone their craft. You’ll be open-minded and sponge-like in your willingness to soak up knowledge. You’ll enjoy solving complex problems, utilising those around you to help where required. You need to be tenacious, sticking with problems and completing tasks to a good standard.",
			"competencies": {
			  "required": [
				"core-competencies/delivering-value/1"
			  ],
			  "recommended": [
			  ]
			}
		},
        {
          "id": "EB27F9AA-A977-4043-9DC5-03CD8F3A8B07",
          "icon": "👷",
          "title": "Junior Software Engineer",
          "path": "junior-software-engineer",
          "summary": "The Junior Engineer is responsible for working with the team around them to both deliver solutions and hone their craft. You’ll be open-minded and sponge-like in your willingness to soak up knowledge. You’ll enjoy solving complex problems, utilising those around you to help where required. You need to be tenacious, sticking with problems and completing tasks to a good standard.",
          "competencies": {
            "required": [
				
				"core-competencies/attitude/1",
              "core-competencies/delivering-value/1",
              "core-competencies/client-focus/1",
              "core-competencies/planning-and-organisation/1",
              "core-competencies/problem-solving/1",
              "core-competencies/communication/1",
              "core-competencies/striving-for-success/2",
              "core-competencies/team-orientation/2"
            ],
            "recommended": [
              "engineering-competencies/software-engineering/1"
            ]
          }
        },
        {
          "id": "CA9C1B1E-D556-4B66-9588-65A1F1C462C4",
          "icon": "👷",
          "title": "Mid-Level Software Engineer",
          "path": "mid-level-software-engineer",
          "summary": "As an engineer, you'll work in a constantly evolving environment, due to technological advances and the strategic direction of their organisation. You'll create, maintain, audit and improve systems to meet particular needs, testing systems to diagnose and resolve system faults. You’ll work to understand and deliver against the product roadmap, ensuring requirements are met to a good standard.",
          "competencies": {
            "required": [
				"core-competencies/attitude/2",
              "core-competencies/delivering-value/2",
              "core-competencies/client-focus/2",
              "core-competencies/planning-and-organisation/2",
              "core-competencies/problem-solving/2",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/3"
            ],
            "recommended": [
              "engineering-competencies/software-engineering/2"
            ]
          }
        },
        {
          "id": "AB51627C-C9F7-4C3B-AFF6-C0489CD1800E",
          "icon": "👷",
          "title": "Senior Software Engineer",
          "path": "senior-software-engineer",
          "summary": "The senior engineer has a responsibility for the delivery of solutions throughout the software delivery cycle. The senior engineer is proactive, identifying bumps in the road before the team gets to them. You’ll coach and help develop the technical skills of the people around you, instilling your knowledge and experience. You’ll ensure that solutions meet the requirements set out by project sponsors, to the agreed timescales.",
          "competencies": {
            "required": [
				"core-competencies/attitude/3",
              "core-competencies/delivering-value/3",
              "core-competencies/client-focus/3",
              "core-competencies/planning-and-organisation/3",
              "core-competencies/problem-solving/3",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
              "engineering-competencies/software-engineering/3"
            ]
          }
        },
        {
          "id": "1307B2A4-BE04-4331-9771-4DF16DCC71E5",
          "icon": "👷",
          "title": "Lead Software Engineer",
          "path": "lead-software-engineer",
          "summary": "A lead engineer is responsible for the day to day running of an agile delivery team, overseeing the delivery of the project/product roadmap. The Lead Engineer cares about and upholds engineering practices, quickly identifying what is and isn’t working within their squad, and fixes those things. The lead has a deep understanding of the technologies their squad is responsible for; ensuring that the solutions the squad are building meet the standards required by the end user. Finally, the lead is a line-manager and motivator, bringing the best out of their team to ensure delivery with accuracy and pace.",
          "competencies": {
            "required": [
				"core-competencies/attitude/4",
              "core-competencies/delivering-value/4",
              "core-competencies/client-focus/4",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/4",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/4",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
              "engineering-competencies/software-engineering/4"
            ]
          }
        }
      ]
    },
	{
		"id": "7c764c67-2331-4276-81db-94d1ae679170",
		"icon": "👷",
		"title": "Hardware Engineer",
		"path": "hardware-engineer",
		"levels": [
		  {
			"id": "89e6dee5-e96d-4e61-8c36-15c2c7992b40",
			"icon": "👷",
			"title": "Junior Hardware Engineer",
			"path": "junior-hardware-engineer",
			"summary": "The Junior Engineer is responsible for working with the team around them to both deliver solutions and hone their craft. You’ll be open-minded and sponge-like in your willingness to soak up knowledge. You’ll enjoy solving complex problems, utilising those around you to help where required. You need to be tenacious, sticking with problems and completing tasks to a good standard.",
			"competencies": {
			  "required": [
				"core-competencies/attitude/1",
				"core-competencies/delivering-value/1",
				"core-competencies/client-focus/1",
				"core-competencies/planning-and-organisation/1",
				"core-competencies/problem-solving/1",
				"core-competencies/communication/1",
				"core-competencies/striving-for-success/2",
				"core-competencies/team-orientation/2"
			  ],
			  "recommended": [
			  ]
			}
		  },
		  {
			"id": "dae058ef-be08-49f0-a914-a72b8d02069d",
			"icon": "👷",
			"title": "Mid-Level Hardware Engineer",
			"path": "mid-level-hardware-engineer",
			"summary": "As an engineer, you'll work in a constantly evolving environment, due to technological advances and the strategic direction of their organisation. You'll create, maintain, audit and improve systems to meet particular needs, testing systems to diagnose and resolve system faults. You’ll work to understand and deliver against the product roadmap, ensuring requirements are met to a good standard.",
			"competencies": {
			  "required": [
				"core-competencies/attitude/2",
				"core-competencies/delivering-value/2",
				"core-competencies/client-focus/2",
				"core-competencies/planning-and-organisation/2",
				"core-competencies/problem-solving/2",
				"core-competencies/communication/3",
				"core-competencies/striving-for-success/3",
				"core-competencies/team-orientation/3"
			  ],
			  "recommended": [
			  ]
			}
		  },
		  {
			"id": "0a69fe33-0b9b-432b-a4c5-90b65974ba36",
			"icon": "👷",
			"title": "Senior Hardware Engineer",
			"path": "senior-hardware-engineer",
			"summary": "The senior engineer has a responsibility for the delivery of solutions throughout the hardware delivery cycle. The senior engineer is proactive, identifying bumps in the road before the team gets to them. You’ll coach and help develop the technical skills of the people around you, instilling your knowledge and experience. You’ll ensure that solutions meet the requirements set out by project sponsors, to the agreed timescales.",
			"competencies": {
			  "required": [
				"core-competencies/attitude/3",
				"core-competencies/delivering-value/3",
				"core-competencies/client-focus/3",
				"core-competencies/planning-and-organisation/3",
				"core-competencies/problem-solving/3",
				"core-competencies/communication/3",
				"core-competencies/striving-for-success/3",
				"core-competencies/team-orientation/4"
			  ],
			  "recommended": [
			  ]
			}
		  },
		  {
			"id": "5daee7ae-6850-4eac-80ba-80028eeecce9",
			"icon": "👷",
			"title": "Lead Hardware Engineer",
			"path": "lead-hardware-engineer",
			"summary": "A lead engineer is responsible for the day to day running of an agile delivery team, overseeing the delivery of the project/product roadmap. The Lead Engineer cares about and upholds engineering practices, quickly identifying what is and isn't working within their squad, and fixes those things. The lead has a deep understanding of the technologies their squad is responsible for; ensuring that the solutions the squad are building meet the standards required by the end user. Finally, the lead is a line-manager and motivator, bringing the best out of their team to ensure delivery with accuracy and pace.",
			"competencies": {
			  "required": [
				"core-competencies/attitude/4",
				"core-competencies/delivering-value/4",
				"core-competencies/client-focus/4",
				"core-competencies/planning-and-organisation/4",
				"core-competencies/problem-solving/4",
				"core-competencies/communication/4",
				"core-competencies/striving-for-success/4",
				"core-competencies/team-orientation/4"
			  ],
			  "recommended": [
			  ]
			}
		  }
		]
	  },
    {
      "id": "0F61D379-7A07-4A34-AC89-97290E02AD5C",
      "icon": "👨‍🎨",
      "title": "Front End Engineer",
      "path": "front-end-engineer",
      "levels": [
        {
          "id": "82096122-AC30-4D51-8B4A-36480602E757",
          "icon": "👨‍🎨",
          "title": "Junior Front End Engineer",
          "path": "junior-front-end-engineer",
          "summary": "Front End Engineers start their career with a knowledge of how to build basic web pages. As they advance, so will their knowledge of HTML, CSS and JavaScript. The Junior Front End Engineer will soak up knowledge from those around them, focusing on delivering value and consistent completion of squad work.",
          "competencies": {
            "required": [
				"core-competencies/attitude/1",
              "core-competencies/delivering-value/1",
              "core-competencies/client-focus/1",
              "core-competencies/planning-and-organisation/1",
              "core-competencies/problem-solving/1",
              "core-competencies/communication/1",
              "core-competencies/striving-for-success/2",
              "core-competencies/team-orientation/2"
            ],
            "recommended": [
              "engineering-competencies/front-end-engineering/1"
            ]
          }
        },
        {
          "id": "3482D916-7074-4216-91E3-DF8C24814B81",
          "icon": "👨‍🎨",
          "title": "Mid-Level Front End Engineer",
          "path": "mid-level-front-end-engineer",
          "summary": "As the technical skill of a Front End Engineer develops, understanding of quality and delivery of value will reflect this. Flexibility and willingness to work across teams is key, helping others understand the impact their changes have on user experience. Responsibilities will include creating and maintaining front end assets, considering testing and performance.",
          "competencies": {
            "required": [
				"core-competencies/attitude/2",
              "core-competencies/delivering-value/2",
              "core-competencies/client-focus/2",
              "core-competencies/planning-and-organisation/2",
              "core-competencies/problem-solving/2",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/3"
            ],
            "recommended": [
              "engineering-competencies/front-end-engineering/2"
            ]
          }
        },
        {
          "id": "12ADA454-78C6-4890-AA26-59A0F2574919",
          "icon": "👨‍🎨",
          "title": "Senior Front End Engineer",
          "path": "senior-front-end-engineer",
          "summary": "A senior Front End Engineer takes responsibility for the web estate, seeking continuous improvement and setting out best practices for the rest of the Engineering team. The senior engineer ensures that all front end assets are monitored and alerted against. The senior must also have great people skills, identifying potential and coaching opportunities within the Engineering team.",
          "competencies": {
            "required": [
				"core-competencies/attitude/3",
              "core-competencies/delivering-value/3",
              "core-competencies/client-focus/3",
              "core-competencies/planning-and-organisation/3",
              "core-competencies/problem-solving/3",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
              "engineering-competencies/front-end-engineering/3"
            ]
          }
        },
        {
          "id": "0E24879B-7F54-4271-A87B-DC8858A11A3F",
          "icon": "👨‍🎨",
          "title": "Lead Front End Engineer",
          "path": "lead-front-end-engineer",
          "summary": "The lead Front End Engineer sets out the standard for the rest of the team. The lead ensures that the web estate is monitored, alerted against and thresholds are set. The lead is responsible for reporting on the performance of the front end, identifying and putting into action any required improvements. Line management of Front End Engineers in various teams, maintaining a consistent identity is important.",
          "competencies": {
            "required": [
				"core-competencies/attitude/4",
              "core-competencies/delivering-value/4",
              "core-competencies/client-focus/4",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/4",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/4",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
              "engineering-competencies/front-end-engineering/4"
            ]
          }
        }
      ]
    },
    {
      "id": "8B0D3B10-42E2-43CF-ABA2-63FAD5775076",
      "icon": "🕵️",
      "title": "Test Engineer",
      "path": "test-engineer",
      "levels": [
        {
          "id": "6C29B2A3-FF64-4ADD-8017-6F582762259A",
          "icon": "🕵️",
          "title": "Junior Test Engineer",
          "path": "junior-test-engineer",
          "summary": "A Junior Test Engineer starts their career with an analytical and logical mindset. They generally have an eye for detail and care about reducing waste. The first step in a Test Engineers career is performing manual testing. They will build test plans, test scripts and discover bugs.",
          "competencies": {
            "required": [
				"core-competencies/attitude/1",
              "core-competencies/delivering-value/1",
              "core-competencies/client-focus/1",
              "core-competencies/planning-and-organisation/1",
              "core-competencies/problem-solving/1",
              "core-competencies/communication/1",
              "core-competencies/striving-for-success/2",
              "core-competencies/team-orientation/2"
            ],
            "recommended": [
              "engineering-competencies/testing/1"
            ]
          }
        },
        {
          "id": "B4EFA333-AE31-4B3B-8DF4-CD4627010EFF",
          "icon": "🕵️",
          "title": "Mid-Level Test Engineer",
          "path": "mid-level-test-engineer",
          "summary": " The Mid-Level Test Engineer is the bastion for quality in each squad. They proactively take responsibility for ensuring every piece of work created by the team meets acceptable levels of usability before it's released to users. Test Engineers will spend time with other Engineers, inputting into how software is built, identifying risks and potential pitfalls before they're realised.",
          "competencies": {
            "required": [
				"core-competencies/attitude/2",
              "core-competencies/delivering-value/2",
              "core-competencies/client-focus/2",
              "core-competencies/planning-and-organisation/2",
              "core-competencies/problem-solving/2",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/3"
            ],
            "recommended": [
              "engineering-competencies/testing/2"
            ]
          }
        },
        {
          "id": "E005BB55-2CBE-4E26-9368-55841765D0CE",
          "icon": "🕵️",
          "title": "Senior Test Engineer",
          "path": "senior-test-engineer",
          "summary": "A Senior Test Engineer is interested in automation and continuous improvement. They will coach quality into those around them, calling out when standards are not met, setting out what good looks like. The senior will be a champion for excellence, encouraging TDD and shared responsibility for quality. The senior will be an active member of the Testing community, staying abreast of testing technologies and methodologies.",
          "competencies": {
            "required": [
				"core-competencies/attitude/3",
              "core-competencies/delivering-value/3",
              "core-competencies/client-focus/3",
              "core-competencies/planning-and-organisation/3",
              "core-competencies/problem-solving/3",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
              "engineering-competencies/testing/3"
            ]
          }
        },
        {
          "id": "08E806AF-3050-4DF3-B4D9-4A649A3363BE",
          "icon": "🕵️",
          "title": "Lead Test Engineer",
          "path": "lead-test-engineer",
          "summary": "As a Lead Test Engineer, you’ll have had previous, strong QA experience throughout the full software development life-cycle. You'll take responsibility for testing strategy, presenting your ideas and suggestions to the wider Engineering team. You'll analyse the SDLC, identifying areas in which quality requires improvement, overseeing the implementation of those improvements. Management of the Testing Team, coaching of Testers and ensuring delivery of value, are all key activities in this role.",
          "competencies": {
            "required": [
				"core-competencies/attitude/4",
              "core-competencies/delivering-value/4",
              "core-competencies/client-focus/4",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/4",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/4",
              "core-competencies/team-orientation/4"
            ]
          },
          "recommended": [
            "engineering-competencies/test/4"
          ]
        }
      ]
    },
    {
      "id": "268C269F-948E-41F0-B37C-98D7C26042FD",
      "icon": "👨‍⚕️",
      "title": "Infrastructure Engineer",
      "path": "infrastructure-engineer",
      "levels": [
        {
          "id": "F0DF58F8-BE8C-4875-BCF6-F439D27FF3F0",
          "icon": "👨‍⚕️",
          "title": "Junior Infrastructure Engineer",
          "path": "junior-infrastructure-engineer",
          "summary": "The Junior Infrastructure Engineer starts their career with an enthusiasm and interest in server and network administration. Previous experience in an IT or desktop support role is beneficial. The junior is responsible for maintenance of cloud based infrastructure, troubleshooting OS and Network issues. They'll ensure that the Engineering team can release software by supporting the CI/CD pipelines. ",
          "competencies": {
            "required": [
				"core-competencies/attitude/1",
              "core-competencies/delivering-value/1",
              "core-competencies/client-focus/1",
              "core-competencies/planning-and-organisation/1",
              "core-competencies/problem-solving/1",
              "core-competencies/communication/1",
              "core-competencies/striving-for-success/2",
              "core-competencies/team-orientation/2"
            ]
          }
        },
        {
          "id": "DECE42A2-9630-4C45-99DD-61AC8BC12CA7",
          "icon": "👨‍⚕️",
          "title": "Mid-Level Infrastructure Engineer",
          "path": "mid-level-infrastructure-engineer",
          "summary": "A good mid-level Infrastructure Engineer will take responsibility for maintenance and configuration of cloud based servers and networking. As part of this role, analysis of the current infrastructure real estate, and whether services currently implemented are still the best tools for the job. Monitoring and alerting is another aspect of responsibility, and must be appropriate, alerting the correct parties.",
          "competencies": {
            "required": [
				"core-competencies/attitude/2",
              "core-competencies/delivering-value/2",
              "core-competencies/client-focus/2",
              "core-competencies/planning-and-organisation/2",
              "core-competencies/problem-solving/2",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/3"
            ]
          }
        },
        {
          "id": "21BEF1DA-1468-4713-8ED2-CEEADB2A6AD3",
          "icon": "👨‍⚕️",
          "title": "Senior Infrastructure Engineer",
          "path": "senior-infrastructure-engineer",
          "summary": "A senior Infrastructure Engineer leads the design and implementation of infrastructure solutions. The senior understands the broader business goals, taking into account requirements from stakeholders throughout the build process. A senior will take responsibility for technical direction for the planning, designing, and execution of testing efforts. The senior will coach other team members, and members of the engineering team, handing responsibility of basic tasks over, freeing themselves up for wider ranging considerations.",
          "competencies": {
            "required": [
				"core-competencies/attitude/3",
              "core-competencies/delivering-value/3",
              "core-competencies/client-focus/3",
              "core-competencies/planning-and-organisation/3",
              "core-competencies/problem-solving/3",
              "core-competencies/communication/3",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/4"
            ]
          }
        },
        {
          "id": "E4AC610D-E044-4B4E-9BDD-7AB035BCB7CC",
          "icon": "👨‍⚕️",
          "title": "Lead Infrastructure Engineer",
          "path": "lead-infrastructure-engineer",
          "summary": "The main focus of the Infrastructure Lead is to lead and manage the Infrastructure team. The lead handles oversees delivery of cloud services to the rest of the business, primarily Data and Engineering. Part of the role is ensuring that services are reliable, scalable, and secure. The lead takes control of costings, ensuring value for money and predictability via cost analysis. The development of the Infrastructure team, via thoughtful leadership and coaching is important. The team must keep up to date on the latest advancements in cloud technology, so that, the business can do the same.",
          "competencies": {
            "required": [
				"core-competencies/attitude/4",
              "core-competencies/delivering-value/4",
              "core-competencies/client-focus/4",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/4",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/4",
              "core-competencies/team-orientation/4"
            ]
          }
        }
      ]
    },
    {
      "id": "4326D0D2-FEE7-4B7C-BA6E-D6A00E4909E4",
      "icon": "👨‍✈️",
      "title": "Software Architect",
      "path": "software-architect",
      "levels": [
        {
          "id": "B96A7CFF-41E1-4B5D-A165-38ED9C7B67FE",
          "icon": "👨‍✈️",
          "title": "Software Architect",
          "path": "software-architect",
          "summary": "A software architect needs to interact with users, Product Managers, and Engineers in order to envision, model and provide initial models and designs that can be built.  The role of a software architect includes collaborative working with humility and providing mentoring as required. The architect should sit across all squads, providing assistance and advice to all squad members, on system design best practices.",
          "competencies": {
            "required": [
				"core-competencies/attitude/4",
              "core-competencies/delivering-value/4",
              "core-competencies/client-focus/4",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/4",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/4",
              "core-competencies/team-orientation/4"
            ]
          }
        }
      ]
    },
    {
      "id": "981B7E81-F104-4DB4-939B-106A9A6E8A58",
      "icon": "👩‍💼",
      "title": "Senior Leader",
      "path": "senior-leader",
      "summary": "Senior Leaders are responsible for the smooth running of the Engineering Team. A great communicator, you'll help to remove impediments and keep the gears moving within the squads so that solutions are delivered effectively. You'll pro-actively identify process issues, suggest and implement improvements to those. You'll need to be a leader and coach team leads and engineers on what good looks like.",
      "levels": [
        {
          "id": "78D924A6-692E-454A-8C92-B143AF417E90",
          "icon": "👨‍💼",
          "title": "Head of Engineering",
          "path": "head-of-engineering",
          "summary": "",
          "competencies": {
            "required": [
				"core-competencies/attitude/4",
              "core-competencies/delivering-value/4",
              "core-competencies/client-focus/4",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/4",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/4",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
              "engineering-competencies/software-engineering/4"
            ]
          }
        },
        {
          "id": "A4223368-A62F-4139-B346-4E736D888F3B",
          "icon": "👩‍💼",
          "title": "Chief Technology Officer",
          "path": "chief-technology-officer",
          "summary": "",
          "competencies": {
            "required": [
				"core-competencies/attitude/4",
              "core-competencies/delivering-value/4",
              "core-competencies/client-focus/4",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/4",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/4",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
              "engineering-competencies/software-engineering/4"
            ]
          }
        }
      ]
    }
  ]
}