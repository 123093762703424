<script>
import { getCurrentUserId } from '../../js/auth.js';
import { getViewUser } from '../../js/readModel.js';


export const pageName = 'onboarding-team-search';
export const pageRoute = '/onboarding/teams/search';
export const title = 'Get started'

export default {
	data() {
		return { 
			currentUser: null,
			ready: false
		}
	},
	methods: {
		async show(opts)
		{
			this.currentUser = await getViewUser(getCurrentUserId());
			this.ready = true;
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<section v-if="ready" class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-6 col-xl-8">
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">
								<h6 class="header-pretitle">Join team</h6>
								<h1 class="header-title">Get invited</h1>
							</div>
						</div>
					</div>
				</div>
				<h2 class="text-center mb-4">Get your team's join-link</h2>
				<p class=" text-center mb-4">You need to ask your team's administrator to share your team's join-link with you.</p>
				<p class=" text-center mb-4">Visit that link to request to join your team.</p>
				<p class="text-primary display-4 text-center my-5">OR</p>
				<h2 class="text-center mb-4">Send your team's admin your email address</h2>
				<p class=" text-center mb-4">Share your email address (<strong>{{ currentUser.email }}</strong>) with<br/>your team's administrator and ask them to invite you to join.</p>
				<p class=" text-center mb-4">You'll receive an email with a link to accept your invitation.<br/>You can also accept the invitation from your <a href="/">dashboard</a>.</p>
			</div>
		</div>
	</section>
</template>