<script>
export const pageName = 'reflections-answer-next';
export const pageRoute = '/reflections/{reflectionId}/answer/next';

import { navigate } from '@trullock/page-manager'
import { getRoleById } from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { getReflectionView,  getViewUser } from '../../../js/readModel.js';
import UserRole from '../../../../functions/domain/userRole.js';
import ReflectionState from '../../../../functions/domain/reflections/reflectionState.js';

export default {
	data() {
		return { }
	},
	methods: {
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			this.reflectionView = await getReflectionView(opts.reflectionId)

			if(this.reflectionView.state != ReflectionState.Unsubmitted)
				navigate('/', { action: 'replace' })

			this.role = getRoleById(this.reflectionView.roleId);
			this.roleLevel = this.role.getLevelById(this.reflectionView.levelId);
			var userRole = new UserRole(this.role, this.roleLevel);
            userRole.applyAnswers(this.reflectionView.answers);
			
			for(let competency of userRole.level.competencies)
			for(let topic of competency.topics)
			for(let level of topic.requiredLevels)
			{
				if(level.answerCompleteness < 100)
				{
					navigate(`/reflections/${this.reflectionView.id}/answer/${competency.path}/${topic.path}/${level.path}`, { action: 'replace' })
					return;
				}
			}

			navigate(`/reflections/${this.reflectionView.id}/submit`, { action: 'replace' })
		}
	},
	props: [ 'options' ]
}

</script>
<template>

</template>