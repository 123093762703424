export default {
	"id": "e31ed164-0867-4c50-9878-87187f784077",
	"title": "👨‍🎨 Design",
	"path": "design",
	"roles": [
		{
			"id": "2996057b-ba62-4e51-9ea0-2c05ca4c2331",
			"icon": "👨‍🎨",
			"title": "Designer",
			"path": "designer",
			"levels": [
				{
					"id": "1b1dafb9-e857-447c-af9b-d252940942e4",
					"icon": "👨‍🎨",
					"title": "Designer",
					"path": "designer",
					"summary": "",
					"competencies": {
						"required": [
							"core-competencies/attitude/2",
							"core-competencies/delivering-value/2",
							"core-competencies/client-focus/2",
							"core-competencies/planning-and-organisation/2",
							"core-competencies/problem-solving/2",
							"core-competencies/communication/2",
							"core-competencies/striving-for-success/3",
							"core-competencies/team-orientation/2"
						],
						"recommended": [
						]
					}
				},
				{
					"id": "699b2302-22a1-4177-a76c-7881bb094d5d",
					"icon": "👩‍🎨",
					"title": "Senior Designer",
					"path": "senior-designer",
					"summary": "",
					"competencies": {
						"required": [
							"core-competencies/attitude/3",
							"core-competencies/delivering-value/3",
							"core-competencies/client-focus/3",
							"core-competencies/planning-and-organisation/3",
							"core-competencies/problem-solving/3",
							"core-competencies/communication/3",
							"core-competencies/striving-for-success/4",
							"core-competencies/team-orientation/3"
						],
						"recommended": [
						]
					}
				}
			]
		}
	]
}