<script>

export const pageName = 'browse-roles-matrix';
export const pageRoute = '/browse/roles/{role}';
export const title = 'Role matrix'

import { getRoleByPath } from '../../../functions/domain/roles/roleStore.js';
import { distinct } from '../../../functions/arrayFunctions.js'
export default {
	data() {
		let role = getRoleByPath(this.options.role);

		let topics = distinct(role.levels.flatMap(l => l.competencies.competencies.flatMap(c => c.topics.map(t => ({
			competencyPath: c.path,
			competencyTitle: c.title,
			topic: t
		})))), t => t.topic.id);
		
		return {
			role: role,
			topics: topics
		}
	},
	props: [ 'options' ]
};

</script>

<template>
<div class="header">
    <div class="container">
        <div class="header-body">
            <nav class="h5" aria-label="breadcrumb">
                <ol class="breadcrumb pt-0 pb-1">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/browse/roles">Roles</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ role.title }}</li>
                </ol>
            </nav>
            <h1 class="header-title">{{ role.displayName }}</h1>
        </div>
    </div>
</div>
<main class="container">
    <div class="row">
        <div class="col-12">
            <section class="card">
                <div class="card-header">
                    <h4 class="card-header-title">Role matrix</h4>
                </div>
                <div class="mb-0">
                    <table class="table table-sm table-hover card-table">
                        <thead>
                            <tr>
                                <th>Competency</th>
                                <th>Topic</th>
								<th class="text-center" v-for="level of role.levels">
									<a :href="`/browse/roles/${role.path}/${level.path}`">{{ level.title }}</a>
								</th>
							</tr>
                        </thead>
                        <tbody>
							<tr v-for="topic of topics">
								<td>{{ topic.competencyTitle }}</td>
								<td><a :href="`/browse/competencies/${topic.competencyPath}/${topic.topic.path}`">{{ topic.topic.title }}</a></td>
								<td class="text-center" v-for="level of role.levels">
									<span>{{ level.competencies.getHighestRequiredTopicLevel(topic.topic.id)?.title }}</span>
								</td>
							</tr>  
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</main>
</template>
