<script>

export const pageName = 'onboarding';
export const pageRoute = '/onboarding';
export const title = 'Get started'

export default {
	data() {
		return { }
	}
}

</script>
<template>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-6 my-5">
				<h1 class="display-4 text-center mb-3">Let's get started</h1>
				<p class="text-muted text-center mb-3 mb-md-6">Choose how you want to use ivelop.</p>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-12 col-md-4 text-center mb-5">
				<span class="display-2 fe fe-users d-block mb-3 text-primary"></span>
				<h2 class="display-5 mb-3">A team member</h2>
				<p class="text-muted mb-5">I'm part of an existing team,<br/>I want to join my colleagues.<br class="d-none d-md-block" /></p>
				<a href="/onboarding/teams/search" class="btn btn-lg btn-primary">Join a team <span class="fe fe-arrow-right-circle"></span></a>
			</div>
			<div class="col-12 col-md-4 text-center mb-5 order-md-last">
				<span class="display-2 fe fe-users d-block mb-3 text-primary"></span>
				<h2 class="display-5 mb-3">A team leader</h2>
				<p class="text-muted mb-5">I'm a team leader,<br/>I want to set this up for my team.<br class="d-none d-md-block" /></p>
				<a href="/teams/create" class="btn btn-lg btn-outline-primary">Create a team <span class="fe fe-arrow-right-circle"></span></a>
			</div>
			<div class="col-12 col-md-4 text-center mb-5 order-md-first">
				<span class="display-2 fe fe-user d-block mb-3 text-primary"></span>
				<h2 class="display-5 mb-3">An individual</h2>
				<p class="text-muted mb-5">I'm an individual,<br />I want to use this by myself.</p>
				<a href="/onboarding/individual" class="btn btn-lg btn-outline-primary">Start as individual <span class="fe fe-arrow-right-circle"></span></a>
			</div>
		</div>
	</div>
</template>