export const validation = {
	'error': {
		email: 'An error occurred, please try again'
	},
	
	'auth/email-already-in-use': {
		email: "Already in use"
	},
	'auth/invalid-email': {
		email: "Not a valid email address"
	},
	'auth/weak-password': {
		password: "Password is not strong enough"
	},
	'auth/wrong-password': {
		password: "Wrong password"
	},
	'auth/user-not-found': {
		email: 'Email address not found'
	},
	'auth/user-disabled': {
		email: 'Account is disabled'
	},

	'auth/account-exists-with-different-credential': {
		email: 'This email address is already associated with a different sign-in mechanism. Sign in with a previous mechanism first.'
	},

	'auth/no-password': {
		email: 'This email address is associated with a different sign-in mechanism.'
	},

	messages: {
		required: 'Required',
		invalidEmail: 'Not a valid email address',
		minLength: l => 'Must be at least ' + l + ' characters',
		invalidFormat: 'Not in the required format',
		breached: n => 'This is a common password, choose something more unique. <a href="/app/account/password-breaches" target="_blank">Learn more about our password security</a>.'
	}
}

export const toast = {
	'password-changed': 'Your password has been changed'
}

export const authProviders = {
	'google.com': 'Google',
	'github.com': 'Github'
}