import ReflectionState from "./reflectionState.js";

export default class ReflectionsHistory
{
	id = null
	reflections = []

	addReflection(reviewId, reflectionId, createdOn, name, roleId, levelId)
	{
		this.reflections.push({
			reviewId,
			reflectionId,
			createdOn,
			name,
			roleId,
			levelId,
			state: ReflectionState.Unsubmitted
		})
	}

	submitReflection(reflectionId, userRole)
	{
		let reflection = this.reflections.find(r => r.reflectionId == reflectionId);
		reflection.state = ReflectionState.Submitted;

		let stats = userRole.getStats();
		reflection.rating = stats.rating;
		reflection.competencyTopicStats = userRole.getCompetencyTopicStats()
	}

	
	getCompetencyTopicStatsOverTime()
	{
		var dictionary = {};

		for (let reflection of this.reflections)
		{
			for (let competencyId in reflection.competencyTopicStats)
			{
				if(!dictionary[competencyId])
					dictionary[competencyId] = {};

				for (let topidId in reflection.competencyTopicStats[competencyId])
				{
					if(!dictionary[competencyId][topidId])
						dictionary[competencyId][topidId] = [];

					// TODO: does this need ordering?
					dictionary[competencyId][topidId].push(reflection.competencyTopicStats[competencyId][topidId]);
				}
			}
		}

		return dictionary;
	}
}