<script>
export const pageName = 'reflections-answer-submit';
export const pageRoute = '/reflections/{reflectionId}/submit';
export const title = 'Submit reflection'

import { getCurrentUserId } from '../../js/auth.js';
import { getReflectionView, getViewTeam, getViewUser } from '../../js/readModel.js';
import {navigate, PageShowError} from '@trullock/page-manager'
import MeNav from '../menav.vue'
import ReflectionState from '../../../functions/domain/reflections/reflectionState.js';
import { functions } from '../../js/functions.js';
import { ReviewPhase } from '../../../functions/domain/reviews/reviewPhase.js';

export default {
	data() {
		return {
			currentUser: null,
			reflectionId: null,
			completeness: 0,
			ready: false
		}
	},
	methods: {
		async submit() {

			this.$refs.form.setSubmitting(true)

			await functions.reflectionSubmitAnswers({
				reflectionId: this.reflectionId
			});

			this.$refs.form.setSubmitting(false)

			if(this.currentUser.isMemberOfATeam)
			{
				if(this.currentUser.membership.mentors.length)
					return navigate(`/reflections/${this.reflectionId}/wait-for-feedback`)

				let team = await getViewTeam(this.currentUser.membership.teamId);
				if(team.currentReviewPhase == ReviewPhase.iterating)
					return navigate(`/reflections/${this.reflectionId}/advice`)

				return navigate(`/reflections/${this.reflectionId}/wait-for-iterating`)
			}

			return navigate(`/reflections/${this.reflectionId}/advice`)
			
		},

		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			let reflectionView = await getReflectionView(opts.reflectionId)
			this.reflectionId = reflectionView.id;

			if(reflectionView.state != ReflectionState.Unsubmitted)
				throw new PageShowError(`/`, 'Cannot submit a submitted Reflection', {}, 'replace')
			
			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>

<div v-if="ready" class="container">

	<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav />
					</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center mb-4">
            <div class="col-12 col-md-6 text-center">
                <h1>Submit reflection</h1>
				<p v-if="currentUser.isMemberOfATeam" class="mb-5">Once you're happy that you've answered all the questions honestly and accurately, submit them to complete this part of the process.</p>
                <p v-else class="mb-5">Once you're happy that you've answered all the questions honestly and accurately, submit them to start getting feedback on how to develop.</p>
                <div class="row mb-4">
                    <div class="col">
                        <div class="mb-4">
							<a :href="`/reflections/${reflectionId}/answer`" class="btn btn-lg btn-block btn-white">Edit answers <span class="fe fe-edit"></span></a>
						</div>
                    </div>
                    <div class="col">
                        <form @submit.prevent="submit" ref="form">
                        	<button type="submit" class="btn btn-lg btn-block btn-primary">Submit answers <span class="fe fe-check-circle"></span></button>
						</form>
                    </div>
                </div>
            </div>
        </div>
</div>

</template>