<script>
export const pageName = 'reflections-advice-criteria';
export const pageRoute = '/reflections/{reflectionId}/advice/{competencyId}/{topicId}';
export const title = 'Choose iteration criteria'

import UserRole from '../../../../functions/domain/userRole.js';
import { getRoleById} from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { functions } from '../../../js/functions.js';
import { getReflectionView, getViewTeam, getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'
import {navigate} from '@trullock/page-manager'
import { ratingStars } from '../../../js/utils.js';
import Reflection from '../../../../functions/domain/reflections/reflection.js';


export default {
	data() {
		return {
			currentUser: null,
			reflectionId: null,
			criteria: [],
			iterationDurationDays: Reflection.DefaultIterationDurationDays,
			showTackled: false,
			ready: false
		}
	},
	methods: {
		async submit() {

			this.$refs.form.setSubmitting(true)

			var selectedCriteriaIds = this.criteria.filter(c => c.selected).map(c => c.id);

			await functions.reflectionStartIteration({
				reflectionId: this.reflectionId,
				competencyId: this.competencyId,
				topicId: this.topicId,
				criteria: selectedCriteriaIds,
				iterationDurationDays: this.iterationDurationDays
			})

			// // tood: say thanks?
			// navigate(`/`)
				
			this.$refs.form.setSubmitting(false)
		},
		ratingStars: ratingStars,
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			let reflectionView = await getReflectionView(opts.reflectionId)
			this.reflectionId = reflectionView.id;

			let role = getRoleById(reflectionView.roleId);
			let roleLevel = role.getLevelById(reflectionView.levelId);

			var userRole = new UserRole(role, roleLevel);
            userRole.applyAnswers(reflectionView.answers);
			userRole.applyFeedback(reflectionView.feedback);
			userRole.applyIterations(reflectionView.iterations);

			this.competencyId = opts.competencyId;
			let userRoleCompentency = userRole.level.competencies.find(c => c.id == opts.competencyId);
			this.topicId = opts.topicId;
			let userRoleTopic = userRoleCompentency.topics.find(t => t.id == opts.topicId);

			this.criteria = userRoleTopic.advisoryCriteria.map(c => ({
				id: c.id,
				text: c.text,
				answer: c.answer,
				iteratedOn: c.iteratedOn,
				selected: false
			}))
			this.criteria.sort((a, b) => a.iteratedOn == b.iteratedOn ? a.answer - b.answer : a.iteratedOn - b.iteratedOn)

			this.showTackled = this.criteria.filter(c => c.iteratedOn > 0).length != 0;

			if(this.currentUser.isMemberOfATeam)
			{
				let team = await getViewTeam(this.currentUser.membership.teamId);
				this.iterationDurationDays = team.iterationDurationDays;
			}

			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>
	
<div v-if="ready" class="container">

	<div class="row">
		<div class="col">
			<div class="header">
				<div class="header-body pt-0">
					<MeNav />
				</div>
			</div>
		</div>
	</div>

	<div class="row justify-content-center mb-4">
		<div class="col-12 col-md-6 text-center">
			<h1>Select iteration criteria</h1>
			<p>Select approximately 3 criteria to work on over the next {{iterationDurationDays}} days.</p>
			<p v-if="currentUser.isMemberOfATeam">Ideally you'll <strong>have a conversation with your manager or mentor</strong> so that they can help you choose the criteria which will bring the most value to you and your team.<br/>Now would be a good time to go and have a chat with them.</p>
		</div>
	</div>

	<form ref="form" @submit.prevent="submit">
		<div class="row">
			<div class="col">
				<div class="card">
					<table class="table table-sm table-hover card-table">
						<thead>
							<tr>
								<th class="d-none d-sm-table-cell shrink">Priority</th>
								<th>Criterion</th>
								<th v-if="showTackled" class="text-center shrink"><span>Tackled <span aria-title="Whether you've made sufficient progress on this criterion or not"><span class="fe fe-info"></span></span></span></th>
								<th class="text-center shrink"><span class="d-none d-sm-inline">Selected <span aria-title="Select around 3 criteria to work on this iteration. You can select ones you've worked on previously to further improve."><span class="fe fe-info"></span></span></span></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(criterion, i) of criteria" :class="criterion.iteratedOn > 0 ? 'table-light' : null">
								<td class="d-none d-sm-table-cell">{{ ++i }}</td>
								<td>{{ criterion.text }}</td>
								<td v-if="showTackled" class="shrink text-center"><span v-if="criterion.iteratedOn" class="fe text-primary fe-check-square"></span></td>
								<td class="shrink text-center">
									<div class="custom-control custom-checkbox">
										<input type="checkbox" v-model.bool="criterion.selected" class="custom-control-input" :id="`cbAdviceCriteria${criterion.id}`">
										<label class="custom-control-label" :for="`cbAdviceCriteria${criterion.id}`"></label>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-12 col-md-6">
				<button type="submit" class="btn btn-lg btn-block btn-primary">Start iteration <span class="fe fe-check-circle"></span></button>
			</div>
		</div>
	</form>
</div>

</template>