<script>

export const pageName = 'onboarding-team';
export const pageRoute = '/onboarding/teams/{team}';
export const title = 'Get started'

export default {
	data() {
		return { 
			configureTeamHref: '/teams/' + this.options.team
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<section class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-6 col-xl-8">
				<div class="header mt-md-5">
					<div class="header-body">
						<div class="row align-items-center">
							<div class="col">
								<h6 class="header-pretitle">New team</h6>
								<h1 class="header-title">Team created!</h1>
							</div>
						</div>
					</div>
				</div>
				<h2 class="text-center mb-4">What next?</h2>
				<p class=" text-center mb-4">Before your team can start using ivelop, you have a few setup tasks to do.</p>
				<p class=" text-center mb-4">You need to configure some review settings, any job roles that are specific to your team, invite team members and set up billing.</p>
				<p class="mb-4 text-center"><a :href="configureTeamHref" class="btn btn-lg btn-block btn-primary js-button">Configure team <span class="fe fe-arrow-right-circle"></span></a></p>
			</div>
		</div>
	</section>
</template>