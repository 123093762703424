<script>
export const pageName = 'browse-competencies';
export const pageRoute = '/browse/competencies';
export const title = 'Browse competencies'

import { allCompetencies } from '../../../functions/domain/roles/roleStore.js';

export default {
	data() {
		return { 
			competencies: allCompetencies()
		}
	},
	props: [ 'options' ]
}
</script>

<template>
	<div class="header">
		<div class="container">
			<div class="header-body">
				<nav class="h5" aria-label="breadcrumb">
					<ol class="breadcrumb pt-0 pb-1">
						<li class="breadcrumb-item"><a href="/">Home</a></li>
						<li class="breadcrumb-item active" aria-current="page">Competencies</li>
					</ol>
				</nav>
				<h1 class="header-title">All Competencies</h1>
			</div>
		</div>
	</div>
	<main class="container">
		<div class="row">
			<div class="col-12">
				<div class="alert alert-light" role="alert">
					<p class="small mb-0"><span class="fe fe-info"></span> Competencies and their Topics are the collections of professional qualities and criteria that make up Roles.</p>
					<p class="small mb-0">Your role is a collection of some of the Competency-Topics listed below. <a href="/browse/roles">Browse the Roles pages</a> to find your role.</p>
				</div>
			</div>
			<div class="col-12 col-lg-6" v-for="competency of competencies">
				<section class="card">
					<div class="card-header">
						<h4 class="card-header-title">{{ competency.title }}</h4>
					</div>
					<div class="mb-0">
						<table class="table table-sm table-nowrap table-hover card-table">
							<thead>
							<tr>
								<th>Topics</th>
								<th class="shrink"></th>
							</tr>
							</thead>
							<tbody>
								<tr v-for="topic of competency.topics">
									<td>{{ topic.title }}</td>
									<td>
										<a :href="`/browse/competencies/${competency.path}/${topic.path}`" class="btn btn-sm btn-outline-primary">View levels</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</div>
	</main>
</template>