<script>

export default {
	data() {
		return { 
			team: this.team,
			currentUser: this.user,
			title: this.title,
			currentPage: this.currentPage
		}
	},
	props: [ 'team', 'user', 'title', 'currentPage' ]
}

</script>
<template>
	<div class="header">
		<div class="container">
			<div class="header-body">
				
				<nav class="h5" aria-label="breadcrumb">
					<ol class="breadcrumb pt-0 pb-1">
						<li class="breadcrumb-item"><a href="/">Home</a></li>
						<li class="breadcrumb-item"><a href="/account/team">Team</a></li>
						<li class="breadcrumb-item active" aria-current="page">{{ team.name }}</li>
					</ol>
				</nav>
				<h1 class="header-title">{{title}}</h1>
					
				<div class="row align-items-center">
					<div class="col">
						<ul class="nav nav-tabs nav-overflow header-tabs">
							<li class="nav-item">
								<a :href="`/teams/${team.path}`" :class="`nav-link ${currentPage == 'overview' ? 'active' : null}`">Overview</a>
							</li>
							<li class="nav-item">
								<a :href="`/teams/${team.path}/reviews`" :class="`nav-link ${currentPage == 'reviews' ? 'active' : null}`">Reviews</a>
							</li>
							<li class="nav-item">
								<a :href="`/teams/${team.path}/insights`" :class="`nav-link ${currentPage == 'insights' ? 'active' : null}`">Insights</a>
							</li>
						</ul>
					</div>
					<div class="col col-auto">
						<ul class="nav nav-tabs nav-overflow header-tabs">
							<li class="nav-item">
								<a :href="`/teams/${team.path}/people`" :class="`nav-link ${currentPage == 'people' ? 'active' : null}`">People</a>
							</li>
							<li class="nav-item">
								<a :href="`/teams/${team.path}/roles`" :class="`nav-link ${currentPage == 'roles' ? 'active' : null} ${currentUser.isAdminOfTeam(team.id) ? '' : 'disabled'}`">Roles</a>
							</li>
							<li class="nav-item">
								<a :href="`/teams/${team.path}/settings`" :class="`nav-link ${currentPage == 'settings' ? 'active' : null} ${currentUser.isAdminOfTeam(team.id) ? '' : 'disabled'}`">Settings</a>
							</li>
							<li class="nav-item">
								<a :href="`/teams/${team.path}/billing`" :class="`nav-link ${currentPage == 'billing' ? 'active' : null} ${currentUser.isAdminOfTeam(team.id) ? '' : 'disabled'}`">Billing</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>