export default class RoleTopicLevel {
	id = null
	title = null
	path = null
	summary = null
	criteria = []
	requiredByRole = false
	
	constructor(level, requiredByRole)
	{
		this.id = level.id;
		this.title = level.title;
		this.path = level.path;
		this.summary = level.summary;
		this.criteria = level.criteria;
		this.requiredByRole = requiredByRole;
	}
}