<script>
import { completenessColor } from '../../js/utils.js';


export default {
	data() {
		return { 
			currentReflection: this.currentReflection,
			completenessColor
		}
	},
	props: [ 'currentReflection' ]
}

</script>
<template>
<section class="card">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col-6 col-md-4">
                <div class="card-header-title">
                    <span class="small text-muted">{{currentReflection.summary.name}}</span>
					<h4 class="mb-0">{{currentReflection.level.title}}</h4>
                </div>
            </div>
            <div class="col offset-md-2">
                <div class="text-muted text-right small mb-n1">Overall progress</div>
                <div class="row align-items-center no-gutters">
                    <div class="col-auto">
                        <div class="mr-2 small">{{ currentReflection.summary.stats.completeness.toFixed(0) }}%</div>
                    </div>
                    <div class="col">
                        <div :class="`progress progress-sm ${completenessColor(currentReflection.summary.stats.completeness)}`">
                            <div :class="`progress-bar progress-${currentReflection.summary.stats.completeness.toFixed(0)}`"></div>
                        </div>
                    </div>
                </div>
            </div>
			<div class="col-auto">
				<a :href="`/reflections/${currentReflection.summary.id}/answer`" class="btn btn-sm btn-white">Answers</a>
			</div>
        </div>

    </div>
    <div class="mb-0">
		<table v-for="competency of currentReflection.progress" class="table table-sm table-nowrap table-hover card-table">
			<thead>
				<tr>
					<th>{{ competency.displayName }}</th>
					<th>Complete</th>
					<th class="shrink"></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="topic of competency.topics">
					<td>{{ topic.displayName }}</td>
					<td class="p-40">
						<div class="row align-items-center no-gutters">
							<div class="col-auto">
								<span class="mr-2">{{topic.stats.completeness.toFixed(0)}}%</span>
							</div>
							<div class="col">
								<div :class="`progress progress-sm ${completenessColor(topic.stats.completeness)}`">
									<div :class="`progress-bar progress-${topic.stats.completeness.toFixed(0)}`"></div>
								</div>
							</div>
						</div>
					</td>
					<td>
						<a :href="`/reflections/${currentReflection.summary.id}/answer/${competency.path}/${topic.path}`" class="btn btn-sm btn-white">Answers</a>
					</td>
				</tr>
			</tbody>
		</table>
    </div>
</section>
</template>