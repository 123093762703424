import Page from '../../js/page.js';
import * as pageManager from '@trullock/page-manager';
import { getAuth, signOut } from 'firebase/auth'

export default pageManager.registerPage('account-sign-out', '/account/sign-out', class extends Page {
	static requireAuth = true;
	static existingDomSelector = '#page-sign-out'; // this doesnt exist, but as you can see below from the ctor, we dont care. Just here to trick pagemanager
	
	constructor() {
		super(null);
	}

	get title() {
		return 'Sign out';
	}

	show(){
		return signOut(getAuth())
			.then(() => pageManager.navigate('/'), error => {
				console.log(error);
			});
	}

	hide(){
		return Promise.resolve();
	}
})