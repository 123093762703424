<script>
export const pageName = 'reflections-answer-answered';
export const pageRoute = '/reflections/{reflectionId}/answered';
export const title = 'Answers submitted'

import { getRoleById } from '../../../../functions/domain/roles/roleStore.js'
import { getCurrentUserId } from '../../../js/auth.js';
import { getReflectionView,  getViewUser } from '../../../js/readModel.js';
import MeNav from '../../menav.vue'
import UserRole from '../../../../functions/domain/userRole.js';

export default {
	data() {
		return {
			currentUser: null,
			reflectionId: null,
			completeness: 0,
			ready: false
		}
	},
	methods: {
		async show(opts) {

			this.currentUser = await getViewUser(getCurrentUserId());
			this.reflectionView = await getReflectionView(opts.reflectionId)

			this.role = getRoleById(this.reflectionView.roleId);
			this.roleLevel = this.role.getLevelById(this.reflectionView.levelId);
			var userRole = new UserRole(this.role, this.roleLevel);
            userRole.applyAnswers(this.reflectionView.answers);
			this.completeness = userRole.getStats().completeness;
			
			this.ready = true;
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>

<div v-if="ready" class="container">

	<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav />
					</div>
				</div>
			</div>
		</div>

	<div class="row justify-content-center mb-4">
		<div class="col-12 col-md-8 col-lg-6">
			<div class="text-center">
				<h1>Great progress!</h1>
				<p class="mb-5">We're doing well. That's it for today, unless you want to carry on and answer some more?</p>
			</div>
			<div class="card">
				<div class="card-header">
					<h4 class="card-header-title">Overall progress: {{completeness.toFixed(0)}}%</h4>
				</div>
				<div class="card-body">
					<div class="progress progress-sm">
						<div :class="`progress-bar progress-${completeness.toFixed(0)}`"></div>
					</div>
				</div>
			</div>
			<div class="row mb-4">
				<div class="col">
					<a href="/" class="btn btn-lg btn-block btn-white">Back to dashboard</a></div>
				<div class="col"><a :href="`/reflections/${reflectionView.id}/answer/next`" class="btn btn-lg btn-block btn-primary">Answer more questions <span class="fe fe-arrow-right-circle"></span></a></div>
			</div>
		</div>
	</div>
</div>

</template>