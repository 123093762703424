<script>
export const pageName = 'reflections-wait-for-iterating';
export const pageRoute = '/reflections/{reflectionId}/wait-for-iterating';
export const title = 'Wait for the next step'

import { getCurrentUserId } from '../../js/auth.js';
import {  getViewUser } from '../../js/readModel.js';
import MeNav from '../menav.vue'

export default {
	data() {
		return {
			currentUser: null,
			ready: false
		}
	},
	methods: {
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.ready = true
		}
	},
	components: { MeNav },
	props: [ 'options' ]
}

</script>
<template>

<div v-if="ready" class="container">

	<div class="row">
		<div class="col">
			<div class="header">
				<div class="header-body pt-0">
					<MeNav />
				</div>
			</div>
		</div>
	</div>
	<div class="row justify-content-center mb-4">
		<div class=" col-12 col-md-6 text-center">
			<h1>No pain, no gain!</h1>
			<p>We know that might have been tough, but great job!</p>
			<p>It's time to chill out for a bit while the rest of your team get their self-reflections submitted.</p>
			<p>After that, your team leader will move the review process on to the next phase - iterating - where you'll work on improving and developing yourself.</p>
			<a href="/" class="btn btn-lg btn-block btn-primary">Back to dashboard</a>
		</div>
	</div>
</div>

</template>