export default class ReviewStats
{
	id = null
	name = null
	startedReflectingOn = null
	startedIteratingOn = null
	reflectionDurationDays = 0;
	reflectionPhaseEndsOn = null

	userTasks = {}

	/// What % of self-reflections have been started
	get reflectionStartedness()
	{
		let values = Object.values(this.userTasks);
		return (values.filter(t => t.reflectionStarted).length * 100.0 / values.length)
	}

	/// What % of self-reflections have been submitted
	get reflectionSubmittedness()
	{
		let values = Object.values(this.userTasks);
		return (values.filter(t => t.reflectionSubmitted).length * 100.0 / values.length)
	}

	/// The total number of mentor-mentee relationships (fed-back or not)
	get mentorMenteeRelationships()
	{
		let values = Object.values(this.userTasks);
		let mapped = values.map(v => Object.values(v.feedbackGivenBy));
		let reduced = mapped.reduce((prev, curr) => prev.concat(curr), [])
		var allFeedbacks = reduced.length;
		return allFeedbacks;
	}

	/// What % of feedback has been provided
	get reflectionFeedbackness()
	{
		let values = Object.values(this.userTasks);
		let arr = values.flatMap(v => Object.values(v.feedbackGivenBy));
		if (arr.length == 0)
			return null;

		return (arr.filter(f => f === true).length * 100.0 / arr.length)
	}

	getFeedbackForUser(userId)
	{
		var user = this.userTasks[userId];

		let feedbacks = Object.values(user.feedbackGivenBy);

		let feedback = new ReviewStats.Feedback()
		feedback.userId = userId;
		feedback.userName = user.userName;
		feedback.providedFeedback = feedbacks.filter(f => f === true).length
		feedback.totalFeedback = feedbacks.length
		return feedback;
	}

	static Feedback = class {
		userId = null
		userName = null
		totalFeedback = 0
		providedFeedback = 0
	}

	static UserParticipation = class {
		
		constructor() {
			
		}
        // TODO: this isnt currently maintained by UserNameChanged events
		userName = null
		reflectionStarted = false
		reflectionSubmitted = false
		feedbackGivenBy = {}
	}
}