export default class RoleCompetency
{
	id = null
	title = null
	path = null
	summary = null
	topics = [];

	constructor(competency, topics)
	{
		this.id = competency.id;
		this.title = competency.title;
		this.path = competency.path;
		this.summary = competency.summary;
		this.topics = topics;
	}

	getTopicById(id)
	{
		return this.topics.find(t => t.id == id);
	}

	getTopicByPath(path)
	{
		return this.topics.find(t => t.path == path);
	}

	equals(roleCompetency)
	{
		return this.id == roleCompetency.id;
	}
}