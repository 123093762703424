export default {
  "id":  "2f742c4b-75cb-4562-91a1-b081a11e71b3",
  "title": "👩‍💼 Operations",
  "path": "operations",
  "roles": [
    {
      "id": "077c5921-9f97-4809-b841-4a942517bf0f",
      "icon": "🧙‍♀️",
      "title": "Project Management",
      "path": "project-management",
      "levels": [
        {
          "id": "cfcbd8b8-410f-4ff4-9323-75c5ad2f8ee7",
          "icon": "👷",
          "title": "Project Manager",
          "path": "project-manager",
          "summary": "The Project Manager provides project and change expertise to the business. They ensure that the appropriate methodologies and governance are applied for all projects and changes. The Project Manager ensures timely communication of project progress, motivating the team, encouraging cross team collaboration. A good person in this role will call out risks and dependencies early, creating mitigation plans, ensuring the risks are not realised.",
          "competencies": {
            "required": [
				"core-competencies/attitude/3",
              "core-competencies/delivering-value/3",
              "core-competencies/client-focus/2",
              "core-competencies/planning-and-organisation/4",
              "core-competencies/problem-solving/3",
              "core-competencies/communication/4",
              "core-competencies/striving-for-success/3",
              "core-competencies/team-orientation/4"
            ],
            "recommended": [
            ]
          }
        }
      ]
    },
	{
		"id": "c9468fc8-cf84-4e43-8203-c56aab706f7c",
		"icon": "🧑🏿‍🤝‍🧑🏻",
		"title": "People",
		"path": "people",
		"levels": [
		  {
			"id": "82799ed7-3688-40b0-9509-e5adf2751ae4",
			"icon": "👷",
			"title": "HR Manager",
			"path": "hr-manager",
			"summary": "TODO",
			"competencies": {
			  "required": [
				"core-competencies/attitude/4",
				"core-competencies/delivering-value/3",
				"core-competencies/client-focus/3",
				"core-competencies/planning-and-organisation/2",
				"core-competencies/problem-solving/2",
				"core-competencies/communication/4",
				"core-competencies/striving-for-success/3",
				"core-competencies/team-orientation/4"
			  ],
			  "recommended": [
			  ]
			}
		  }
		]
	  }
  ]
}