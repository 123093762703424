<script>
export const pageName = 'teams-leave';
export const pageRoute = '/teams/{path}/leave';
export const title = 'Leave team'

import { getCurrentUserId } from '../../js/auth.js';
import { getViewTeamByPath, getViewUser } from '../../js/readModel.js';
import { functions } from '../../js/functions.js'
import { navigate } from '@trullock/page-manager'
import TopNav from './topnav.vue'

export default {
	data() {
		return {
			currentUser: null,
			team: null,
			ready: false
		}
	},

	methods: {
		submit: async function() {
			if(!this.$refs.form.validate())
				return;

			this.$refs.form.setSubmitting(true);
			
			await functions.teamLeave({ membershipId: this.currentUser.membership.membershipId });
			
			this.$refs.form.setSubmitting(false);

			navigate('/')
		},
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			this.team = await getViewTeamByPath(opts.path);
			this.ready = true;
		}
	},
	components: { TopNav },
	props: [ 'options' ]
}

</script>
<template>
	<div class="container" v-if="ready">
		<TopNav :team="team" :user="currentUser"></TopNav>
		<section class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-lg-6 col-xl-8">
					<div class="header mt-md-5">
						<div class="header-body">
							<div class="row align-items-center">
								<div class="col">
									<h6 class="header-pretitle">{{ team.name }}</h6>
									<h1 class="header-title">Leave team</h1>
								</div>
							</div>
						</div>
					</div>

					<p>Are you sure you want to leave <strong>{{ team.name }}</strong>?</p>
					<p>All your reflections will remain accessible but you won't be able to start any new interactions with your (ex) team mates.</p>
					<form @submit.prevent="submit" ref="form" class="mb-6">
						<button type="submit" class="btn btn-block btn-outline-danger">Leave</button>
					</form>
				</div>
			</div>
		</section>
	</div>
</template>