<script>
import DateTime from '../../../functions/domain/dateTime.js';

export default {
	data() {
		return { 
			task: this.task,
			DateTime
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>

<section class="card">
    <div class="card-header">
        <h4 class="card-header-title">Wait for feedback</h4>
        <small :class="task.dueOn <= DateTime.now() ? 'text-danger' : 'text-muted'" :aria-title="`This task should be completed before ${task.dueOn.format('P')}`"><span class="fe fe-clock"></span> {{task.dueOn.format('MMMM do')}}</small>
    </div>
    <div class="card-body">
		<div v-if="task.dueOn <= DateTime.now()" class="alert alert-warning small" role="alert">
			<span class="fe fe-alert-triangle"></span> The feedback deadline has passed, go and nag your mentors to give you your feedback.
		</div>
		<template v-else-if="task.DueOn <= DateTime.now().addDays(2)">
            <div class="alert alert-warning small" role="alert">
                <span class="fe fe-alert-triangle"></span> The feedback deadline is nearly upon you, go and nag your mentor to give you your feedback.
            </div>
            <p>Once your mentors have provided feedback, you should discuss it with them. Then your team leader will move the review process on to the next phase - iterating - where you'll work on improving and developing yourself.</p>
		</template>
		<template v-else>
			<p>It's time to chill out for a bit while your mentor(s) feed back on your self-reflection.</p>
            <p>After that, you should discuss your feedback with your mentor. Then your team leader will move the review process on to the next phase - iterating - where you'll work on improving and developing yourself.</p>
		</template>
        <p class="mb-0">We'll let you know when there's a new action for you, it should be around {{task.dueOn.format('P')}}.</p>
    </div>
</section>

</template>