<script>
import { functions } from '../../js/functions.js'
import { showConfirm } from '../../js/confirm.js'
import { refresh } from '@trullock/page-manager'
export default {
	data() {
		return {
			currentUser: this.currentUser,
			team: this.team,
			applications: this.applications
		}
	},
	methods: {
		async rejectApplication(membershipId) {
			await functions.teamApplicationReject({ membershipId })
			refresh()
		},
		confirmApplication(application){
			showConfirm({
				title: 'Confirm subscription change',
				message: `By accepting this person to join your team you understand that you will be adding 1 seat to your subscription and that this will have an associated cost.<br /><a href="/teams/${this.team.path}/billing" target="_blank">View subscription details <sup class="fe fe-external-link"></sup></a>`,
				positiveText: 'Approve',
				positiveAction: async () => {
					await functions.teamApplicationApprove({ membershipId: application.membershipId })
					refresh()
				}
			})
		}
	},
	props: [ 'currentUser', 'team', 'applications' ]
}

</script>
<template>
	<div class="card-body">
		<p class="small mb-0">The people below have applied to join your team. If you recognise them, you should accept them so that they can quickly begin contributing, learning and developing.</p>
	</div>
	<table class="table table-sm table-nowrap table-hover card-table">
		<thead>
			<tr>
				<th>Person</th>
				<th class="d-none d-sm-table-cell">Requested</th>
				<th class="shrink">Actions</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="application in applications">
				<td>
					<div v-if="application.user != null" class="row align-items-center">
						<div class="col-auto d-none d-sm-block">
							<div class="avatar avatar-sm dropdown-toggle">
								<img :src="application.user.avatar" :alt="application.user.name" class="avatar-img rounded-circle">
							</div>
						</div>
						<div class="col">
							<div class="mb-0">{{application.user.name}}</div>
							<div class="text-muted small">{{application.user.email}}</div>
						</div>
					</div>
				</td>
				<td class="d-none d-sm-table-cell">{{application.requestedOn}}</td>
				<td>
					<button type="button" @click.prevent="confirmApplication(application)" class="btn btn-sm btn-block btn-sm-inline-block btn-primary" :disabled="!currentUser.isAdminOfTeam(team.id)">Approve</button>
					<form @submit.prevent="rejectApplication(application.membershipId)" class="mb-1 ml-2 d-block d-sm-inline">
						<button type="submit" class="btn btn-sm btn-block btn-sm-inline-block btn-outline-danger" :disabled="!currentUser.isAdminOfTeam(team.id)">Reject</button>
					</form>
				</td>
			</tr>
		</tbody>
	</table>
</template>