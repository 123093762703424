<script>
export const pageName = 'root';
export const pageRoute = '/';
export const title = 'Your dashboard'

import { getCurrentUserId } from '../../js/auth.js';
import { getAdminUserTasks, getReflectionView, getTeamMemberships, getUserTasks, getViewUser } from '../../js/readModel.js';
import { TeamState } from '../../../functions/domain/teams/teamState.js';
import ReflectionState from '../../../functions/domain/reflections/reflectionState.js';
import { functions } from '../../js/functions.js'
import { refresh } from '@trullock/page-manager'
import MeNav from '../menav.vue'
import UserTask from '../userTasks/task.vue'
import TeamUserTask from '../teams/userTasks/task.vue'
import ActionTeamApplicationsUserTask from '../../../functions/domain/users/userTasks/members/admins/actionTeamApplicationsUserTask.js'
import { getRoleById } from '../../../functions/domain/roles/roleStore.js';
import Stats from '../../../functions/domain/reflections/stats.js';
import ReflectionUnsubmitted from './reflectionUnsubmitted.vue'
import ReflectionSubmitted from './reflectionSubmitted.vue'
import { AdminTask } from '../../../functions/domain/users/userTasks/userTask.js';

export default {
	data() {
		return { 
			currentUser: null,
			teamTasks: [],
			userTasks: [],
			currentReflection: null,
			currentIteration: null,
			TeamState,
			ReflectionState,
			ready: false
		}
	},

	methods: {
		async show(opts) {
			this.currentUser = await getViewUser(getCurrentUserId());
			
			if(this.currentUser.isAdminOfATeam)
			{
				let memberships = await getTeamMemberships(this.currentUser.membership.teamId);
				if(memberships.applications.length)
				this.teamTasks.push(new ActionTeamApplicationsUserTask(this.currentUser.membership.path))
			}
			
			let tasks = await getUserTasks(this.currentUser.id);
			this.userTasks = tasks.filter(task => !(task instanceof AdminTask))
			this.teamTasks = tasks.filter(task => task instanceof AdminTask)

			if(this.currentUser.currentReflection)
			{
				let reflection = await getReflectionView(this.currentUser.currentReflection.id);
				let role = getRoleById(reflection.roleId)
				let level = role.getLevelById(reflection.levelId);

				let progress = level.competencies.competencies.map(c => ({
					displayName: c.title,
					path: c.path,
					topics: c.topics.map(t => ({
						displayName: t.title,
						path: t.path,
						stats: reflection.competencyTopicStats[c.id]?.[t.id] || Stats.Zero
					}))
				}))

                // TODO: we dont need to send all this stuff in every case

				this.currentReflection = {
					summary: reflection,
					role,
					level,
					progress
				}
			}
			
			this.currentIteration = this.currentUser.currentIteration
		
			this.ready = true;
		},
		acceptInvitation: async function(membershipId){
			await functions.teamInvitationAccept({
				membershipId
			})
			refresh()
		},
		rejectInvitation: async function(membershipId){
			await functions.teamInvitationReject({
				membershipId
			})
			refresh()
		}
	},
	components: { MeNav, TeamUserTask, UserTask, ReflectionUnsubmitted, ReflectionSubmitted },
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container">
		<div class="row">
			<div class="col">
				<div class="header">
					<div class="header-body pt-0">
						<MeNav current-page="dashboard" />
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col col-12 col-lg-4 order-lg-last">
				<div class="d-none d-lg-block">
					<!-- <CurrentIteration v-if="currentIteration" :currentIteration="currentIteration"></CurrentIteration> -->
					
					<section v-if="currentUser.isMemberOfATeam" class="card">
						<div class="card-header">
							<h4 class="card-header-title">{{ currentUser.membership.name }}</h4>
							<div class="dropdown">
								<button class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fe fe-more-vertical"></i>
								</button>
								<div class="dropdown-menu dropdown-menu-right">
									<a href="/account/team" class="dropdown-item">View membership</a>
									<a :href="`/teams/${currentUser.membership.path}/leave`" class="dropdown-item text-danger">Leave team</a>
								</div>
							</div>
						</div>
						<div class="card-body">
							
							<div class="row">
								<div class="col">
									<h5>Mentors <span aria-title="These are people who are your mentor/manager. You define your mentors when you start a new self-reflection."><span class="fe fe-info text-muted"></span></span></h5>
								</div>
							</div>
							
							<ul v-if="currentUser.membership.mentors.length" class="list-group list-group-flush list mb-n3">
								<li v-for="mentor of currentUser.membership.mentors" class="list-group-item">
									<div class="row align-items-center">
										<div class="col-auto">
											<div class="avatar avatar-xs">
												<img :src="mentor.avatar" :alt="`Picture of ${mentor.name}`" class="avatar-img rounded-circle">
											</div>
										</div>
										<div class="col ml-n2">
											{{mentor.name}}
										</div>
									</div>
								</li>
							</ul>
							<p v-else class="small text-muted">You do not have a mentor.</p>
							<hr />
							<h5>Mentees <span aria-title="These are people who you mentor/manage. You cannot control who you mentor, they must nominate you."><span class="fe fe-info text-muted"></span></span></h5>
				
							<ul v-if="currentUser.membership.mentees.length" class="list-group list-group-flush list">
								<li v-for="mentee of currentUser.membership.mentees" class="list-group-item">
									<div class="row align-items-center">
										<div class="col-auto">
											<div class="avatar avatar-xs">
												<img :src="mentee.avatar" :alt="`Picture of ${mentee.name}`" class="avatar-img rounded-circle">
											</div>
										</div>
										<div class="col ml-n2">
											{{ mentee.name }}
										</div>
										<div class="col-auto">
											<a :href="`/insights/mentees/${mentee.id}`" class="btn btn-sm btn-outline-primary">Insights</a>
										</div>
									</div>
								</li>
							</ul>
							<p v-else class="small text-muted mb-0">You do not mentor anyone.</p>
						</div>
					</section>

					<section v-if="currentUser.isAppliedToATeam" class="card">
						<div class="card-header">
							<div class="card-header-title">
								<span class="small text-muted">Application to join team</span>
								<h4 class="mb-0">{{ currentUser.teamApplication.name }}</h4>
							</div>
							<div class="dropdown">
								<button class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<i class="fe fe-more-vertical"></i>
								</button>
								<div class="dropdown-menu dropdown-menu-right">
									<a href="/account/team" class="dropdown-item">View membership</a>
									<a :href="`/teams/${currentUser.teamApplication.path}/people/cancel-application`" class="dropdown-item text-danger">Cancel application</a>
								</div>
							</div>
						</div>
						<div class="card-body">
							<p>You have applied to join <strong>{{ currentUser.teamApplication.name }}</strong><br />A team admin needs to approve you.</p>
							<p class="small text-muted mb-0">Maybe go and nag them?</p>
						</div>
					</section>
						<template v-if="currentUser.isInvitedToATeam">
							<section v-for="invite of currentUser.teamInvitations" class="card">
								<div class="card-header">
									<div class="card-header-title">
										<span class="small text-muted">Team invitation</span>
										<h4 class="mb-0">{{invite.name}}</h4>
									</div>
									<form @submit.prevent="acceptInvitation(invite.membershipId)">
										<button type="submit" class="btn btn-sm btn-primary mr-2">Accept</button>
									</form>
									<div class="dropdown">
										<button class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<i class="fe fe-more-vertical"></i>
										</button>
										<div class="dropdown-menu dropdown-menu-right">
											<a href="/account/team" class="dropdown-item">View membership</a>
											<form @submit.prevent="rejectInvitation(invite.membershipId)" class="mr-2">
												<button type="submit" class="dropdown-item text-danger">Reject invitation</button>
											</form>
										</div>
									</div>
								</div>
								<div class="card-body">
									<p class="mb-0">You have been invited to join team <strong>{{ invite.name }}</strong><br />Accept or reject the invitation.</p>
								</div>
							</section>
						</template>
					
						<section v-if="!currentUser.isMemberOfATeam && !currentUser.isAppliedToATeam && !currentUser.isInvitedToATeam" class="card">
							<div class="card-header">
								<h4 class="card-header-title">Team</h4>
								<a href="/teams/create" class="btn btn-sm btn-outline-primary mr-2">Create team</a>
								<a href="/onboarding/teams/search" class="btn btn-sm btn-primary mr-2">Join team</a>
							</div>
							<div class="card-body">
								<p class="small">You are not currently part of a team.</p>
								<p class="small mb-0">Get the most out of <strong>ivelop</strong> by joining a team and collaborating with your peers and mentors.</p>
							</div>
						</section>
				</div>
			</div>

			<div class="col col-12 col-lg-8">

			<template v-if="teamTasks.length">
				<h2 class="mb-1 text-center">Team tasks</h2>
				<p class="small mb-5 text-center text-muted">Things you need to do for your team as an administrator</p>
				<template v-for="task of teamTasks">
					<TeamUserTask :task="task" :currentUser="currentUser"></TeamUserTask>
				</template>
			</template>

			<hr v-if="teamTasks.length" class="my-5" />
			
			<template v-if="teamTasks.length">
				<h2 class="mb-1 text-center">Individual tasks</h2>
				<p class="small mb-5 text-center text-muted">Things you need to do as an ivelop user</p>
			</template>
			<template v-else>
				<h2 class="mb-1 text-center">Your tasks</h2>
				<p class="small mb-5 text-center text-muted">These are the things you need to do next</p>
			</template>
			<template v-for="task of userTasks">
				<UserTask :task="task" :currentUser="currentUser"></UserTask>
			</template>

			<div v-if="!userTasks.length" class="card">
				<div class="card-header">
					<h4 class="card-header-title">You're all caught up</h4>
				</div>
				<div class="card-body">
					<p class="small mb-0">You have no outstanding tasks at the moment.</p>
				</div>
			</div>

			<template v-if="currentReflection?.summary.state == ReflectionState.Unsubmitted">
				<hr class="my-5" />
				<ReflectionUnsubmitted :currentReflection="currentReflection"></ReflectionUnsubmitted>
			</template>
			<template v-else-if="currentReflection?.summary.state == ReflectionState.Submitted">
				<hr class="my-5" />
				<ReflectionSubmitted :currentReflection="currentReflection" :currentUser="currentUser"></ReflectionSubmitted>
			</template>

			</div>
		</div>
    </div>
</template>
