<script>
import DateTime from '../../../functions/domain/dateTime.js';

export default {
	data() {
		return { 
			task: this.task
		}
	},
	methods: {
		taskDeadline: date => {
			if (!date)
                return null;

            var color = date < DateTime.now() ? "text-danger" : "text-muted";
            return `<small class="${color}" aria-title="This task should be completed before ${date.format('MMMM do')}"><span class="fe fe-clock"></span> ${date.format('MMMM do')}</small>`;
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<section class="card">
    <div class="card-header">
        <h4 class="card-header-title">Reflect on your recent performance</h4>
        <span v-html="taskDeadline(task.dueOn)"></span>
        <a href="/reflections/new" class="btn btn-sm btn-primary">Start self-reflection <span class="fe fe-arrow-right-circle"></span></a>
    </div>
    <div class="card-body">
        <p class="">Let's get started with a self reflection of how you're currently doing.</p>
        <p class="mb-0">You'll choose your role and then provide feedback on various different competencies.</p>
    </div>
</section>
</template>