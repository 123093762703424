<script>
import DateTime from '../../../functions/domain/dateTime.js';

export default {
	data() {
		return { 
			task: this.task,
			DateTime
		}
	},
	props: [ 'task', 'currentUser' ]
}

</script>
<template>
<section class="card">
    <div class="card-header">
        <h4 class="card-header-title">Submit your self-reflection&hellip;</h4>
		<small :class="task.dueOn <= DateTime.now() ? 'text-danger' : 'text-muted'" :aria-title="`This task should be completed before ${task.dueOn.format('P')}`"><span class="fe fe-clock"></span> {{task.dueOn.format('MMMM do')}}</small>
        <a :href="`/reflections/${task.reflectionId}/submit`" class="btn btn-sm btn-primary">Submit answers</a>
    </div>
    <div class="card-body">
		<div v-if="(task.dueOn <= DateTime.now())" class="alert alert-warning small" role="alert">
			The submission deadline has passed, expect your team leader to come looking for you.<br />Get your answers in quick!
		</div>
        <p class="mb-0">Great, you've answered all the questions for this self-reflection.<br />Now you need to review and submit them.</p>
    </div>
</section>
</template>