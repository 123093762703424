import AggregateRoot from "../aggregateRoot.js";
import DateTime from "../dateTime.js";
import { ReviewPhase } from "./reviewPhase.js";

export default class Review extends AggregateRoot
{
	teamId = null
	name = null
	reflectionDurationDays = 0
	reflectionPhaseEndsOn = null
	phase = 0

	static start(id, teamId, name, reflectionDurationDays)
	{
		let review = new Review();
		review.#start(id, teamId, name, reflectionDurationDays);
		return review;
	}

	#start(id, teamId, name, reflectionDurationDays)
	{
		let endsOn = DateTime.now();
		endsOn = endsOn.addDays(reflectionDurationDays);

		this.apply('reviewStarted', {
			aggregateRootId: id,
			teamId,
			name, 
			reflectionDurationDays,
			reflectionPhaseEndsOn: endsOn
		});
	}

	onReviewStarted(event) 
	{
		this.id = event.aggregateRootId;
		this.teamId = event.teamId;
		this.name = event.name;
		this.reflectionDurationDays = event.reflectionDurationDays;
		this.reflectionPhaseEndsOn = event.reflectionPhaseEndsOn;
		this.phase = ReviewPhase.reflecting;
	}

	beginIterating(prioritisedTopicIds)
	{
		if(this.phase != ReviewPhase.reflecting)
			throw new Error(`Can't start iterating when we aren't reflecting`);
		
		this.apply('reviewStartedIterating', {
			teamId: this.teamId,
			name: this.name,
			prioritisedTopicIds
		})
	}

	onReviewStartedIterating(event)
	{
		this.phase = ReviewPhase.iterating;
	}
}